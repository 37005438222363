import * as t from '../../const.config'

const initialState = {
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    listPhoto: [],
    detailPhoto: {},
    pages: 0
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case t.GET_PHOTO_START:
            return {
                ...state,
                isLoading: action.loading,
                isSuccess: false,
                isFailed: false,
                listPhoto: action.reset ? [] : state.listPhoto,
                detailPhoto: action.reset ? {} : state.detailPhoto
            }
        case t.GET_PHOTO_SUCCESS:
            const stateData = state.listPhoto
            const data = action.isLoadMore ? stateData.concat(action.data) : action.data
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                [action.state]: data,
                pages: action.state === "listPhoto" ? action.pages : state.pages
            }
        case t.GET_PHOTO_FAILED:
            return {
                ...state,
                isLoading: false,
                isFailed: true,
            }
        case t.DO_RESET_PHOTO_DETAIL:
        case t.DO_RESET_ALL_POST_DETAIL:
            return {
                ...state,
                detailPhoto: {}
            }
        default:
            return state;
    }
}

export default reducer;