import { combineReducers } from 'redux'
import { reducerMentor } from './askmentor'
import { reducerUser } from './auth'
import * as c from './community'
import * as k from './content'
import chatReducer from './chat'
import triviaReducer from './trivia'
import { reducerMessage } from './message'
import profileReducer from './profile'
import {reducerPoint} from './point'
import bikinEventReducer from './bikinEvent'

const reducer = combineReducers({
    message: reducerMessage,
    user: reducerUser,
    content: k.reducerContent,
    banner: k.reducerBanner,
    feedCommunity: c.feedCommunityReducer,
    articleCommunity: c.articleCommunityReducer,
    mentor: reducerMentor,
    eventCommunity: c.eventCommunityReducer,
    photoCommunity: c.photoCommunityReducer,
    memberCommunity: c.memberCommunityReducer,
    podcastCommunity: c.podcastCommunityReducer,
    chat: chatReducer,
    trivia: triviaReducer,
    profile: profileReducer,
    point: reducerPoint,
    bikinEvent: bikinEventReducer
})

export default reducer
