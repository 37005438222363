import React, {Fragment} from 'react'
import Icon from 'react-web-vector-icons'
import { Dialog, Transition } from '@headlessui/react'

function SlideOver({handleClick, openModal, itemData, title}) {
    return (
        <Transition.Root show={openModal} as={Fragment}>
            <Dialog as="div" static className="fixed inset-0 overflow-hidden z-50" open={openModal} onClose={handleClick}>
                <div className="absolute inset-0 overflow-hidden">
                <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>
                <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
                    <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                    >
                    <div className="relative w-screen max-w-md">
                        <Transition.Child
                        as={Fragment}
                        enter="ease-in-out duration-500"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in-out duration-500"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        >
                        <div />
                        </Transition.Child>
                        <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
                        <div className="px-4 sm:px-6 flex flex-row items-center justify-between">
                        <button
                            className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                            onClick={handleClick}
                            >
                                <Icon
                                    name='close'
                                    font='AntDesign'
                                    color='#4B5563'
                                    size={24}
                                />
                            </button>
                            {title}
                        </div>
                        <div className="mt-6 relative flex-1 px-4 sm:px-6">
                            {/* ini content */}
                                <div className="flex flex-col items-start mt-4 space-y-5 ">
                                    {itemData}
                                </div>
                            {/* /End contentnya */}
                        </div>
                        </div>
                    </div>
                    </Transition.Child>
                </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export {SlideOver}
