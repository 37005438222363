import { MENTOR_COMMENT_LOADING, MENTOR_GET_LOADING, MENTOR_MY_QUESTIONS_LOADING, MENTOR_QUESTIONS_LOADING, MENTOR_QUESTION_DETAIL_LOADING, TOPICS_GET_LOADING,
    MENTOR_QUESTION_DETAIL_CLEAR,
    MENTOR_SUBMIT_ANSWER_LOADING,
    MENTOR_SUBMIT_QUESTION_LOADING,
    MENTOR_QUESTIONS

} from "../const.config";

export const getQuestions = data =>({
    type: MENTOR_QUESTIONS,
    payload: data
})

export const getMyQuestions = data =>({
    type: MENTOR_MY_QUESTIONS_LOADING,
    payload: data
})

export const getQuestionDetail = data =>({
    type: MENTOR_QUESTION_DETAIL_LOADING,
    payload: data
})

export const getQuestionDetailClear = () =>({
    type: MENTOR_QUESTION_DETAIL_CLEAR
})

export const getCommentAnswer = (item) => ({
    type: MENTOR_COMMENT_LOADING,
    payload: item
})

export const getListMentor = (item) => ({
    type: MENTOR_GET_LOADING,
    payload: item
})

export const getListTopics = (item) => ({
    type: TOPICS_GET_LOADING,
    payload: item
})

export const submitAnswer = (item) => ({
    type: MENTOR_SUBMIT_ANSWER_LOADING,
    payload: item
})

export const sumbitQuestion = (item) => ({
    type: MENTOR_SUBMIT_QUESTION_LOADING,
    payload: item
})