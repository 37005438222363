import React from 'react'
import * as a from '../../redux/actions'
import { Base, FlatList, TabCategory } from '../../components'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams, useHistory } from 'react-router-dom'
import { ArticleDetail } from '../../routers/const'

function ListArticles(props) {
    const dispatch = useDispatch()
    const [activeTab, setActiveTab] = React.useState(0)
    const [keyword, setKeyword] = React.useState("")
    const [page, setPage] = React.useState(1)
    const { listArticle, isLoading, pages } = useSelector(state => state.articleCommunity)
    const fromSide = props.location.state?.fromSide
    const title = props.location.state?.title || ""
    const params = useParams()
    const history = useHistory()

    const fetchData = (value, reset, resetPage, filter) => {
        dispatch(a.getArticleCommunity({
            id: params?.id,
            idArticle: null,
            type: null,
            keyword,
            page: value ? value : 1,
            filter: filter,
            reset
        }))
    }

    const listCtg = [
        { category_id: "all", name: 'Semua' },
        { category_id: "community", name: 'Komunitas' },
        { category_id: "brand", name: 'Mitra' },
    ]

    const tabFilter = (item) => {
        setActiveTab(item)
        setPage(1)
        fetchData(null, true, true, item.category_id)
    }

    const handleClickArticle = (id, title) => {
        const pathname = !fromSide ?
            ArticleDetail.replace(':id', params?.id).replace(':idContent', id) :
            `article/${id}/${title.split(" ").join("-").toLowerCase()}`
        history.push(pathname)
    }

    const loadMore = () => {
        setTimeout(() => {
            if (listArticle.length % 10 == 0 && !isLoading && page + 1 <= pages) {
                setPage(page + 1)
                fetchData(page + 1, false, false, activeTab.category_id)
            }
        }, 500)
    }

    React.useEffect(() => {
        fetchData()
        setActiveTab({ category_id: "all" })
        dispatch(a.doCheckToken())
    }, [keyword])

    const renderItem = ({ item }) => {
        return (
            <button
                onClick={() => handleClickArticle(item.id, item.post_title)}
                className="mb-3 flex flex-row items-center text-left">
                <img
                    className="object-cover h-28 w-28 md:h-36 md:w-36 rounded-lg mr-3"
                    src={item.featuredthumb[0]?.image}
                />
                <div className="flex flex-col justify-center h-28 md:h-36 w-full">
                    <p className="text-sm md:text-base font-semibold line-clamp-3">
                        {item.post_title}
                    </p>
                    <p className="text-xs md:text-sm font-light my-1">{item.post_date}</p>
                    <p className="text-xs md:text-sm">oleh {item.display_name}</p>
                </div>
            </button>
        )
    }

    return (
        <Base
            primary
            title={`Articles ${title}`}
            onSearch={setKeyword}
            main={
                <div>
                    <div className="sticky top-14 lg:top-0 rounded-md sm:px-6 bg-white flex sm:justify-center ">
                        {fromSide && <TabCategory
                            data={listCtg}
                            active={activeTab?.category_id}
                            id="category_id"
                            class="shadow-md border-0"
                            onPressItem={tabFilter}
                        />}
                    </div>
                    <FlatList
                        data={listArticle}
                        renderItem={renderItem}
                        loading={isLoading}
                        style={`${fromSide && 'mt-4'} px-2 mb-24 sm:mb-5`}
                        onEndReached={loadMore}
                        hasMore={page + 1 <= pages}
                        page={page}
                    />
                </div>
            }
            right={<></>}
        />
    )
}

export { ListArticles }
