import React, { useState, useEffect } from 'react'
import { FlatList, Modal, Spinner } from '../../components'
import { RiSearchLine } from 'react-icons/ri'
import { IoMdClose, IoMdCamera } from 'react-icons/io'
import { IoCloseCircle, IoCheckmarkCircle, IoChevronBack } from 'react-icons/io5'

function AddChat({ type, visible, onClose, onSearch, loading, data, user, onOpenChat, onCreateGroup, detail, onAddMember }) {
    const [selectedUser, setSelectedUser] = useState([])
    const [groupName, setGroupName] = useState("")
    const [page, setPage] = useState(1)
    const group = type == 'group'

    const handleClose = () => {
        setPage(1)
        setSelectedUser([])
        onClose()
    }

    const hasWhiteSpace = (text) => {
        return /^\s/.test(text)
    }

    const handleCreateGroup = () => {
        if (selectedUser.length && groupName && !hasWhiteSpace(groupName)) {
            let newData = [...selectedUser]
            const userIds = newData.map(x => x.id)
            const body = {
                room_name: groupName,
                creator: user?.ID,
                participants: userIds,
                room_option: false,
                room_avatar_url: ''
            }
            onCreateGroup(body)
            setPage(3)
        }
    }


    const handleSelectUser = (item) => {
        let newData = [...selectedUser]
        let checkData = newData.filter(x => x.id == item.id).length
        if (checkData) newData = newData.filter(x => x.id !== item.id)
        else newData.push(item)
        setSelectedUser(newData)
    }

    const handleAddMember = () => {
        const userIds = selectedUser.map(x => x.id)
        onAddMember(userIds)
    }

    useEffect(() => {
        if (!visible) {
            setPage(1)
            setSelectedUser([])
            setGroupName("")
        }
    }, [visible])

    const renderSelectedUser = ({ item }) => {
        const select = page == 1
        const name = select ? `${item?.first_name} ${item?.last_name}`.substring(0, 5) : `${item?.first_name} ${item?.last_name}`
        return (
            <div className={`flex flex-col items-center ${select && 'mr-3'}`}>
                <div className="relative w-11 h-11">
                    <img src={item?.avatar} className="h-11 w-11 object-cover rounded-full border" />
                    {select && <IoCloseCircle
                        className="text-red-500 absolute -top-0.5 -right-1.5 text-xl bg-white rounded-full"
                        onClick={() => handleSelectUser(item)}
                    />}
                </div>
                <p className={`text-sm font-light mt-0.5 ${!select && 'line-clamp-1'} text-center`}>{name}</p>
            </div>
        )
    }

    const renderUser = ({ item }) => {
        const name = item?.user_email == user.user_email ? 'You' : `${item?.first_name} ${item?.last_name}`
        const checkData = selectedUser.filter(x => x.id == item.id).length
        return (
            <button
                onClick={() => type == 'single' ?
                    onOpenChat(item?.id) : name == 'You' ?
                        null : handleSelectUser(item)
                }
                className="flex items-center mb-1 text-left w-full py-1">
                <div className="relative">
                    <img src={item?.avatar} className="h-11 w-11 object-cover rounded-full border" />
                    {checkData !== 0 && <IoCheckmarkCircle
                        className="text-green-500 absolute -bottom-0.5 -right-1.5 text-xl bg-white rounded-full"
                    />}
                </div>
                <div className="ml-2 flex flex-col justify-between">
                    <p className="text-sm font-semibold mb-0.5">{name}</p>
                    <p className="text-xs font-light">{item?.user_email}</p>
                </div>
            </button>
        )
    }

    const renderContent = () => {
        if (page == 1) {
            return (
                <>
                    <div className="flex items-center justify-between mb-3">
                        <div className="flex items-center">
                            <IoMdClose className="text-2xl mr-2" onClick={handleClose} />
                            <p className="text-base font-semibold">{group ? 'Tambah peserta' : 'Pilih pengguna'}</p>
                        </div>
                        {group &&
                            <button
                                onClick={() => detail ? handleAddMember() : selectedUser.length && setPage(2)}
                                className={`${selectedUser.length ? 'bg-red-500 text-white' : 'bg-gray-100 text-gray-500'} text-sm font-semibold px-3 py-1 rounded-md`}>
                                {detail ? 'Selesai' : 'Lanjut'}
                            </button>
                        }
                    </div>
                    {group &&
                        <FlatList
                            data={selectedUser}
                            renderItem={renderSelectedUser}
                            style="overflow-x-auto flex mb-3 w-full"
                            ListEmptyComponent={() => null}
                        />
                    }
                    <div className="flex items-center bg-gray-100 py-2 px-3 mb-3">
                        <RiSearchLine className="text-xl mr-2 text-gray-400" />
                        <form className="w-full" onSubmit={e => {
                            e.preventDefault()
                            onSearch(e.target.elements.key.value)
                        }}>
                            <input
                                name="key"
                                className="outline-none bg-gray-100 text-sm w-full"
                                placeholder="Cari pengguna"
                            />
                        </form>
                    </div>
                    <FlatList
                        data={loading ? [] : data}
                        renderItem={renderUser}
                        loading={loading}
                        ListFooterComponent={() => (
                            <div className="h-screen-50 sm:h-screen-60">
                                <Spinner size={10} top={3} />
                            </div>
                        )}
                        style="h-screen-50 sm:h-screen-60 overflow-y-auto"
                        ListEmptyComponent={() => (
                            <div className="w-full h-screen-50 sm:h-screen-60 flex justify-center">
                                <p className="text-sm font-light mt-5">Not found</p>
                            </div>
                        )}
                    // onEndReached={loadMore}
                    // hasMore={page + 1 <= pages}
                    // page={page}
                    />
                </>
            )
        } else if (page == 2) {
            return (
                <>
                    <div className="flex items-center justify-between mb-3">
                        <div className="flex items-center">
                            <IoChevronBack className="text-xl mr-2" onClick={() => setPage(1)} />
                            <p className="text-base font-semibold">Grup baru</p>
                        </div>
                        {group &&
                            <button
                                onClick={handleCreateGroup}
                                className={`${groupName && !hasWhiteSpace(groupName) ? 'bg-red-500 text-white' : 'bg-gray-100 text-gray-500'} text-sm font-semibold px-3 py-1 rounded-md`}>
                                Selesai
                            </button>
                        }
                    </div>
                    <div className="flex items-center w-full">
                        <div className="h-11 w-11 rounded-full flex items-center justify-center bg-gray-200 mr-3">
                            <IoMdCamera className="text-3xl text-white" />
                        </div>
                        <input
                            placeholder="Masukkan nama grup"
                            className="border-b w-9/12 text-sm outline-none py-2"
                            value={groupName}
                            onChange={(e) => setGroupName(e.target.value)}
                        />
                    </div>
                    <p className="text-sm mt-3 mb-2">Peserta: {selectedUser.length}</p>
                    <FlatList
                        data={selectedUser}
                        renderItem={renderSelectedUser}
                        style="overflow-y-auto grid grid-cols-4 gap-3 max-h-screen-50 sm:max-h-screen-60 bg-gray-50 px-3 py-3"
                    />
                </>
            )
        } else if (page == 3) {
            return (
                <div className="max-h-screen-50 sm:max-h-screen-60 flex flex-col items-center justify-center">
                    <p className="text-base">Creating a new group, please wait...</p>
                    <Spinner size={10} top={3} />
                </div>
            )
        }
    }

    return (
        <Modal visible={visible} className="flex-col px-5 py-3" containerStyle="w-full">
            {renderContent()}
        </Modal>
    )
}

export default AddChat