const images = {
    logo: require('./img/logo.png'),
    cover: require('./img/cover.png').default,
    preview: require('./img/preview.png'),
    scWhite: require('./img/sc-white.png').default,
    downloads: {
        google: require('./img/download/google-play-badge.svg'),
        apple: require('./img/download/apple-app-store-badge.svg')
    },
    badge: {
        bronze: require('./img/badge/bronze.png').default,
        silver: require('./img/badge/silver.png').default,
        gold: require('./img/badge/gold.png').default,
        diamond: require('./img/badge/diamond.png').default,
    },
    landing: {
        joined: require('./img/about/joined.png').default,
        askMentor: require('./img/about/askmentor.png').default,
        articles: require('./img/about/articles.png').default,
        podcasts: require('./img/about/podcasts.png').default,
        community: require('./img/about/community.png').default,
    }
}

export { images }