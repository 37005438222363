import Axios from 'axios'
import qs from 'qs'
import { store } from '../redux'

export const env = "www" // change to dev/www
export const platform = "web" // change to web/mobile
export const baseUrl = `https://${env}.socialconnext.app`
export const baseUrlApi = `${baseUrl}/api`
export const baseUrlCustom = `${baseUrlApi}/custom_service/?service`
export const baseUrlPush = `${baseUrl}/push`
export const authKey = 'BdbySwnBDn10NHX1Kbmqx7VlR'
export const CONSUMER_KEY = platform == 'mobile' ? '119687763' : '211565709'
export const SECRET_KEY = platform == 'mobile' ? 'FAACB79A0FEE6E85DE987C10D3559E78A6490BFC122DA4FAD5D2E7F63F08EFA6' : 'ED5B9023F51D5CF9C7AE6EE63EC2D380E17BF0520F8FF7EA785319F269A73791'

const getHeader = (type) => {
    if (type !== 'form') {
        return {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
    } else {
        return {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
    }
}

export const getDataFromApi = async (body, url, type, typePost) => {
    const token = await store.getState().user.user.access_token_data.token
    // '27126271266052c13950018_PpvojZusjvvQd5IyN6Yoc5mIStpo2ZJCey3ExlYul143stgqkzvnGnYsYM937iAzNYWDrVJDF6fD9sZTZ6tHpgTz81hrhki9odFG7p4oqKmhq8LxdKBxJCkceIwMgJAn34kwICNUmegQBl3nBCZU8iA3hYiUGgUM7x2s9VU57Xt7CyV2olKaS2Q0kNVraAUfn2Lam3oV71PPRQSOEnqjYtOAsD52Ij1p3qFdOlfl'
    let reqBody = ''
    if (typePost !== 'form') {
        reqBody = qs.stringify({
            ACCESS_TOKEN: token,
            ...body
        })
    } else {
        body.append('ACCESS_TOKEN', token)
        reqBody = body
    }

    const res = await Axios.post(url, reqBody, getHeader(typePost))
    // console.log("res", res)
    // .then(y => console.log("y", y)).catch(x => console.log("x", x))

    const result = () => {
        switch (type) {
            case 'page':
                return { data: res.data.result, page: res.data.paging.pages }
            case 'msg':
                return res.data.message
            case 'comment':
                return { data: res.data.result, page: res.data.paging }
            case 'base':
                return res
            case 'respond':
                return res.data.respond
            default:
                return res.data.result
        }
    }

    return result()
}

// Community
export const getEventApi = `${baseUrlCustom}=m_getevents`
export const getPostApi = `${baseUrlCustom}=m_getallposts`
export const getArticleApi = `${baseUrlCustom}=m_getarticles`
export const getMemberApi = `${baseUrlCustom}=m_getmembers`
export const getCategoryApi = `${baseUrlCustom}=m_getcategories`
export const getCityApi = `${baseUrlCustom}=g_getcities`
export const getPodcastApi = `${baseUrlCustom}=m_getpodcasts`
export const getProvinceApi = `${baseUrlCustom}=g_getprovince`
export const getPhotoApi = `${baseUrlCustom}=m_getphotos`
export const doRegisterEventApi = `${baseUrlCustom}=m_setrsvp`
export const doFollowApi = `${baseUrlApi}/follow_author`
export const doUnfollowApi = `${baseUrlApi}/unfollow_author`
export const doLikePostApi = `${baseUrlCustom}=postlike`
export const doUnlikePostApi = `${baseUrlCustom}=postunlike`
export const getCommentApi = `${baseUrlApi}/getcomments`
export const getCommentApi2 = `${baseUrlCustom}=g_listcomment`
export const doCommentApi = `${baseUrlApi}/newcomment`
export const getProfileApi = `${baseUrlCustom}=m_getauthorprofile`
export const getCommunityApi = `${baseUrlCustom}=m_getcommunities`

// momo
export const getSeriesPodcastApi = `${baseUrlCustom}=m_getseries`
export const sendOPT = `${baseUrlCustom}=send_otp`
export const getBanner = `${baseUrlCustom}=m_getbanner`

// Chat
export const getUserListApi = `${baseUrlCustom}=getuserqiscus`
export const getRoomDetailApi = `${baseUrlCustom}=get_room_id`
export const createGroupApi = `${baseUrlCustom}=create_room`
export const editDescApi = `${baseUrlCustom}=update_desc_group`
export const addParticipantsApi = `${baseUrlCustom}=add_participants`
export const updateAvatarGroupApi = `${baseUrlCustom}=update_picture_group`
export const doMakeAdminGroupApi = `${baseUrlCustom}=add_room_admins`
export const removeParticipantsApi = `${baseUrlCustom}=remove_participants`
export const getListChatGroupApi = `${baseUrlCustom}=get_room_info`
export const updateGroupNameApi = `${baseUrlCustom}=m_update_group_name`

// Trivia
export const getTriviaApi = `${baseUrlCustom}=gettrivia`
export const getQaApi = `${baseUrlCustom}=getqa`
export const getResultApi = `${baseUrlCustom}=triviavalidate`
export const getLeaderboardApi = `${baseUrlCustom}=trivialeaderboard`

// Profile
export const getMyProfileApi = `${baseUrlApi}/get_author/`
export const updateProfilePhotoApi = `${baseUrlApi}/profile_image/`
export const updateProfileApi = `${baseUrlApi}/edit_profile/`

// Auth
export const doSaveDeviceTokenApi = `${baseUrlPush}/savetoken/`
export const doCheckTokenApi = `${baseUrlCustom}=check_token`

// Event
export const getwEventApi = `${baseUrlCustom}=w_getevents`
export const addEventApi = `${baseUrlCustom}=w_newevent`
export const updateEventApi = `${baseUrlCustom}=w_updateevent`
export const removeEventApi = `${baseUrlCustom}=w_deleteevent`
export const getRsvpApi = `${baseUrlCustom}=w_getrsvp`
export const doApproveRsvpApi = `${baseUrlCustom}=w_approversvp`
export const doExportRsvpApi = `${baseUrlCustom}=w_exportrsvp`
