import * as t from '../const.config'

const initialState = {
    user: {},
    action: null,
    loading: false,
    link: '',
    isLoading: false,
    isSuccessSaveToken: false,
    isFailed: false,
    logout: false,
    isTokenValid: true,
    isLogin: false,
    otp: ''
}

const reducerUser = (state = initialState, action) => {
    switch (action.type) {
        case t.SIGN_IN_LOADING:
            return {
                ...state,
                action: action.type,
                loading: true,
                isSuccessSaveToken: false
            }
        case t.SIGN_IN_SUCCSESS:
            return {
                ...state,
                action: action.type,
                user: action.data,
                loading: false,
                isSuccessSaveToken: true,
                isLogin: true
            }
        case t.SIGN_IN_FAILURE:
            return {
                ...state,
                action: action.type,
                loading: false,
                message: action.message
            }
        case t.SIGN_OTP_LOADING:
            return {
                ...state,
                action: action.type,
                loading: true,
                isSuccessSaveToken: false
            }
        case t.SIGN_OTP_SUCCSESS:
            return {
                ...state,
                action: action.type,
                user: action.data,
                loading: false
            }
        case t.SIGN_OTP_FAILURE:
            return {
                ...state,
                action: action.type,
                loading: false,
                message: action.message
            }
        case t.SIGN_PHONE_LOADING:
            return {
                ...state,
                action: action.type,
                loading: true,
                isSuccessSaveToken: false,
            }
        case t.SIGN_PHONE_SUCCSESS:
            return {
                ...state,
                action: action.type,
                phone: action.data.phone,
                otp: action.data.otp,
                loading: false
            }
        case t.SIGN_PHONE_FAILURE:
            return {
                ...state,
                action: action.type,
                loading: false,
                message: action.message
            }
        case t.SIGN_OUT_LOADING:
            return {
                ...state,
                action: action.type,
                loading: true
            }
        case t.SIGN_OUT_SUCCSESS:
            return {
                ...state,
                action: action.type,
                user: {},
                loading: false,
                logout: true,
                isSuccessSaveToken: false
            }
        case t.SIGN_OUT_FAILURE:
            return {
                ...state,
                action: action.type,
                loading: false,
                message: action.message
            }
        case t.UPDATE_PROFILE_LOADING:
            return {
                ...state,
                action: action.type,
                loading: true
            }
        case t.UPDATE_PROFILE_SUCCSESS:
            return {
                ...state,
                action: action.type,
                loading: false
            }
        case t.UPDATE_PROFILE_FAILED:
            return {
                ...state,
                action: action.type,
                loading: false,
                message: action.message
            }

        case t.FORGET_PASSWORD_LOADING:
            return {
                ...state,
                action: action.type,
                loading: true
            }
        case t.FORGET_PASSWORD_SUCCSESS:
            return {
                ...state,
                action: action.type,
                loading: false
            }
        case t.FORGET_PASSWORD_FAILURE:
            return {
                ...state,
                action: action.type,
                loading: false
            }
        case t.SECRET_CODE_LOADING:
            return {
                ...state,
                action: action.type,
                loading: true
            }
        case t.SECRET_CODE_SUCCSESS:
            return {
                ...state,
                action: action.type,
                loading: false,
                link: action.data
            }
        case t.SECRET_CODE_FAILURE:
            return {
                ...state,
                action: action.type,
                loading: false
            }
        case t.CLEAR_ACTION:
            return {
                ...state,
                action: '',
                loading: false,
                logout: false
            }
        case t.SIGN_UP_LOADING:
            return {
                ...state,
                action: action.type,
                loading: true
            }
        case t.SIGN_UP_SUCCSESS:
            return {
                ...state,
                action: action.type,
                loading: false
            }
        case t.SIGN_UP_FAILURE:
            return {
                ...state,
                action: action.type,
                loading: false,
                message: action.message
            }
        case t.CHANGE_PASSWORD_LOADING:
            return {
                ...state,
                action: action.type,
                loading: true
            }
        case t.CHANGE_PASSWORD_SUCCSESS:
            return {
                ...state,
                action: action.type,
                loading: false,
                message: action.message
            }
        case t.CHANGE_PASSWORD_FAILURE:
            return {
                ...state,
                action: action.type,
                loading: false,
                message: action.message
            }
        case t.DO_SAVE_DEVICE_TOKEN_LOADING:
            return {
                ...state,
                isLoading: true,
                isSuccessSaveToken: false,
                isFailed: false,
            }
        case t.DO_SAVE_DEVICE_TOKEN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccessSaveToken: true,
            }
        case t.DO_SAVE_DEVICE_TOKEN_FAILED:
            return {
                ...state,
                isLoading: false,
                isFailed: true,
            }
        case t.DO_CHECK_TOKEN_LOADING:
            return {
                ...state,
            }
        case t.DO_CHECK_TOKEN_SUCCESS:
            return {
                ...state,
                isTokenValid: action.data
            }
        case t.DO_CHECK_TOKEN_FAILED:
            return {
                ...state,
                isSuccessSaveToken: action.data,
                isTokenValid: action.data
            }
        case t.DO_RESET_REGISTER_STATUS:
            return {
                ...state,
                action: '',
                message: ''
            }
        case t.DO_RESET_LOGIN_STATUS:
            return {
                ...state,
                isLogin: false,
                isTokenValid: false
            }
        case t.DO_SET_STATE_AUTH:
            return {
                ...state,
                [action.payload.key]: action.payload.value,
            }
        default:
            return state
    }
}

export { reducerUser }