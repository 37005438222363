import * as t from '../../const.config'

const initialState = {
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    listMember: [],
    pages: 0
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case t.GET_MEMBER_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
                isFailed: false,
                listMember: action.reset ? [] : state.listMember
            }
        case t.GET_MEMBER_SUCCESS:
            const stateData = state.listMember
            const data = action.isLoadMore ? stateData.concat(action.data) : action.data
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                listMember: data,
                pages: action.pages
            }
        case t.GET_MEMBER_FAILED:
            return {
                isLoading: false,
                isFailed: true,
                ...state,
            }
        default:
            return state;
    }
}

export default reducer;