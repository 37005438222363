import { takeEvery, call, put } from 'redux-saga/effects'
import * as t from '../const.config'
import * as a from '../../config/api'
import Axios from '../../utils/server/baseURL'
import qs from 'qs'

export const AskMentor = [
    takeEvery(t.MENTOR_QUESTIONS, workerGetQuestions),
    takeEvery(t.MENTOR_QUESTION_DETAIL_LOADING, workerGetQuestionDetail),
    takeEvery(t.MENTOR_COMMENT_LOADING, workerGetAnswers),
    takeEvery(t.MENTOR_GET_LOADING, workerGetMentors),
    takeEvery(t.TOPICS_GET_LOADING, workerGetTopics),
    takeEvery(t.MENTOR_MY_QUESTIONS_LOADING, workerGetMyQuestions),
    takeEvery(t.MENTOR_SUBMIT_ANSWER_LOADING, workerSubmitAnswer),
    takeEvery(t.MENTOR_SUBMIT_QUESTION_LOADING, workerSubmitQuestion),
]

const getQuestions = async params => {
    let url = ''
    if (params.mentor && params.topic) {
        url = `/api/getposts/?callpage=${params.callpage}&perpage=10&custom_post=cma_thread&taxonomy_id=${params.topic}&custom_meta_and={"cmaexp_expert_uid": "='${params.mentor}'"}`
    } else if (params.topic && !params.mentor) {
        url = `/api/getposts/?callpage=${params.callpage}&perpage=10&custom_post=cma_thread&taxonomy_id=${params.topic}`
    } else if (!params.topic && params.mentor) {
        url = `/api/getposts/?callpage=${params.callpage}&perpage=10&custom_post=cma_thread&question=${params.mentor}`
    } else {
        url = `/api/getposts/?callpage=${params.callpage}&perpage=10&custom_post=cma_thread`
    }
    try {
        const data = await Axios({
            url: url,
            data: qs.stringify({
                ACCESS_TOKEN: params.token,
            })
        })
        return data
    } catch (error) {
        return error
    }
}

// function* workerGetQuestions(action) {
//     try {
//         const response = yield call(getQuestions, action.payload)
//         if (response.data.respond === 1) {
//             yield put({
//                 type: t.MENTOR_QUESTIONS_SUCCSESS,
//                 questions: response.data.result,
//                 pagging: response.data.paging,
//                 isLoadMore:action.payload.more
//             })
//         } else {
//             throw response
//         }
//     } catch (error) {
//         yield put({
//             type: t.MENTOR_QUESTIONS_FAILURE,
//             message: error.data.message
//         })
//     }
// }

function* workerGetQuestions(action) {
    const { page, topic, mentor, reset } = action.payload
    yield put({ type: t.MENTOR_QUESTIONS_LOADING, reset })
    try {
        let url = ''
        if (mentor && topic) {
            url = `${a.baseUrlApi}/getposts/?callpage=${page}&perpage=10&custom_post=cma_thread&taxonomy_id=${topic}&custom_meta_and={"cmaexp_expert_uid": "='${mentor}'"}`
        } else if (topic && !mentor) {
            url = `${a.baseUrlApi}/getposts/?callpage=${page}&perpage=10&custom_post=cma_thread&taxonomy_id=${topic}`
        } else if (!topic && mentor) {
            url = `${a.baseUrlApi}/getposts/?callpage=${page}&perpage=10&custom_post=cma_thread&question=${mentor}`
        } else {
            url = `${a.baseUrlApi}/getposts/?callpage=${page}&perpage=10&custom_post=cma_thread`
            // url = `/getposts/?callpage=1&perpage=10&custom_post=cma_thread`
        }

        const data = yield a.getDataFromApi({}, url, 'page')
        const newData = data.data
        const pages = data.page
        const isLoadMore = page > 1

        yield put({ type: t.MENTOR_QUESTIONS_SUCCSESS, data: newData, isLoadMore, pages })
    } catch (error) {
        yield put({
            type: t.MENTOR_QUESTIONS_FAILURE
        })
    }
}


const getQuestionDetail = async params => {
    try {
        const data = await Axios({
            url: `/api/getpost/?post_id=${params.id}`,
            data: qs.stringify({
                ACCESS_TOKEN: params.token,
            })
        })
        return data
    } catch (error) {
        return error
    }
}

function* workerGetQuestionDetail(action) {
    try {
        const response = yield call(getQuestionDetail, action.payload)
        if (response.data.respond === 1) {
            yield put({
                type: t.MENTOR_QUESTION_DETAIL_SUCCSESS,
                data: response.data.result[0]
            })
        } else {
            throw response
        }
    } catch (error) {
        yield put({
            type: t.MENTOR_QUESTION_DETAIL_FAILURE,
            message: error.data.message
        })
    }
}

const getAnswers = async params => {
    try {
        const data = await Axios({
            url: `api/getcomments/?post_id=${params.id}&callpage=1`,
            data: qs.stringify({
                ACCESS_TOKEN: params.token,
            })
        })
        return data
    } catch (error) {
        return error
    }
}

function* workerGetAnswers(action) {
    try {
        const response = yield call(getAnswers, action.payload)
        if (response.data.respond === 1) {
            yield put({
                type: t.MENTOR_COMMENT_SUCCSESS,
                data: response.data.result
            })
        } else {
            throw response
        }
    } catch (error) {
        yield put({
            type: t.MENTOR_COMMENT_FAILURE,
            // message: error.data.message
        })
    }
}

const getMentors = async params => {
    try {
        const data = await Axios({
            url: `/api/custom_service/?service=getmentor`,
            data: qs.stringify({
                ACCESS_TOKEN: params.token,
            })
        })
        return data
    } catch (error) {
        return error
    }
}


const answer = async params => {
    try {
        const data = await Axios({
            url: `/api/newcomment/?post_id=${params.post_id}&content=${params.content}&comment_type=cma_answer&comment_ip=127.0.0.1&custom_meta={"CMA_private_answer":"0","user_read":"0"}`,
            data: qs.stringify({
                ACCESS_TOKEN: params.token,
            })
        })
        return data
    } catch (error) {
        return error
    }
}

function* workerGetMentors(action) {
    try {
        const response = yield call(getMentors, action.payload)
        if (response.data.respond === 1) {
            let min = 0,
                max = response.data.result.length,
                random = Math.floor(Math.random() * (max - min)) + min


            yield put({
                type: t.MENTOR_GET_SUCCSESS,
                data: response.data.result,
                random: response.data.result[random]
            })
        } else {
            throw response
        }
    } catch (error) {
        yield put({
            type: t.MENTOR_GET_FAILURE,
            message: error.data.message
        })
    }
}

const getTopics = async params => {
    let url = ''
    if (params.topic) {
        url = `/api/custom_service/?service=gettopik&id_topik=${params.topic}`
    } else {
        url = `/api/custom_service/?service=gettopik`
    }
    try {
        const data = await Axios({
            url: url,
            data: qs.stringify({
                ACCESS_TOKEN: params.token,
            })
        })
        return data
    } catch (error) {
        return error
    }
}

function* workerGetTopics(action) {
    try {
        const response = yield call(getTopics, action.payload)
        if (response.data.respond === 1) {

            yield put({
                type: t.TOPICS_GET_SUCCSESS,
                data: response.data.result,
            })
        } else {
            throw response
        }
    } catch (error) {
        yield put({
            type: t.TOPICS_GET_FAILURE,
            message: error.data.message
        })
    }
}

const getMyQuestion = async params => {
    try {
        const data = await Axios({
            url: `/api/author_posts/?custom_post=cma_thread&author_id=${params.id}&callpage=1&perpage=10`,
            data: qs.stringify({
                ACCESS_TOKEN: params.token,
            })
        })
        return data
    } catch (error) {
        return error
    }
}

function* workerGetMyQuestions(action) {
    try {
        const response = yield call(getMyQuestion, action.payload)
        if (response.data.respond === 1) {

            yield put({
                type: t.MENTOR_MY_QUESTIONS_SUCCSESS,
                data: response.data.result,
            })
        } else {
            throw response
        }
    } catch (error) {
        yield put({
            type: t.MENTOR_MY_QUESTIONS_FAILURE
        })
    }
}

function* workerSubmitAnswer(action) {
    try {
        const response = yield call(answer, action.payload)
        if (response.data.respond === 1) {

            yield put({
                type: t.MENTOR_SUBMIT_ANSWER_SUCCSESS,
            })
        } else {
            throw response
        }
    } catch (error) {
        yield put({
            type: t.MENTOR_SUBMIT_ANSWER_FAILURE
        })
    }
}

const question = async params => {
    try {
        const data = await Axios({
            // url: `/api/newpost/?subject=Click here to answer it&content=Ini adalah pertanyaan yang dikirim dari API SAGA&custom_post=cma_thread&taxonomy=[cma_category,"Technology"]&custom_meta={"_resolved":"0","_author_ip":"::1","_views":"1","_votes":"0","_votes_question":"0","_votes_question_answers":"0","_highest_rated_answer":"0","_sticky_post":"0","_cmaexp_visibility":"experts"}`,
            url: `/api/newpost/?subject=Click here to answer it&content=${params.question}&custom_post=cma_thread&taxonomy=[cma_category,"${params.topic}"]&custom_meta={"_resolved":"0","_author_ip":"::1","_views":"1","_votes":"0","_votes_question":"0","_votes_question_answers":"0","_highest_rated_answer":"0","_sticky_post":"0","_cmaexp_visibility":"public"}`,
            data: qs.stringify({
                ACCESS_TOKEN: params.token,
            })
        })
        return data
    } catch (error) {
        return error
    }
}

function* workerSubmitQuestion(action) {
    try {
        const response = yield call(question, action.payload)
        if (response.data.respond === 1) {

            yield put({
                type: t.MENTOR_SUBMIT_QUESTION_SUCCSESS,
            })
        } else {
            throw response
        }
    } catch (error) {
        yield put({
            type: t.MENTOR_SUBMIT_QUESTION_FAILURE
        })
    }
}