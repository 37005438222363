export const Landing = "/"
export const notFound = "/404"
export const About = "/about"
export const FAQ = "/faq"
export const Login = "/login"
export const Register = "/register"

export const Home = "/feed"
export const AskMentor = '/ask'
export const AskMentorDetail = '/ask/:id'
export const Mentors = '/mentors'
export const Chat = "/chat"

export const Articles = "/articles"
export const DetailArticle = "/article/:id/:title"
export const Podcasts = "/podcasts"
export const Profile = "/profile"

export const Community = '/community'
export const CommunityHome = '/community/:id'
export const Event = '/community/:id/events'
export const EventDetail = '/community/:id/events/:idContent'
export const Member = '/community/:id/members'
export const Photo = '/community/:id/photos'
export const PhotoDetail = '/community/:id/photos/:idContent'
export const ArticleCommunity = '/community/:id/articles'
export const ArticleDetail = '/community/:id/articles/:idContent'
export const PodcastCommunity = '/community/:id/podcasts'

export const BikinEvent = '/bikin-event'
export const ListEvent = '/events'
export const RsvpEvent = '/rsvp/:id'
export const ListRsvp = '/rsvp/:id/list'
