import React from 'react'
import './styles.css'

function Spinner({ size, top, bottom }) {
    return (
        <div className={`flex justify-center mt-${top} mb-${bottom}`}>
            <div class={`loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-${size} w-${size}`}></div>
        </div>
    )
}

export { Spinner }
