import * as t from '../../const.config'

export const getPodcastCommunity = (item) => ({
    type: t.GET_PODCAST,
    payload: item
})

export const doResetDetailPodcast = () => ({
    type: t.RESET_DETAIL_PODCAST
})

export const getCategoryPodcast = () => ({
    type: t.HOME_PODCAST_SERIES,
})