import React, { useState, useEffect } from 'react'
import Icon from 'react-web-vector-icons'
import { Base, CardFeed, FlatList, Modal, Spinner } from '../../components'
import { AiOutlineSetting, AiOutlineInfoCircle, AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import { IoMdClose, IoMdCamera } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'
import * as a from '../../redux/actions'
import * as c from '../../redux/const.config'
import Toast from '../../utils/toast'

function PageProfile() {
    const [showEdit, setShowEdit] = useState(false)
    const [showChangePass, setShowChangePass] = useState(false)
    const { profile, isSuccessUpPhoto, isLoadingUpPhoto, isLoading } = useSelector(state => state.profile)
    const { user, loading, action, message } = useSelector(state => state.user)
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [phone, setPhone] = useState("")
    const [email, setEmail] = useState("")
    const [hobby, setHobby] = useState("")
    const [description, setDescription] = useState("")
    const [oldpassword, setOldpassword] = useState("")
    const [password, setPassword] = useState("")
    const [showOld, setShowOld] = useState(false)
    const [showNew, setShowNew] = useState(false)
    const [img, setImg] = useState({ url: profile?.avatar })
    const dispatch = useDispatch()
    const about = [
        { id: 0, label: 'Email', value: profile?.user_email || "-" },
        { id: 1, label: 'Nomor telepon', value: profile?.authormeta?.phone_number || "-" },
        { id: 2, label: 'Hobi', value: profile?.authormeta?.user_hobi || "-" },
        { id: 3, label: 'Bergabung sejak', value: profile?.user_registered || "-" },
        { id: 4, label: 'Deskripsi', value: profile?.description || "-" },
    ]

    const hasWhiteSpace = (text) => {
        return /^\s/.test(text)
    }

    const handleClickSave = () => {
        if (showEdit) {
            const bodyform = { firstName, lastName, email, description, phone, hobby }
            const body = new FormData()
            body.append('ACCESS_TOKEN', user.Access_Token)
            body.append('file', img?.file)
            body.append('userimg_fieldkey', 'smio_avatar')
            body.append('type', 'file')
            if (firstName && !hasWhiteSpace(firstName)) {
                dispatch(a.updateProfile(bodyform))
                profile?.avatar !== img?.url && dispatch(a.updateProfilePhoto(body))
            }
            else alert('First name cannot be empty or contain spaces')
        } else if (showChangePass) {
            const body = { oldpassword, password, token: user.access_token_data.token }
            if (oldpassword && !hasWhiteSpace(oldpassword) && oldpassword.length >= 6 && password && !hasWhiteSpace(password) && password.length >= 6)
                dispatch(a.changePassword(body))
        }
    }

    const handlePickImage = (e) => {
        e.preventDefault()
        const file = e.target.files[0]
        setImg({ url: URL.createObjectURL(file), file })
    }

    useEffect(() => {
        if (showEdit) {
            setFirstName(profile?.first_name)
            setLastName(profile?.last_name)
            setPhone(profile?.authormeta?.phone_number)
            setEmail(profile?.user_email)
            setHobby(profile?.authormeta?.user_hobi)
            setDescription(profile?.description)
            setImg({ url: profile?.avatar })
        } else if (showChangePass) {
            setOldpassword("")
            setPassword("")
            setShowOld(false)
            setShowNew(false)
        }
    }, [showEdit, showChangePass])

    useEffect(() => {
        if (isSuccessUpPhoto) {
            setShowEdit(false)
            dispatch(a.getProfile({ id: user?.ID }))
            Toast.info("Successful profile update")
        }
    }, [isSuccessUpPhoto])

    useEffect(() => {
        dispatch(a.getProfile({ id: user?.ID }))
        dispatch(a.doCheckToken())
    }, [])

    useEffect(() => {
        if (action == c.CHANGE_PASSWORD_SUCCSESS) {
            Toast.info(message)
            setShowChangePass(false)
        }
        if (action == c.CHANGE_PASSWORD_FAILURE) {
            Toast.error(message)
        }
        dispatch(a.clearAction())
    }, [action])

    const renderModalEdit = () => {
        const style = {
            label: 'text-sm font-semibold mb-1 text-gray-600',
            input: 'outline-none text-sm w-full py-2 px-3 mb-3 border rounded-md'
        }
        return (
            <Modal visible={showEdit} className="flex-col px-5 py-3" containerStyle="w-full">
                <div className="flex items-center mb-3">
                    <IoMdClose className="text-2xl mr-2" onClick={() => setShowEdit(false)} />
                    <p className="text-base font-semibold">Edit profil</p>
                </div>
                <div className="w-full flex justify-center mb-5">
                    <div className="relative">
                        <img src={img?.url} className="h-32 w-32 object-cover rounded-full border" />
                        <input type="file" onChange={handlePickImage} id="upload" hidden />
                        <label for="upload">
                            <IoMdCamera className="text-3xl mr-2 absolute right-0.5 bottom-0 bg-red-500 text-white rounded-full p-1" />
                        </label>
                    </div>
                </div>
                <div className="flex items-center w-full justify-between">
                    <div className="flex flex-col w-1/2">
                        <p className={style.label}>Nama Pertama</p>
                        <input
                            className={style.input}
                            placeholder="Nama pertama"
                            value={firstName}
                            onChange={e => setFirstName(e.target.value)}
                        />
                    </div>
                    <div className="flex flex-col w-1/2 ml-2">
                        <p className={style.label}>Nama Terakhir</p>
                        <input
                            className={style.input}
                            placeholder="Nama terakhir"
                            value={lastName}
                            onChange={e => setLastName(e.target.value)}
                        />
                    </div>
                </div>
                <p className={style.label}>Nomor Telepon</p>
                <input
                    className={style.input}
                    placeholder="Nomor Telepon"
                    value={phone}
                    onChange={e => setPhone(e.target.value)}
                />
                <p className={style.label}>Email</p>
                <input
                    className={style.input}
                    placeholder="Email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                />
                <p className={style.label}>Hobi</p>
                <input
                    className={style.input}
                    placeholder="Hobi"
                    value={hobby}
                    onChange={e => setHobby(e.target.value)}
                />
                <p className={style.label}>Deskripsi</p>
                <input
                    className={style.input}
                    placeholder="Deskripsi"
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                />
                <button
                    onClick={handleClickSave}
                    className="text-white bg-red-500 w-full rounded-md py-2 font-semibold text-sm mt-6 mb-3">
                    {isLoadingUpPhoto ? <Spinner size={5} /> : 'Simpan'}
                </button>
            </Modal>
        )
    }

    const IconEye = ({ show, onClick }) => {
        return (show ? <AiOutlineEyeInvisible onClick={onClick} /> : <AiOutlineEye onClick={onClick} />)
    }

    const renderModalChangePass = () => {
        const style = {
            label: 'text-sm font-semibold mb-1 text-gray-600',
            input: 'outline-none text-sm w-full mr-2',
            div: 'w-full py-2 px-3 mb-3 border rounded-md flex items-center justify-between'
        }
        const check = oldpassword && !hasWhiteSpace(oldpassword) && oldpassword.length >= 6 && password && !hasWhiteSpace(password) && password.length >= 6
        return (
            <Modal visible={showChangePass} className="flex-col px-5 py-3" containerStyle="w-full">
                <div className="flex items-center mb-6">
                    <IoMdClose className="text-2xl mr-2" onClick={() => setShowChangePass(false)} />
                    <p className="text-base font-semibold">Change Password</p>
                </div>
                <p className="text-sm mb-3 text-gray-600">Creating a password helps you keep your account safe</p>
                <p className={style.label}>Old Password</p>
                <div className={style.div}>
                    <input
                        className={style.input}
                        placeholder="Old Password"
                        value={oldpassword}
                        onChange={e => setOldpassword(e.target.value)}
                        type={showOld ? 'text' : 'password'}
                    />
                    <IconEye show={showOld} onClick={() => setShowOld(!showOld)} />
                </div>
                <p className={style.label}>New Password</p>
                <div className={style.div}>
                    <input
                        className={style.input}
                        placeholder="New Password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        type={showNew ? 'text' : 'password'}
                    />
                    <IconEye show={showNew} onClick={() => setShowNew(!showNew)} />
                </div>
                <button
                    onClick={handleClickSave}
                    className={`text-white ${check ? 'bg-red-500' : 'bg-gray-200'} w-full rounded-md py-2 font-semibold text-sm mt-6 mb-3`}>
                    {loading ? <Spinner size={5} /> : 'Save'}
                </button>
            </Modal>
        )
    }

    return (
        <Base
            primary
            title="Profile"
            hideSearch
            main={
                <div>
                    {isLoading ?
                        <Spinner size={12} top={10} /> :
                        <>
                            <CardFeed>
                                <div className="flex flex-col items-center">
                                    <div className="w-auto">
                                        <img src={profile?.avatar} alt={profile?.first_name}
                                            className="h-44 w-44 object-cover rounded-full border bg-gray-100" />
                                    </div>
                                    <div className="text-center flex flex-col items-center">
                                        <p className="text-2xl mt-2 font-semibold">
                                            {profile?.first_name} {profile?.last_name}
                                        </p>
                                        <p className="text-base flex items-center">
                                            <Icon
                                                name='star'
                                                font='AntDesign'
                                                color='#EF4444'
                                                size={22}
                                            />
                                            <p className="ml-1 font-light">
                                                {profile?.authormeta?.mycred_default} poin
                                            </p>
                                        </p>
                                    </div>
                                </div>
                            </CardFeed>
                            <CardFeed>
                                <div className="flex items-center">
                                    <p className="font-semibold mb-3">Tentang</p>
                                    <AiOutlineInfoCircle className="-mt-2.5 ml-2" />
                                </div>
                                <FlatList
                                    data={about}
                                    renderItem={({ item }) => (
                                        <div key={item.id} className="flex items-center mb-1">
                                            <p className="text-xs sm:text-sm text-gray-700 w-1/3">{item.label}</p>
                                            <p className="text-xs sm:text-sm text-gray-700">{item.value}</p>
                                        </div>
                                    )}
                                />
                            </CardFeed>
                            <CardFeed>
                                <div className="flex items-center">
                                    <p className="font-semibold mb-3">Pengaturan</p>
                                    <AiOutlineSetting className="-mt-2.5 ml-2" />
                                </div>
                                <div className="flex flex-col items-start">
                                    <button
                                        onClick={() => setShowEdit(true)}
                                        className="text-xs sm:text-sm text-gray-700 mb-1 mr-1">
                                        Edit profil
                                    </button>
                                    <button
                                        onClick={() => setShowChangePass(true)}
                                        className="text-xs sm:text-sm text-gray-700">
                                        Ganti password
                                    </button>
                                </div>
                            </CardFeed>
                            {renderModalEdit()}
                            {renderModalChangePass()}
                        </>
                    }
                </div>
            }
            right={<div />}
        />
    )
}

export { PageProfile }
