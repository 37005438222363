import * as P from '../pages'
const { Title } = require('./title');

export const Landing = Title({ component: P.Landing })
export const FAQ = Title({ component: P.FAQ, title: 'FAQ' })
export const Login = Title({ component: P.Login, title: 'Login' })
export const Register = Title({ component: P.Register, title: 'Register' })

export const Feed = Title({ component: P.Feed, title: 'Feed' })
export const CommunityHome = Title({ component: P.CommunityHome, title: 'Community' })
export const Event = Title({ component: P.Event, title: 'Event' })
export const EventDetail = Title({ component: P.EventDetail, title: 'Event' })
export const Photo = Title({ component: P.Photo, title: 'Photos' })
export const PhotoDetail = Title({ component: P.PhotoDetail, title: 'Photos' })
export const Member = Title({ component: P.Member, title: 'Member' })

export const Chat = Title({ component: P.Chat, title: 'Chat' })

export const AskMentor = Title({ component: P.FeedAskMentor, title: 'AskMentor' })
export const AskMentorDetail = Title({ component: P.AskMentorDetail, title: 'AskMentor' })
export const Mentors = Title({ component: P.Mentors, title: 'AskMentor' })

export const Articles = Title({ component: P.ListArticles, title: 'Articles' })
export const DetailArticle = Title({ component: P.DetailArticle })

export const Podcasts = Title({ component: P.ListPodcasts, title: 'Podcasts' })
export const Profile = Title({ component: P.PageProfile, title: 'Profile' })

export const FormEvent = Title({ component: P.FormEvent, title: 'Bikin Event' })
export const ListEvent = Title({ component: P.ListEvent, title: 'List Event' })
export const RsvpEvent = Title({ component: P.RsvpEvent, title: 'RSVP Event' })
export const ListRsvp = Title({ component: P.ListRsvp, title: 'List RSVP' })
