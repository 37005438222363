import { takeEvery, put } from 'redux-saga/effects'
import * as t from '../../const.config'
import * as a from '../../../config/api'

export const PodcastCommunity = [
    takeEvery(t.GET_PODCAST, workerGetPodcast),
    takeEvery(t.HOME_PODCAST_SERIES, workerGetSeries)
]

function* workerGetPodcast(action) {
    const { id, idPodcast, type, keyword, page, reset = true, series } = action.payload
    yield put({ type: t.GET_PODCAST_START, reset: type === "byId" ? false : reset })
    try {
        const body = {
            community_id: id,
            podcast_id: type === "byId" ? idPodcast : null,
            search: keyword,
            callpage: page,
            series_id: series,
            perpage: 10
        }
        const data = yield a.getDataFromApi(body, a.getPodcastApi, 'page')
        const state = type === "byId" ? "detailPodcast" : "listPodcast"
        let arrayId = []
        if (type !== "byId") arrayId = data.data.map(x => x.id)
        const newData = type === "byId" ? data.data[0] : data.data
        const pages = data.page
        const isLoadMore = page > 1

        yield put({ type: t.GET_PODCAST_SUCCESS, data: newData, state, arrayId, isLoadMore, pages })
    } catch (error) {
        yield put({ type: t.GET_PODCAST_FAILED })
    }
}

function* workerGetSeries() {
    yield put({ type: t.HOME_PODCAST_SERIES_START })
    try {
        const data = yield a.getDataFromApi({}, a.getSeriesPodcastApi)

        yield put({ type: t.HOME_PODCAST_SERIES_SUCCSESS, data })
    } catch (error) {
        yield put({ type: t.HOME_PODCAST_SERIES_FAILURE })
    }
}