import { images } from "../assets";

const { link } = require("./LinkEksternal");

const socialMedia = [
    { name:'instagram', img: "instagram", path:link.instagram },
    { name:'linkedin', img: "linkedin-square", path:link.linkedin }
]
const info = {
    logo:images.scWhite,
    address: 'Jl. Kemang Selatan IX No.1B, RT.1/RW.2, Bangka, Kec. Mampang Prpt., Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12730 (021) 71796721',
}

export {socialMedia, info}