import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Dialog } from '@headlessui/react'
import { images, link } from '../assets'
import Icon from 'react-web-vector-icons'
import { useSelector, useDispatch } from 'react-redux'
import { Menu } from '@headlessui/react'
import { classNamesMenu, DropDown } from './DropDown'
import { menuAccount, menuAccountLanding, menuExit, menuAuth } from '../Dao'
import { Login, Profile } from '../routers/const'
import { SlideOver } from './NavPosition/SlideOver'
import * as a from '../redux/actions'
import { Alert } from './Alert'

function Header(props) {
    const { isSuccessSaveToken, user, logout, loading } = useSelector(state => state.user)
    const dispatch = useDispatch()
    const history = useHistory()
    const [open, setOpen] = React.useState(false)
    const [showAlert, setShowAlert] = React.useState(false)
    const [mobile, setMobile] = React.useState(true)

    const showButton = () => {
        if (window.innerWidth <= 1025) {
            setMobile(false)
        } else {
            setMobile(true)
        }
    }

    const popUP = () => {
        setOpen(false)
        setShowAlert(true)
    }

    const handleClickLogout = () => {
        dispatch(a.signOut(user?.access_token_data.token))
        dispatch(a.doResetProfile('profile'))
        dispatch(a.doResetData())
        // qiscus.logout()
        // qiscus.disconnect()
    }

    React.useEffect(() => {
        showButton();
    }, [])

    // document
    window.addEventListener('resize', showButton)


    return (
        <nav className="flex sticky top-0 z-30 bg-white items-center my-3 p-3 container justify-between flex-wrap p-2 min-w-full">
            <Link to={{ pathname: '/' }} className="flex items-center text-white">
                <img src={images.logo.default} className="h-7 object-contain" />
            </Link>
            {!logout ? <Alert
                title="Keluar"
                subTitle="Apa kamu yakin akan keluar?"
                visible={showAlert}
                onClick={handleClickLogout}
                onClose={() => setShowAlert(false)}
                loading={loading}
            /> : null}
            {isSuccessSaveToken ?
                <SlideOver
                    handleClick={() => setOpen(!open)}
                    openModal={open}
                    title={
                        <button onClick={() => history.push(Profile)}>
                            <Dialog.Title className="text-lg font-medium text-gray-900">
                                <img src={user?.avatar} alt={user?.first_name}
                                    className="h-9 w-9 object-cover rounded-full border" />
                            </Dialog.Title>
                        </button>
                    }
                    itemData={
                        <>
                            {menuAccount.map(item => (
                                <button onClick={() => history.push({ pathname: item.path, state: { fromSide: true } })}>
                                    <Icon
                                        name={item.icon}
                                        font='AntDesign'
                                        color='#4B5563'
                                        size={18}
                                    />
                                    <span className="ml-2">
                                        {item.name}
                                    </span>
                                </button>
                            ))}
                            {/* <div className="flex flex-col items-start py-4 w-full space-y-5 border-t">
                                {menuAccountLanding.map(item => (
                                    <button onClick={() => window.open(item.path, "_blank")}>
                                        <Icon
                                            name={item.icon}
                                            font={item.type}
                                            color='#4B5563'
                                            size={18}
                                        />
                                        <span className="ml-2">
                                            {item.name}
                                        </span>
                                    </button>
                                ))}
                            </div> */}
                            <div className="flex flex-col items-start py-4 w-full space-y-5 border-t">
                                <button onClick={() => popUP()}>
                                    <Icon
                                        name={menuExit.icon}
                                        font={menuExit.type}
                                        color='#4B5563'
                                        size={18}
                                    />
                                    <span className="ml-2">
                                        {menuExit.name}
                                    </span>
                                </button>
                            </div>
                        </>
                    }
                />
                :
                <SlideOver
                    handleClick={() => setOpen(!open)}
                    openModal={open}
                    itemData={
                        <>
                            {/* {menuAccountLanding.map(item => (
                                <button onClick={() => history.push(item.path)}>
                                    <Icon
                                        name={item.icon}
                                        font={item.type}
                                        color='#4B5563'
                                        size={18}
                                    />
                                    <span className="ml-2">
                                        {item.name}
                                    </span>
                                </button>
                            ))} */}
                            {menuAuth.map(item => (
                                <button className={`${item.style} border border-red-500 w-full p-2 rounded-md`} onClick={() => history.push(item.path)}>
                                    {item.name}
                                </button>
                            ))}
                        </>
                    }
                />
            }
            <div className="block lg:hidden">
                <button onClick={() => setOpen(!open)} className="flex items-center">
                    <Icon
                        name='bars'
                        font='AntDesign'
                        color='#4B5563'
                        size={24}
                    />
                </button>
            </div>
            {props.landing ?
                <div className="w-full block lg:flex lg:items-center lg:w-auto">
                    {mobile ?
                        <div className="text-center">
                            <div className="m-auto h-screen lg:h-auto flex justify-center flex-col items-center lg:flex-row">
                                <div className="flex md:justify-around flex-col space-y-5 lg:space-y-0 h-1/4 lg:h-auto lg:flex-row lg:h-auto">
                                    {menuAccountLanding.map(item => (
                                        <a href={item.path} target={item.external ? "_blank" : null} className="block mt-4 lg:inline-block lg:mt-0 text-teal-lighter hover:text-red-500 lg:mr-8">
                                            {item.name}
                                        </a>
                                    ))}
                                </div>

                                <div className="self-center mt-8 lg:mt-0">
                                    <div className="flex flex-col items-start mt-4 space-y-5 lg:hidden">
                                        {menuAccount.map(item => (
                                            <button onClick={() => history.push(item.path)}>
                                                <Icon
                                                    name={item.icon}
                                                    font='Feather'
                                                    color='#4B5563'
                                                    size={18}
                                                />
                                                <span className="ml-2">
                                                    {item.name}
                                                </span>
                                            </button>
                                        ))}
                                    </div>
                                    {isSuccessSaveToken ?
                                        <DropDown
                                            smHide
                                            title={
                                                <img src={user?.avatar} alt={user?.first_name} className="h-9 w-9 object-cover rounded-full border" />
                                            }
                                            renderItem={
                                                <div>
                                                    {menuAccount.map(item => (
                                                        <Menu.Item>
                                                            {({ active }) => (
                                                                <button
                                                                    onClick={() => history.push(item.path)}
                                                                    className={classNamesMenu(
                                                                        active ? 'bg-red-100 text-gray-900' : 'text-gray-700',
                                                                        'block w-full text-left px-4 py-2 text-sm'
                                                                    )}
                                                                >
                                                                    <Icon
                                                                        name={item.icon}
                                                                        font='AntDesign'
                                                                        color='#4B5563'
                                                                        size={18}
                                                                    />
                                                                    <span className="ml-2">
                                                                        {item.name}
                                                                    </span>
                                                                </button>
                                                            )}
                                                        </Menu.Item>
                                                    ))}
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <button
                                                                onClick={() => setShowAlert(true)}
                                                                className={classNamesMenu(
                                                                    active ? 'bg-red-100 text-gray-900' : 'text-gray-700',
                                                                    'block w-full text-left px-4 py-2 text-sm'
                                                                )}
                                                            >
                                                                <Icon
                                                                    name={menuExit.icon}
                                                                    font={menuExit.type}
                                                                    color='#4B5563'
                                                                    size={18}
                                                                />
                                                                <span className="ml-2">
                                                                    {menuExit.name}
                                                                </span>
                                                            </button>
                                                        )}
                                                    </Menu.Item>
                                                </div>
                                            }
                                        />
                                        :
                                        <button onClick={() => history.push(Login)}
                                            className="text-red-500 hover:bg-red-700 
                                    hover:text-white outline border border-red-500 
                                    focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-opacity-50 
                                    p-2 my-2 lg:my-0 rounded"
                                        >
                                            Masuk
                                        </button>
                                    }
                                </div>
                            </div>
                        </div>
                        : null}
                </div>
                :
                mobile ?
                    <div className="flex">
                        <form className="mr-3">
                            <input
                                placeholder="Search Community"
                                className="border bg-gray-100 h-8 p-3 rounded-full focus:outline-none focus:ring-2 focus:ring-red-600 focus:border-transparent" />
                        </form>
                        <img src='https://cdn2.iconfinder.com/data/icons/avatars-99/62/avatar-370-456322-512.png' className="h-9 object-contain" />
                    </div> : null
            }
        </nav>
    )
}

export { Header }
