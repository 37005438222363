import * as t from '../../const.config'

export const getEventCommunity = (item) => ({
    type: t.GET_EVENT_COMMUNITY,
    payload: item
})

export const doRegisterEventCommunity = (item) => ({
    type: t.DO_REGISTER_EVENT,
    payload: item
})

export const doResetEventDetail = () => ({
    type: t.DO_RESET_EVENT_DETAIL
})