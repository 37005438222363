import RoutersConfig from './routers';
import React from 'react'
import qiscus from './utils/qiscusSDK'

function App() {
  React.useEffect(() => {
    window.addEventListener('beforeunload', function (ev) {
      if (qiscus && qiscus.isLogin && Object.keys(qiscus).length) {
        ev.preventDefault()
        qiscus.updateProfile({ extras: { online: false } })
        ev.returnValue = '';
      }
    });
    window.addEventListener('unload', function (ev) {
      if (qiscus && qiscus.isLogin && Object.keys(qiscus).length) {
        ev.preventDefault()
        qiscus.updateProfile({ extras: { online: false } })
        ev.returnValue = '';
      }
    });
    return () => {
      if (qiscus && qiscus.isLogin && Object.keys(qiscus).length) {
        qiscus.updateProfile({ extras: { online: false } })
      }
    }
  }, [])

  return (
    <div className="">
      <RoutersConfig />
    </div>
  );
}

export default App;
