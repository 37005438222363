import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import Icon from 'react-web-vector-icons'
import { Menu } from '@headlessui/react'
import { images } from '../../assets'
import { menuAccount } from '../../Dao'
import { classNamesMenu, DropDown } from '../DropDown'
import { Alert } from '../Alert'
import { Profile } from '../../routers/const'
import { useSelector, useDispatch } from 'react-redux'
import * as a from '../../redux/actions'
import * as c from '../../redux/const.config'

function Left() {
    const { user } = useSelector(state => state.user)
    const { profile } = useSelector(state => state.profile)
    const { action } = useSelector(state => state.profile)
    const { count } = useSelector(state => state.chat)
    const dispatch = useDispatch()
    const history = useHistory()
    const [enter, setEnter] = React.useState(false)
    const [showAlert, setShowAlert] = React.useState(false)
    const [enterName, setEnterName] = React.useState(null)
    const active_btn = (item) => window.location.pathname === item.path
    const onHoverActive = (item) => {
        setEnter(true)
        setEnterName(item)
    }

    const onHoverDeactive = () => {
        setEnter(false)
        setEnterName(null)
    }

    const handleClickLogout = () => {
        dispatch(a.doSetCountChat({ all: 0 }))
        dispatch(a.signOut(user?.access_token_data.token))
    }

    React.useEffect(() => {
        if (action == c.SIGN_OUT_SUCCSESS) {
            dispatch(a.doResetProfile('profile'))
            dispatch(a.doResetData())
        }
    }, [action])

    return (
        <div>
            <section className="md:flex flex-col h-full fixed pt-5 pb-5 rounded-lg">
                <Link to={{ pathname: '/community' }} className="flex items-center flex-no-shrink text-white mr-6 mb-6 mx-5 md:mr-16">
                    <img src={images.logo.default} className="h-8 object-contain" />
                </Link>
                <Alert
                    title="Keluar"
                    subTitle="Apa kamu yakin akan keluar?"
                    visible={showAlert}
                    onClick={handleClickLogout}
                    onClose={() => setShowAlert(false)}
                />
                <div className="flex flex-col justify-between h-full mb-14">
                    <div>
                        {menuAccount.map(item => (
                            <div onMouseEnter={() => onHoverActive(item.label)} onMouseLeave={() => onHoverDeactive()}>
                                <button
                                    className={`
                                    flex
                                    flex-row 
                                    items-center 
                                    md:mr-16 mt-3
                                    rounded-full 
                                    pl-3 pr-3
                                    transition duration-200 ease-in-out relative block 
                                    hover:translate-x-2px 
                                    hover:font-semibold
                                    hover:text-red-500
                                    hover:bg-red-100 
                                    ${active_btn(item) ? 'text-red-500' : 'text-gray-600'}
                                    focus:ring-2 focus:ring-red-600 focus:ring-opacity-50
                                    font-medium`}
                                    onClick={() => history.push({ pathname: item.path, state: { fromSide: true, param: item.param } })}
                                >
                                    <Icon
                                        name={item.icon}
                                        font='AntDesign'
                                        color={`${active_btn(item) || (enterName == item.name && enter) ? '#EF4444' : '#4B5563'}`}
                                        size={27}
                                    />
                                    <p className="ml-3 hidden md:flex text-md">
                                        {item.name}
                                    </p>
                                    {count.all !== 0 && item.name === 'Message' &&
                                        <div className={`bg-red-500 rounded-full mt-0.5 text-xs text-white ml-1.5 h-4 ${count.all.toString().length < 3 ? 'w-4' : 'w-5'}`}>
                                            {count.all}
                                        </div>
                                    }
                                </button>
                            </div>
                        ))}
                    </div>
                    <div class="flex w-4/5 xl:w-full">
                        <DropDown
                            title={
                                <button
                                    className={`
                                flex
                                flex-row 
                                items-center 
                                rounded-lg
                                p-3
                                transition duration-200 ease-in-out relative block 
                                hover:translate-x-2px 
                                hover:font-semibold
                                hover:text-red-500
                                hover:bg-red-100
                                focus:ring-2 focus:ring-red-600 focus:ring-opacity-50
                                font-medium`}
                                >
                                    <div className="flex items-center">
                                        <div className="flex flex-wrap justify-center">
                                            <div className="w-10">
                                                <img src={profile?.avatar || user?.avatar} alt={profile?.first_name || user?.first_name}
                                                    className="h-9 w-9 object-cover rounded-full border" />
                                            </div>
                                        </div>
                                        <p className="ml-2 text-lg">
                                            {profile?.first_name || user?.first_name} {profile?.last_name || user?.last_name}
                                        </p>
                                    </div>
                                </button>
                            }
                            style="bottom-20 w-full"
                            renderItem={
                                <div>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <button
                                                onClick={() => history.push(Profile)}
                                                className={classNamesMenu(
                                                    active ? 'bg-red-100 text-gray-900' : 'text-gray-700',
                                                    'block w-full text-left px-4 py-2 text-sm'
                                                )}
                                            >
                                                <Icon
                                                    name='user'
                                                    font='Feather'
                                                    color='#4B5563'
                                                    size={18}
                                                />
                                                <span className="ml-2">
                                                    Profile
                                                </span>
                                            </button>
                                        )}
                                    </Menu.Item>

                                    <Menu.Item>
                                        {({ active }) => (
                                            <button
                                                onClick={() => setShowAlert(true)}
                                                className={classNamesMenu(
                                                    active ? 'bg-red-100 text-gray-900' : 'text-gray-700',
                                                    'block w-full text-left px-4 py-2 text-sm'
                                                )}
                                            >
                                                <Icon
                                                    name='log-out'
                                                    font='Feather'
                                                    color='#4B5563'
                                                    size={18}
                                                />
                                                <span className="ml-2">
                                                    Keluar
                                                </span>
                                            </button>
                                        )}
                                    </Menu.Item>
                                </div>
                            }
                        />
                    </div>
                </div>
            </section>
        </div >
    )
}

export { Left }
