import * as t from '../../const.config'

const initialState = {
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    listPodcast: [],
    detailPodcast: {},
    listCategory: [],
    arrayId: [],
    pages: 0
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case t.GET_PODCAST_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
                isFailed: false,
                listPodcast: action.reset ? [] : state.listPodcast,
                detailPodcast: {}
            }
        case t.GET_PODCAST_SUCCESS:
            const stateData = state.listPodcast
            const data = action.isLoadMore ? stateData.concat(action.data) : action.data
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                [action.state]: data,
                arrayId: action.state === "listPodcast" ? action.arrayId : state.arrayId,
                pages: action.pages
            }
        case t.GET_PODCAST_FAILED:
            return {
                ...state,
                isLoading: false,
                isFailed: true,
            }
        case t.RESET_DETAIL_PODCAST:
            return {
                ...state,
                detailPodcast: {}
            }

        case t.HOME_PODCAST_SERIES_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
                isFailed: false,
            }
        case t.HOME_PODCAST_SERIES_SUCCSESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                listCategory: action.data
            }
        case t.HOME_PODCAST_SERIES_FAILURE:
            return {
                ...state,
                isLoading: false,
                isFailed: true,
            }
        default:
            return state;
    }
}

export default reducer;