import React, { useState, useEffect } from 'react'
import { Base, FlatList, TabCategory } from '../../components'
import { useDispatch, useSelector } from 'react-redux'
import * as a from '../../redux/actions'
import { useHistory } from 'react-router-dom'
import { BikinEvent, RsvpEvent } from '../../routers/const'
import Icon from 'react-web-vector-icons'

let tempTab = 0
function ListEvent(props) {
    const history = useHistory()
    const [page, setPage] = useState(1)
    const [activeTab, setActiveTab] = useState(tempTab)
    const { user } = useSelector(state => state.user)
    const { listEvent, isLoading, pages } = useSelector(state => state.eventCommunity)
    const dispatch = useDispatch()
    const listCtg = [
        { id: 0, name: 'Semua' },
        { id: 1, name: 'My Event' },
    ]

    const fetchData = (value, reset, resetPage) => {
        dispatch(a.getEventCommunity({
            id: !activeTab ? null : user.ID,
            idEvent: null,
            typeList: 'user',
            // keyword,
            page: value ? value : 1,
            reset,
            typeEvent: 'upcoming'
        }))
        dispatch(a.doResetBikinEvent())
    }

    const loadMore = () => {
        setTimeout(() => {
            if (listEvent.length % 10 == 0 && !isLoading && page + 1 <= pages) {
                setPage(page + 1)
                fetchData(page + 1, false)
            }
        }, 500)
    }

    const tabFilter = (item) => {
        setActiveTab(item)
        tempTab = item
    }

    useEffect(() => { fetchData() }, [activeTab])

    const renderItem = ({ item, index }) => {
        return (
            <button
                key={item.id}
                onClick={() => history.push({ pathname: RsvpEvent.replace(':id', item.id), state: { ...item, tab: activeTab } })}
                className={`bg-white flex flex-row items-center ${index == listEvent.length - 1 ? 'mb-20 sm:mb-5' : 'mb-2'}`}>
                <img src={item?.featuredthumb[0]?.image || "https://heduparts.com/uploads/placeholder.png"} className="h-20 w-20 object-cover rounded-md bg-gray-50" />
                <div className="px-3 py-1 flex flex-col items-start">
                    <p className="text-xs sm:text-sm text-red-500">{item?.tanggal_event}</p>
                    <p className="text-sm sm:text-md font-bold break-words my-0.5 text-left">{item?.post_title}</p>
                    <p className="text-xs sm:text-sm text-gray-500">{item?.display_name}</p>
                </div>
            </button>
        )
    }

    return (
        <Base
            primary
            title={'Events'}
            rightIcon={() => !activeTab ? null : (
                <button onClick={() => history.push({ pathname: BikinEvent, state: { type: 'add' } })} class="flex items-center border rounded-md border-red-500 px-2 py-0.5">
                    <Icon
                        name='plus'
                        font='AntDesign'
                        color='#EF4444'
                        size={18}
                    />
                    <p className="ml-1 text-sm text-red-500">Tambah</p>
                </button>
            )}
            main={
                <div class="px-3">
                    <div className="mb-5">
                        <TabCategory
                            data={listCtg}
                            active={activeTab}
                            class="shadow-md border-0"
                            onPressItem={tabFilter}
                            id="id"
                        />
                    </div>
                    <FlatList
                        data={listEvent}
                        renderItem={renderItem}
                        loading={isLoading}
                        onEndReached={loadMore}
                        hasMore={page + 1 <= pages}
                        page={page}
                    />
                </div>
            }
            right={<div />}
        />
    )
}

export { ListEvent }
