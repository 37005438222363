import * as a from "../const.config";

export const getUserList = (item) => ({
    type: a.GET_USER_LIST,
    payload: item
})

export const doResetUserList = () => ({
    type: a.DO_RESET_USER_LIST
})

export const doSetCountChat = (item) => ({
    type: a.DO_SET_COUNT_CHAT,
    payload: item
})

export const getRoomDetail = (item) => ({
    type: a.GET_ROOM_DETAIL,
    payload: item
})

export const doResetRoomDetail = () => ({
    type: a.DO_RESET_ROOM_DETAIL
})

export const createGroup = (item) => ({
    type: a.CREATE_GROUP,
    payload: item
})

export const editDesc = (item) => ({
    type: a.EDIT_DESC,
    payload: item
})

export const addParticipants = (item) => ({
    type: a.ADD_PARTICIPANTS,
    payload: item
})

export const updateAvatarGroup = (item) => ({
    type: a.UPDATE_AVATAR_GROUP,
    payload: item
})

export const doMakeAdminGroup = (item) => ({
    type: a.DO_MAKE_ADMIN_GROUP,
    payload: item
})

export const removeParticipants = (item) => ({
    type: a.REMOVE_PARTICIPANTS,
    payload: item
})

export const getListChatGroup = (item) => ({
    type: a.GET_LIST_CHAT_GROUP,
    payload: item
})

export const updateGroupName = (item) => ({
    type: a.UPDATE_GROUP_NAME,
    payload: item
})

export const setChatState = (item) => ({
    type: a.SET_CHAT_STATE,
    payload: item
})