import {
    CHANGE_PASSWORD_LOADING, CLEAR_ACTION, FORGET_PASSWORD_LOADING, SECRET_CODE_LOADING, SIGN_IN_LOADING,
    SIGN_OTP_LOADING, SIGN_OUT_LOADING, SIGN_PHONE_LOADING, SIGN_UP_LOADING, VERIFY_PHONE_LOADING, DO_SAVE_DEVICE_TOKEN,
    DO_RESET_REGISTER_STATUS, DO_CHECK_TOKEN, DO_RESET_LOGIN_STATUS, DO_SET_STATE_AUTH
} from "../const.config";

export const signInWatcher = data => ({
    type: SIGN_IN_LOADING,
    payload: data
})

export const signInOTPWatcher = data => ({
    type: SIGN_OTP_LOADING,
    payload: data
})

export const signPhoneWatcher = data => ({
    type: SIGN_PHONE_LOADING,
    payload: data
})

export const signOut = data => ({
    type: SIGN_OUT_LOADING,
    payload: data
})

export const forgotPassword = data => ({
    type: FORGET_PASSWORD_LOADING,
    payload: data
})

export const secretCode = data => ({
    type: SECRET_CODE_LOADING,
    payload: data
})

export const clearAction = () => ({
    type: CLEAR_ACTION
})

export const signUp = data => ({
    type: SIGN_UP_LOADING,
    payload: data
})

export const verifyPhone = data => ({
    type: VERIFY_PHONE_LOADING,
    payload: data
})

export const changePassword = data => ({
    type: CHANGE_PASSWORD_LOADING,
    payload: data
})

export const doSaveDeviceToken = data => ({
    type: DO_SAVE_DEVICE_TOKEN,
    payload: data
})

export const doResetRegisterStatus = () => ({
    type: DO_RESET_REGISTER_STATUS
})

export const doCheckToken = () => ({
    type: DO_CHECK_TOKEN
})

export const doResetLoginStatus = () => ({
    type: DO_RESET_LOGIN_STATUS
})

export const doSetStateAuth = (data) => ({
    type: DO_SET_STATE_AUTH,
    payload: data
})