import * as t from '../const.config'

const initialState = {
    points:{},
    loading:false,
    success:false
}

const reducerPoint = (state=initialState, action) => {
    switch(action.type){
        case t.ADD_PONT_START:
            return{
                ...state,
                loading:true,
                success:false
            }
        case t.ADD_PONT_SUCCESS:
            return{
                ...state,
                points:action.point,
                loading:false,
                success:true
            }
        case t.ADD_PONT_FAILED:
            return{
                ...state,
                loading:false,
                points:{},
                success:false
            }
        default:
            return state
    }
}

export {reducerPoint}