import React, { useState, useEffect } from 'react'
import { Base, CardFeed, CommunityPost, Right, TabCategory } from '../../components'
import Icon from 'react-web-vector-icons'
import * as a from '../../redux/actions'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import * as s from '../../routers/const'
import { images } from '../../assets'

function CommunityHome(props) {
    const [page, setPage] = useState(1)
    const [showAbout, setShowAbout] = useState(false)
    const dispatch = useDispatch()
    const history = useHistory()
    const params = useParams()
    const data = useSelector(state => state.feedCommunity.profile)[0] || {}
    const { listPostbyId, isLoading, followData, pages } = useSelector(state => state.feedCommunity)
    const about = [
        { id: 0, label: 'Email', value: data?.email || "-", color: 'blue' },
        { id: 1, label: 'City', value: data?.city_name || "-", color: 'blue' },
        { id: 2, label: 'Province', value: data?.province_name || "-", color: 'blue' },
        { id: 3, label: 'Category', value: data?.author_category_name || "-" },
        { id: 4, label: 'Member', value: data?.followers },
        { id: 5, label: 'Contact', value: data?.phone_number },
    ]
    const listCtg = [
        { id: 1, name: 'Articles', onPress: () => navTo(s.ArticleCommunity) },
        { id: 2, name: 'Events', onPress: () => navTo(s.Event) },
        { id: 3, name: 'Photos', onPress: () => navTo(s.Photo) },
        { id: 4, name: 'Members', onPress: () => navTo(s.Member) },
        { id: 5, name: 'Podcast', onPress: () => navTo(s.PodcastCommunity) },
    ]

    const fetchData = (reset, loading, value) => {
        const id = params?.id
        dispatch(a.getPostCommunity({
            item: {
                community_id: id,
                callpage: value ? value : page
            }, reset, loading
        }))
    }

    const fetchProfile = () => {
        dispatch(a.getProfileCommunity({ id: params?.id }))
    }

    const navTo = (screen) => {
        history.push({
            pathname: screen.replace(':id', data?.ID),
            state: { title: data?.community_name, fromSide: false }
        })
    }

    const doFollow = () => {
        dispatch(a.doFollowCommunity({
            id: data?.ID,
            type: data?.is_follow == "false" ? "follow" : "unfollow"
        }))
    }

    const doLike = async (id, type) => {
        if (!isLoading) {
            await dispatch(a.doLikePost({ id, type }))
            fetchData(false)
        }
    }

    const handleSeeMore = (item, type) => {
        const push = (page, id) => history.push(
            page.replace(':id', item.author_id).replace(':idContent', id || item.post_id),
        )
        switch (type) {
            case 'Article':
                push(s.ArticleDetail)
                break;
            case 'Event':
                push(s.EventDetail)
                break;
            case 'Podcast':
                history.push(
                    s.PodcastCommunity.replace(':id', item.author_id),
                )
                break;
            case 'Photo':
                const id = item.images.length && item.images[item.images.length - 1].id
                push(s.PhotoDetail, id)
                break;
        }
    }

    const loadMore = () => {
        setTimeout(() => {
            if (listPostbyId.length % 10 == 0 && page + 1 <= pages) {
                setPage(page + 1)
                fetchData(false, true, page + 1)
            }
        }, 500)
    }

    useEffect(() => {
        if (followData?.success && followData?.id == data?.ID) fetchProfile()
    }, [followData])

    useEffect(() => {
        fetchData(true)
        fetchProfile()
    }, [])

    useEffect(() => {
        if (listPostbyId && listPostbyId.length && listPostbyId[0].followers !== data?.followers) {
            fetchProfile()
        }
    }, [listPostbyId])

    const renderBadge = (item) => {
        let array = []
        let image = null
        switch (item.badge_type) {
            case 'bronze':
                image = images.badge.bronze
                break;
            case 'silver':
                image = images.badge.silver
                break;
            case 'gold':
                image = images.badge.gold
                break;
            case 'diamond':
                image = images.badge.diamond
                break;
        }
        for (let i = 0; i < item.badge_count; i++) array.push(image)
        return (
            <div className="flex items-center">
                {array.map(y => <img src={y} className="w-6 object-contain" />)}
            </div>
        )
    }

    return (
        <Base
            title={data?.community_name}
            hideSearch
            main={
                <div class="pb-10">
                    <div className="flex flex-col items-center justify-between mb-2 shadow-md mt-2 bg-white relative">
                        <img src={data?.cover_image} className="h-52 sm:h-64 object-fill" />
                        <div className="h-52 sm:h-64 w-full absolute z-1 bg-gradient-to-b from-transparent via-transparent to-gray-600" />
                        <div className="absolute bottom-0 flex flex-row w-full">
                            <img src={data?.profile_image} className="absolute -bottom-7 left-2 sm:left-5 h-28 sm:h-32 rounded-full shadow-md object-fill z-20 bg-white" />
                            {Object.keys(data).length > 0 &&
                                <div className="flex items-center justify-between w-full">
                                    <div className="flex flex-col justify-end mb-1 ml-32 sm:ml-40">
                                        <p className="text-lg sm:text-xl font-bold text-white">{data?.community_name}</p>
                                        <p className="text-xs sm:text-sm text-white mb-1">{`${data?.followers || 0} ${data?.followers > 1 ? 'Members' : 'Member'}`}</p>
                                        {data?.badge && renderBadge(data?.badge)}
                                    </div>
                                    <button onClick={doFollow} className={`${data?.is_follow == "true" ? 'bg-gray-200' : 'bg-red-500 text-white'} font-semibold px-3 py-0.5 text-xs sm:text-sm rounded-xl mr-2 sm:mr-5 ml-1`}>
                                        {data?.is_follow == "true" ? "Unfollow" : "Follow"}
                                    </button>
                                </div>
                            }
                        </div>
                    </div>
                    <CardFeed>
                        <button
                            onClick={() => setShowAbout(!showAbout)}
                            className={`flex flex-row justify-between items-center ${showAbout ? 'mb-2' : '-mb-3'} w-full`}>
                            <p className="font-bold text-md">About</p>
                            <Icon
                                name={`${showAbout ? 'expand-less' : 'expand-more'}`}
                                font='MaterialIcons'
                                color='black'
                                size={25}
                            />
                        </button>
                        <div className={`${showAbout ? 'flex flex-col' : 'hidden'}`}>
                            {about.map(item => (
                                <div className="flex flex-row items-center mb-1">
                                    <p className="w-1/3 sm:w-1/6 text-sm font-light">{item.label}</p>
                                    <p className="w-2/3 smm:w-5/6 text-sm font-light">{item.value}</p>
                                </div>
                            ))}
                            <p className="text-sm font-light mt-1">{data?.description}</p>
                        </div>
                    </CardFeed>
                    <div className="sticky top-14 lg:top-0 rounded-md sm:px-6 bg-white flex sm:justify-center ">
                        <TabCategory
                            data={listCtg}
                            active={null}
                            id="id"
                            class="shadow-md border-0"
                        />
                    </div>
                    <CommunityPost
                        data={listPostbyId}
                        isLoading={isLoading}
                        onClickAuthor={() => null}
                        doLike={doLike}
                        onClickSeeMore={handleSeeMore}
                        hasMore={page + 1 <= pages}
                        onEndReached={loadMore}
                        page={page}
                    />
                </div>
            }

            right={
                <></>
            }
        />
    )
}

export { CommunityHome }
