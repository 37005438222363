import React, { useState, useEffect } from 'react'
import { Modal, Spinner } from '../../components'
import * as a from '../../redux/actions'
import * as c from '../../redux/const.config'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Toast from '../../utils/toast'
import { ToastContainer } from 'react-toastify'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import { langId } from '../../Dao'
import { images } from '../../assets'
import { Link } from 'react-router-dom'
import { IoMdClose } from 'react-icons/io'
import OtpInput from 'react-otp-input'

function Register(props) {
    const [showPass, setShowPass] = useState(true)
    const [data, setData] = useState({})
    const [showVerifOtp, setShowVerifOtp] = useState(false)
    const [timer, setTimer] = useState(59)
    const [inputOtp, setInputOtp] = useState('')
    const dispatch = useDispatch()
    const { loading, action, otp, message } = useSelector(state => state.user)
    const history = useHistory()
    const style = {
        label: 'text-sm mb-1 text-gray-600',
        input: 'outline-none text-sm w-full py-2 px-3 mb-3 border rounded-md',
        div: 'w-full py-2 px-3 mb-3 border rounded-md flex items-center justify-between',
        input2: 'outline-none text-sm w-full mr-2',
    }

    const IconEye = ({ show, onClick }) => {
        return (show ? <AiOutlineEyeInvisible onClick={onClick} /> : <AiOutlineEye onClick={onClick} />)
    }

    const hasWhiteSpace = (text) => {
        return /^\s/.test(text)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const { fname, lname, phone, email, password } = e.target.elements
        const body = {
            username: email.value,
            email: email.value,
            first_name: fname.value,
            last_name: lname.value,
            password: password.value,
            phone_number: phone.value
        }
        if (body.email && body.first_name && body.phone_number && body.password && !hasWhiteSpace(body.email) &&
            !hasWhiteSpace(body.first_name) && !hasWhiteSpace(body.phone_number) && !hasWhiteSpace(body.password) &&
            body.password.length >= 6 && body.password.length <= 15) {
            setData(body)
            dispatch(a.signPhoneWatcher({ phone: phone.value, type: 'register' }))
        }
    }

    useEffect(() => {
        if (action == c.SIGN_UP_FAILURE) {
            Toast.error(message, Toast.SHORT)
        }
        if (action == c.SIGN_UP_SUCCSESS) {
            Toast.info('Register successfully')
            setTimeout(() => {
                history.push('/login')
            }, 2000)
        }
        if (action == c.SIGN_PHONE_SUCCSESS) {
            dispatch(a.doSetStateAuth({ key: 'action', value: '' }))
            setShowVerifOtp(true)
            setTimer(59)
        }
        if (action == c.SIGN_PHONE_FAILURE) {
            dispatch(a.doSetStateAuth({ key: 'action', value: '' }))
            Toast.error('Gagal kirim otp')
        }
    }, [action])

    useEffect(() => {
        if (inputOtp.length == 6) {
            if (inputOtp == otp) {
                dispatch(a.signUp(data))
                setShowVerifOtp(false)
                setInputOtp('')
            }
            else Toast.error('Kode salah')
        }
    }, [inputOtp])

    useEffect(() => {
        dispatch(a.clearAction())
        return () => dispatch(a.doResetRegisterStatus())
    }, [])

    const renderModalForgot = () => {
        return (
            <Modal visible={showVerifOtp} className="flex-col px-5 py-3 bg-gray" containerStyle="w-full">
                <div className="flex items-center mb-3">
                    <IoMdClose className="text-2xl mr-2" onClick={() => setShowVerifOtp(false)} />
                    <p className="text-base font-semibold">Verifikasi Nomor</p>
                </div>
                <p className="text-sm font-light mt-1 mb-3 text-center flex items-center justify-center">Kode verifikasi telah dikirim ke nomor
                    <p className="text-red-500 font-bold ml-1">{data?.phone_number}</p>
                </p>
                <OtpInput
                    value={inputOtp}
                    onChange={setInputOtp}
                    numInputs={6}
                    separator={<span className="mr-2"></span>}
                    containerStyle="justify-center"
                    inputStyle={{
                        width: 40,
                        height: 40,
                        backgroundColor: 'rgb(243, 244, 246)',
                        outline: 'none',
                        borderBottomWidth: 1,
                    }}
                    focusStyle="border-red-500"
                />
                <p className="text-sm font-light mt-3 mb-3 text-center flex items-center justify-center">Tidak mendapatkan kode?
                    {!timer ?
                        <button
                            onClick={() => dispatch(a.signPhoneWatcher({ phone: data?.phone_number, type: 'register' }))}
                            className="text-red-500 font-bold ml-1">
                            Kirim Ulang
                        </button>
                        :
                        <Countdown val={timer} onEnd={() => setTimer(0)} />
                    }
                </p>
            </Modal>
        )

    }

    return (
        <div>
            <div className="w-full mx-auto bg-white flex sticky top-0 z-50 ">
                <ToastContainer
                    position="top-center"
                    autoClose={3000}
                    hideProgressBar
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </div>
            {renderModalForgot()}
            <div className="flex items-center">
                <div className="w-7/12 h-screen relative hidden sm:flex">
                    <Link to={{ pathname: '/' }} className="absolute top-10 left-20">
                        <img src={images.logo.default} alt="" className="h-9 object-contain" />
                    </Link>
                    <p class="absolute top-28 left-20 text-3xl text-red-500 font-bold w-2/3">
                        {langId.landing.heroTitle}
                    </p>
                    <img src={images.cover} alt="" className="w-full h-full bg-cover" />
                </div>
                <div className="w-full h-screen sm:w-1/2 flex flex-col items-center justify-center relative">
                    <p className="text-center text-3xl font-semibold text-gray-900">{langId.auth.reg}</p>
                    <form className="mt-8 w-4/5 lg:w-3/5" onSubmit={handleSubmit}>
                        <div className="flex items-center w-full justify-between">
                            <div className="flex flex-col w-1/2">
                                <p className={style.label}>Nama Pertama</p>
                                <input
                                    className={style.input}
                                    placeholder="Masukkan nama"
                                    name="fname"
                                    required
                                />
                            </div>
                            <div className="flex flex-col w-1/2 ml-2">
                                <p className={style.label}>Nama Terakhir</p>
                                <input
                                    className={style.input}
                                    placeholder="Masukkan nama"
                                    name="lname"
                                />
                            </div>
                        </div>
                        <p className={style.label}>Nomor Telepon</p>
                        <input
                            className={style.input}
                            placeholder="Masukkan nomor telepon"
                            name="phone"
                            required
                        />
                        <p className={style.label}>Email</p>
                        <input
                            className={style.input}
                            placeholder="Masukkan email"
                            name="email"
                            type="email"
                            required
                        />
                        <p className={style.label}>Password</p>
                        <div className={style.div}>
                            <input
                                className={style.input2}
                                placeholder="Masukkan password"
                                type={showPass ? 'text' : 'password'}
                                name="password"
                                required
                            />
                            <IconEye show={showPass} onClick={() => setShowPass(!showPass)} />
                        </div>
                        <button
                            className={`text-white bg-red-500 w-full rounded-md py-2 font-semibold text-sm mt-6 mb-3`}>
                            {loading ? <Spinner size={5} /> : 'Daftar'}
                        </button>
                        <p className="mt-2 text-center text-sm text-gray-600">
                            Sudah punya akun?{' '}
                            <a href="/login" className="font-medium text-red-600 hover:text-red-500">
                                Masuk
                            </a>
                        </p>
                    </form>
                    <div className="absolute bottom-10 flex items-center justify-center w-full">
                        <p className="text-center text-sm font-semibold text-gray-400">SocialConnext Copyright 2021</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Countdown = ({ val, onEnd }) => {
    const [timer, setTimer] = useState(0)

    useEffect(() => {
        const interval = setInterval(() => {
            if (timer > 0) setTimer(timer - 1)
            else {
                onEnd()
                clearInterval(interval)
            }
        }, 1000)

        return () => clearInterval(interval)
    }, [timer])

    useEffect(() => {
        val === 59 && setTimer(59)
    }, [val])

    return (
        <p className="text-sm font-light ml-1"> {`Kirim ulang dalam ${timer} detik`}</p>
    )
}

export { Register }
