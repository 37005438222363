import * as t from '../../const.config'

const initialState = {
    data:[],
    isLoading: false,
    isSuccess: false,
    isFailed: false,
}

const reducerBanner = (state=initialState, action) => {
    switch(action.type){
        case t.BANNER_LOADING:
            return{
                ...state,
                isLoading: true,
                isSuccess: false,
                isFailed: false
            }
        case t.BANNER_SUCCESS:
            return{
                ...state,
                isLoading: false,
                isSuccess: true,
                isFailed: false,
                data:action.data
            }
        case t.BANNER_FAILED:
            return{
                ...state,
                isLoading: false,
                isSuccess: false,
                isFailed: true,
            }
        default:
            return state
    }
}

export {reducerBanner}