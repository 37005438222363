import * as URL from '../routers/const'
import { link } from './LinkEksternal'

const menuAccount = [
    { name: "Home", type: "AntDesign", icon: 'home', path: URL.Community },
    { name: "Message", type: "AntDesign", icon: 'mail', path: URL.Chat },
    { name: "Ask Mentor", type: "AntDesign", icon: 'questioncircleo', path: URL.AskMentor },
    { name: "Articles", type: "AntDesign", icon: 'profile', path: URL.Articles },
    { name: "Podcasts", type: "AntDesign", icon: 'sound', path: URL.Podcasts },
    { name: "Events", type: "AntDesign", icon: 'calendar', path: URL.ListEvent },
    // { name: "404", type: "AntDesign", icon: '', path: URL.notFound },
]

const menuAccountLanding = [
    // { name: "About", type: "AntDesign", icon: '', path: URL.About },
    // { name: "FAQ", type: "AntDesign", icon: '', path: URL.FAQ },
    { name: "Community", type: "AntDesign", icon: '', path: link.community, external: true },
]

const menuExit = { name: "Keluar", type: "Feather", icon: 'log-out', path: '' }
const menuAuth = [
    { name: "Masuk", type: "Feather", style: '', path: URL.Login },
    { name: "Daftar", type: "Feather", style: 'bg-red-500 text-white', path: URL.Register },
]

const bottomBar = [
    { path: URL.Community, label: 'Home', icon: 'home' },
    { path: URL.Chat, label: 'Message', icon: 'mail' },
    { path: URL.AskMentor, label: 'Ask Mentor', icon: 'questioncircleo' },
]
const bottomBarSecondary = [
    // { path: URL.Landing, label: 'About us', icon: 'layout' },
    { path: URL.Articles, label: 'Articles', icon: 'profile' },
    { path: URL.Podcasts, label: 'Podcasts', icon: 'sound' },
    { path: URL.ListEvent, label: "Events", icon: 'calendar' },
    { path: URL.Profile, label: 'Profile', icon: 'user' },
]

export {
    menuAccount, menuAccountLanding,
    menuExit, menuAuth,
    bottomBar, bottomBarSecondary
}