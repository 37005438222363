import React, { useState, useEffect } from 'react'
import { Spinner, Placeholder, FlatList, Base } from '../../components'
import Icon from 'react-web-vector-icons'
import * as a from '../../redux/actions'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { PhotoDetail } from '../../routers/const'

function Photo(props) {
    const [page, setPage] = useState(1)
    const [keyword, setKeyword] = useState("")
    const dispatch = useDispatch()
    const history = useHistory()
    const params = useParams()
    const { listPhoto, isLoading, pages } = useSelector(state => state.photoCommunity)
    const title = props.location.state?.title || ""

    const fetchData = (value, reset, resetPage) => {
        dispatch(a.getPhotoCommunity({
            id: params?.id,
            idPhoto: null,
            type: null,
            page: value ? value : 1,
            reset
        }))
        resetPage && setPage(1)
    }

    const handleClickPhoto = (id) => {
        const pathname = PhotoDetail.replace(':id', params?.id).replace(':idContent', id)
        history.push({ pathname, state: { authorId: params?.id } })
    }

    const loadMore = () => {
        setTimeout(() => {
            if (listPhoto.length % 18 == 0 && !isLoading && page + 1 <= pages) {
                setPage(page + 1)
                fetchData(page + 1, false)
            }
        }, 500)
    }

    useEffect(() => { fetchData() }, [])

    const renderItem = ({ item }) => {
        return (
            <button onClick={() => handleClickPhoto(item.ID)} className="">
                <img src={item?.image} className="h-full w-full object-cover bg-gray-100" />
            </button>
        )
    }

    return (
        <Base
            title={`Photos ${title}`}
            rightIcon={() => null}
            main={
                <FlatList
                    data={listPhoto}
                    renderItem={renderItem}
                    loading={isLoading}
                    containerStyle="flex flex-row flex-wrap justify-center grid grid-cols-2 gap-1 mb-20"
                    onEndReached={loadMore}
                    hasMore={page + 1 <= pages}
                    page={page}
                />
            }
            right={<></>}
        />
    )
}

export { Photo }
