import * as a from '../const.config'

const initialState = {
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    listUser: [],
    count: {
        personal: 0,
        group: 0,
        public: 0,
        all: 0
    },
    roomDetail: {},
    createData: {},
    isSuccessCreate: false,
    isLoadingCreate: false,
    pages: 0,
    isSuccessEditDesc: false,
    isLoadingRoom: false,
    isSuccessAdd: false,
    isSuccessUpAvatar: false,
    isSuccessMakeGroup: false,
    isSuccessRemove: false,
    isSuccessUpGroupName: false,
    isJoinedForum: false,
    isLoadingOption: false
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case a.GET_USER_LIST_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
                isFailed: false,
            }
        case a.GET_USER_LIST_SUCCESS:
            const stateData = state.listUser
            const data = action.isLoadMore ? stateData.concat(action.data) : action.data
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                listUser: data,
                pages: action.pages
            }
        case a.GET_USER_LIST_FAILED:
            return {
                ...state,
                isLoading: false,
                isFailed: true,
            }
        case a.DO_RESET_USER_LIST:
            return {
                ...state,
                listUser: [],
                createData: {},
                isSuccessCreate: false,
                isLoadingCreate: false,
            }
        case a.DO_SET_COUNT_CHAT:
            return {
                ...state,
                count: action.payload
            }
        case a.GET_ROOM_DETAIL_START:
            return {
                ...state,
                isLoadingRoom: true,
                isSuccess: false,
                isFailed: false,
                isSuccessEditDesc: false,
                isSuccessAdd: false,
                isSuccessUpAvatar: false,
                isSuccessMakeGroup: false,
                isSuccessRemove: false,
                isSuccessUpGroupName: false
            }
        case a.GET_ROOM_DETAIL_SUCCESS:
            return {
                ...state,
                isLoadingRoom: false,
                isSuccess: true,
                roomDetail: action.data
            }
        case a.GET_ROOM_DETAIL_FAILED:
            return {
                ...state,
                isLoadingRoom: false,
                isFailed: true,
            }
        case a.DO_RESET_ROOM_DETAIL:
            return {
                ...state,
                roomDetail: {}
            }
        case a.CREATE_GROUP_START:
            return {
                ...state,
                isLoadingCreate: true,
                isSuccessCreate: false,
                isFailed: false,
                createData: {}
            }
        case a.CREATE_GROUP_SUCCESS:
            return {
                ...state,
                isLoadingCreate: false,
                isSuccessCreate: true,
                createData: action.data
            }
        case a.CREATE_GROUP_FAILED:
            return {
                ...state,
                isLoadingCreate: false,
                isFailed: true,
            }
        case a.EDIT_DESC_START:
            return {
                ...state,
                isSuccessEditDesc: false,
                isFailed: false,
            }
        case a.EDIT_DESC_SUCCESS:
            return {
                ...state,
                isSuccessEditDesc: true,
            }
        case a.EDIT_DESC_FAILED:
            return {
                ...state,
                isFailed: true,
            }
        case a.ADD_PARTICIPANTS_START:
            return {
                ...state,
                isLoading: true,
                isSuccessAdd: false,
                isFailed: false,
            }
        case a.ADD_PARTICIPANTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccessAdd: true,
            }
        case a.ADD_PARTICIPANTS_FAILED:
            return {
                ...state,
                isLoading: false,
                isFailed: true,
            }
        case a.UPDATE_AVATAR_GROUP_START:
            return {
                ...state,
                isSuccessUpAvatar: false,
                isFailed: false,
            }
        case a.UPDATE_AVATAR_GROUP_SUCCESS:
            return {
                ...state,
                isSuccessUpAvatar: true,
            }
        case a.UPDATE_AVATAR_GROUP_FAILED:
            return {
                ...state,
                isFailed: true,
            }
        case a.DO_MAKE_ADMIN_GROUP_START:
            return {
                ...state,
                isLoadingOption: true,
                isSuccessMakeGroup: false,
                isFailed: false,
            }
        case a.DO_MAKE_ADMIN_GROUP_SUCCESS:
            return {
                ...state,
                isLoadingOption: false,
                isSuccessMakeGroup: true,
            }
        case a.DO_MAKE_ADMIN_GROUP_FAILED:
            return {
                ...state,
                isLoadingOption: false,
                isFailed: true,
            }
        case a.REMOVE_PARTICIPANTS_START:
            return {
                ...state,
                isLoadingOption: true,
                isSuccessRemove: false,
                isFailed: false,
            }
        case a.REMOVE_PARTICIPANTS_SUCCESS:
            return {
                ...state,
                isLoadingOption: false,
                isSuccessRemove: true,
            }
        case a.REMOVE_PARTICIPANTS_FAILED:
            return {
                ...state,
                isLoadingOption: false,
                isFailed: true,
            }
        case a.GET_LIST_CHAT_GROUP_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
                isFailed: false,
            }
        case a.GET_LIST_CHAT_GROUP_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
            }
        case a.GET_LIST_CHAT_GROUP_FAILED:
            return {
                ...state,
                isLoading: false,
                isFailed: true,
            }
        case a.UPDATE_GROUP_NAME_START:
            return {
                ...state,
                isLoading: true,
                isSuccessUpGroupName: false,
                isFailed: false,
            }
        case a.UPDATE_GROUP_NAME_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccessUpGroupName: true,
            }
        case a.UPDATE_GROUP_NAME_FAILED:
            return {
                ...state,
                isLoading: false,
                isFailed: true,
            }
        case a.SET_CHAT_STATE:
            return {
                ...state,
                [action.payload.key]: action.payload.value,
            }
        default:
            return state;
    }
}

export default reducer