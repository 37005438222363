import { takeEvery, call, put } from 'redux-saga/effects'
import * as t from '../../const.config'
import Axios from '../../../utils/server/baseURL'
import qs from 'qs'

export const Articles = [
    takeEvery(t.HOME_ARTICLES_LOADING, workerGetArticle),
    takeEvery(t.HOME_ARTICLE_DETAIL_LOADING, workerGetDetailArticle)
]


const articles = async token => {
    try {
        const data = await Axios({
            url: `/api/custom_service/?service=getlastposts`,
            data: qs.stringify({
                ACCESS_TOKEN: token
            })
        })
        return data
    } catch (error) {
        return error
    }
}

function* workerGetArticle(action) {
    try {
        const response = yield call(articles, action.payload)
        if (response.data.respond === 1) {
            yield put({
                type: t.HOME_ARTICLES_SUCCSESS,
                articles: response.data.result
            })
        } else {
            throw response
        }
    } catch (error) {
        yield put({
            type: t.HOME_ARTICLES_FAILURE,
            message: error.data.message
        })
    }
}
const article = async params => {
    try {
        const data = await Axios({
            url: `/api/getpost/?post_id=${params.id}`,
            data: qs.stringify({
                ACCESS_TOKEN: params.token
            })
        })
        return data
    } catch (error) {
        return error
    }
}

function* workerGetDetailArticle(action) {
    try {
        const response = yield call(article, action.payload)
        if (response.data.respond === 1) {
            yield put({
                type: t.HOME_ARTICLE_DETAIL_SUCCSESS,
                article: response.data.result[0]
            })
        } else {
            throw response
        }
    } catch (error) {
        yield put({
            type: t.HOME_ARTICLE_DETAIL_FAILURE,
            message: error.data.message
        })
    }
}