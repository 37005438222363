import Icon from 'react-web-vector-icons'

function ActionPost(props) {
    let share = props.onClickShare ? [{
        id: 2,
        name: "Share",
        icon: 'export',
        font: 'AntDesign',
        onPress: props.onClickShare || null
    }] : []
    let postAction = [
        {
            id: 0,
            name: "Like",
            icon: props.isLiked ? 'heart' : 'hearto',
            font: 'AntDesign',
            onPress: props.onClickLike,
            count: props.like != 0 ? props.like : null,
            liked: props.isLiked
        },
        {
            id: 1,
            name: "Comment",
            icon: 'message1',
            font: 'AntDesign',
            onPress: props.onClickComment,
            count: props.comment != 0 ? props.comment : null

        },
        ...share
    ]

    return (
        <div className={`flex flex-row items-center justify-around border-t py-1 ${props.style}`}>
            {postAction.map(item => (
                <button onClick={item.onPress} className="flex flex-row w-1/3 justify-center items-end">
                    <Icon
                        name={item.icon}
                        font={item.font}
                        color={item.liked ? 'red' : 'black'}
                        size={props.size || 23}
                    />
                    <p className="text-xs sm:text-sm mb-0.5 ml-0.5">{item.count}</p>
                </button>
            ))}
        </div>
    )
}

const ActionArticle = (props) => {
    return (
        <div className="w-auto p-5 flex-row flex space-x-4 justify-between border-t mt-8">
            <div>
                <Icon
                    name="hearto"
                    font='AntDesign'
                    color='black'
                    size={25}
                />
                <span> {props.like}</span>
            </div>
            <div>
                <Icon
                    name="message1"
                    font='AntDesign'
                    color='black'
                    size={25}
                />
                <span> {props.comment}</span>
            </div>
            <div>
                <Icon
                    name="export"
                    font='AntDesign'
                    color='black'
                    size={25}
                />
            </div>
        </div>
    )
}

export { ActionPost, ActionArticle }