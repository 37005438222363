import { takeEvery, put } from 'redux-saga/effects'
import * as t from '../const.config'
import * as a from '../../config/api'

export const Profile = [
    takeEvery(t.UPDATE_PROFILE, workerUpdateProfile),
    takeEvery(t.GET_PROFILE, workerGetProfile),
    takeEvery(t.UPDATE_PROFILE_PHOTO, workerUpdateProfilePhoto),
]

const constructProfile = (data) => {
    return {
        id: data?.ID,
        avatar: data?.avatar,
        email: data?.user_email,
        name: `${data?.first_name} ${data?.last_name}`,
        description: data?.authormeta?.description,
        hobby: data?.authormeta?.user_hobi,
        phone: data?.authormeta?.phone_number,
        joined: data?.user_registered
    }
}

function* workerGetProfile(action) {
    const { id, type } = action.payload
    yield put({ type: t.GET_PROFILE_LOADING })
    try {
        const api = `${a.getMyProfileApi}?author_id=${id}`
        const data = yield a.getDataFromApi({}, api)
        const newData = data.length ? type !== 'chat' ? data[0] : constructProfile(data[0]) : {}
        const state = type !== 'chat' ? 'profile' : 'profileChat'

        yield put({ type: t.GET_PROFILE_SUCCSESS, data: newData, state })
    } catch (error) {
        yield put({ type: t.GET_PROFILE_FAILED })
    }
}

function* workerUpdateProfilePhoto(action) {
    const body = action.payload
    yield put({ type: t.UPDATE_PROFILE_PHOTO_LOADING })
    try {
        const data = yield a.getDataFromApi(body, a.updateProfilePhotoApi, '', 'form')

        yield put({ type: t.UPDATE_PROFILE_PHOTO_SUCCSESS })
    } catch (error) {
        yield put({ type: t.UPDATE_PROFILE_PHOTO_FAILED })
    }
}

function* workerUpdateProfile(action) {
    const p = action.payload
    yield put({ type: t.UPDATE_PROFILE_LOADING })
    try {
        let phone = null
        if (p.phone) {
            const country_code = p.phone.substring(0, 2)
            if (country_code === '62') phone = `0${p.phone.substring(2, 15)}`.replace('-', '')
            else phone = p.phone.replace('-', '')
        }
        const api = `${a.updateProfileApi}?first_name=${p.firstName}&last_name=${p.lastName}&email=${p.email}&description=${p.description}&custom_meta={"phone_number":"${phone}","user_hobi":"${p.hobby}"}`
        const data = yield a.getDataFromApi({}, api)

        yield put({ type: t.UPDATE_PROFILE_SUCCSESS })
    } catch (error) {
        yield put({ type: t.UPDATE_PROFILE_FAILED })
    }
}