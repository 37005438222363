import React, { useState, useEffect, useCallback } from 'react'
import { Base, FlatList, SimpleModal, Alert } from '../../components'
import qiscus from '../../utils/qiscusSDK'
import { appId, channel, channel2 } from '../../config/qiscus'
import moment from 'moment'
import ChatRoom from './ChatRoom'
import RoomDetail from './RoomDetail'
import { useDispatch, useSelector } from "react-redux"
import * as a from '../../redux/actions'
import { HiUserGroup } from 'react-icons/hi'
import { RiMailAddFill } from 'react-icons/ri'
import { MdGroupAdd } from 'react-icons/md'
import AddChat from './AddChat'
import { IoIosArrowDown } from 'react-icons/io'
import Toast from '../../utils/toast'

let tempTab = 0
let tempChat = {}

function Chat() {
    const [activeTab, setActiveTab] = useState(0)
    const [loading, setLoading] = useState(false)
    const [lastId, setLastId] = useState(null)
    const [loadingRoom, setLoadingRoom] = useState(false)
    const [data, setData] = useState([])
    const [dataRoom, setDataRoom] = useState({})
    const [room, setRoom] = useState([])
    const [showRoom, setShowRoom] = useState(false)
    const [showDetail, setShowDetail] = useState(false)
    const [mobile, setMobile] = useState(false)
    const [keyword, setKeyword] = useState("")
    const [showAddChat, setShowAddChat] = useState(false)
    const [showOption, setShowOption] = useState(false)
    const [alertDelete, setAlertDelete] = useState(false)
    const { roomDetail, isJoinedForum, listUser, isLoading, createData, isSuccessCreate,
        isLoadingCreate, isSuccessAdd, isSuccessMakeGroup, isSuccessRemove, isLoadingOption, count, isSuccessEditDesc,
        isSuccessUpAvatar, isSuccessUpGroupName, isLoadingRoom
    } = useSelector(state => state.chat)
    const { profileChat } = useSelector(state => state.profile)
    const { user } = useSelector(state => state.user)
    const [selectedId, setSelectedId] = useState({})
    const [listMember, setListMember] = useState([])
    const tab = [
        { id: 0, label: 'Pribadi', badge: count.personal },
        { id: 1, label: 'Grup', badge: count.group },
        { id: 2, label: 'Forum' },
    ]
    const typeList = activeTab == 0 ? 'single' : activeTab == 1 ? 'group' : 'forum'
    const dispatch = useDispatch()
    const IconAdd = typeList == 'single' ? RiMailAddFill : MdGroupAdd

    const getChatList = () => {
        const type = tempTab == 0 ? 'single' : tempTab == 1 ? 'group' : 'forum'
        const conf = {
            page: 1,
            limit: 100,
            show_participants: true,
            show_empty: type !== 'single'
        }
        qiscus.loadRoomList(conf)
            .then(item => {
                const newData = item.filter(x => x.room_type == type && x.isChannel == false)
                const temp = item.filter(x => x.room_type !== type)
                let tempData = type == 'forum' ? item.filter(x => x.room_type == 'group' && x.isChannel) : newData
                let counterP = 0
                let counterG = 0
                if (type !== 'forum') {
                    tempData.map(x => {
                        if (type == 'single') counterP += x.count_notif
                        else counterG += x.count_notif
                    })
                    temp.map(x => {
                        if (type == 'single') counterG += x.count_notif
                        else counterP += x.count_notif
                    })
                    const newCount = { ...count, personal: counterP, group: counterG }
                    dispatch(a.doSetCountChat(newCount))
                }
                setData(tempData)
                setLoading(false)
            })
            .catch(error => {
                setLoading(false)
            })
    }

    const joinChannel = () => {
        if (!isJoinedForum) {
            channel2.map(x => qiscus.getOrCreateRoomByChannel(x.room_channel_id, x.room_name, x.room_avatar_url))
            channel.map(x => qiscus.removeParticipantsFromGroup(x.room_id, [qiscus.userData.email]))
            dispatch(a.setChatState({ key: 'isJoinedForum', value: true }))
        }
    }

    const fetchRoom = () => {
        const fetch = () => {
            window.scrollTo(0, 0)
            setLoadingRoom(true)
            let options = {
                last_comment_id: null,
                after: false,
                limit: 50
            }
            qiscus.readComment(dataRoom?.id, dataRoom?.lastCommentId)
            qiscus.loadComments(dataRoom?.id, options).then(result => {
                console.log("temp", result)
                const checkDate = result[0]?.timestamp > result[result.length - 1]?.timestamp
                let newData = checkDate ? result : result.reverse()
                if (tempChat?.id && tempChat?.id !== newData[0].id) newData.splice(0, 1, tempChat)
                setRoom(newData)
                setLastId(newData.length && newData[newData.length - 1].id)
                setLoadingRoom(false)
            }).catch(() => setLoadingRoom(false))
        }
        if (!tempChat.room_id) fetch()
        if (tempChat.room_id === dataRoom.id) fetch()
    }

    const loadMore = () => {
        const limit = 20
        if (room.length % limit == 0 && !loadingRoom && room.length <= 90) {
            fetchRoom()
        }
    }

    const handleExitGroup = () => {
        qiscus.removeParticipantsFromGroup(dataRoom?.id, [qiscus.userData.email])
            .then(() => {
                setDataRoom({})
                getChatList()
                setShowDetail(false)
            })
    }

    const handleOpenChat = (id) => {
        qiscus.chatTarget(id).then(
            (result) => {
                const userId = result?.participants.filter(x => x.email !== qiscus.userData.email)[0]?.email
                const room = {
                    id: result.id,
                    name: result.name,
                    avatar: result.avatar,
                    lastCommentId: result?.last_comment_id,
                    userId,
                    avatar: result?.avatar,
                    myId: user.ID
                }
                setDataRoom(room)
                setShowRoom(true)
                setShowAddChat(false)
                setKeyword("")
            },
            (err) => {
                alert('Cant open chat!')
            }
        );
    }

    const handleCreateGroup = (data) => {
        dispatch(a.createGroup(data))
    }

    const handleClickOption = (type, id) => {
        type == 'admin' ? dispatch(a.doMakeAdminGroup({
            room_id: dataRoom?.id,
            user_id: id
        })) : dispatch(a.removeParticipants({
            room_id: dataRoom?.id,
            user_id: [id]
        }))
    }

    const getCountChat = () => {
        qiscus && qiscus.isLogin && qiscus.getTotalUnreadCount()
            .then(function (unreadCount) {
                dispatch(a.doSetCountChat({ ...count, all: unreadCount }))
            })
    }

    useEffect(() => {
        if (typeof window !== 'undefined') {
            function handleResize() {
                setMobile(window.innerWidth <= 768)
            }
            window.addEventListener("resize", handleResize)
            handleResize()
            return () => window.removeEventListener("resize", handleResize)
        }
    }, [])

    useEffect(() => {
        if (qiscus && qiscus.isLogin && Object.keys(qiscus).length) {
            qiscus.updateProfile({ extras: { online: true } })
        }
        return () => {
            qiscus.updateProfile({ extras: { online: false } })
            tempTab = 0
        }
    }, [])

    useEffect(() => {
        tempChat = {}
        setData([])
        setDataRoom({})
        setShowDetail(false)
        setLoading(true)
        setLastId(null)
        getChatList()
        getCountChat()
    }, [activeTab])

    useEffect(() => {
        if (dataRoom?.id) {
            tempChat = {}
            setRoom([])
            setShowDetail(false)
            setLastId(null)
            fetchRoom()
            getCountChat()
            typeList == 'single' ?
                dispatch(a.getProfile({ id: dataRoom?.userId, type: 'chat' })) :
                dispatch(a.getRoomDetail({ id: dataRoom?.id, personal: typeList == 'single' }))
        }
    }, [dataRoom])

    useEffect(() => { if (dataRoom?.id) fetchRoom() }, [data])

    useEffect(() => {
        if (qiscus && qiscus.isLogin && dataRoom?.id) {
            const length = roomDetail?.participants_name ? roomDetail?.participants_name.length : 0
            if (Object.keys(roomDetail).length && dataRoom?.avatar !== roomDetail?.room_avatar_url) {
                qiscus.updateRoom({ id: dataRoom?.id, avatar_url: roomDetail?.room_avatar_url }).then(() => getChatList())
            }
            if (Object.keys(roomDetail).length && dataRoom?.member !== length) {
                qiscus.updateRoom({ id: dataRoom?.id, options: { participants: length } })
            }
        }
        if (typeList !== 'single' && typeList !== 'forum') {
            if (Object.keys(roomDetail).length) {
                const temp = roomDetail?.participants_name ? roomDetail?.participants_name.filter(x => x.user_id !== user.ID) : []
                setListMember(temp)
            }
        }
    }, [roomDetail])

    useEffect(() => {
        dispatch(a.getUserList({ keyword, page: 1 }))
    }, [keyword, setShowAddChat])

    useEffect(() => {
        if (isSuccessCreate && Object.keys(createData).length) {
            const options = createData?.room_options && JSON.parse(createData?.room_options)
            setShowAddChat(false)
            getChatList()
            setKeyword("")
            setDataRoom({
                id: createData.room_id,
                lastCommentId: '', name: createData.room_name,
                myId: user.ID,
                avatar: createData.room_avatar_url,
                member: options && options?.participants ? options?.participants : createData?.room_participants.length,
            })
            setShowRoom(true)
            dispatch(a.doResetUserList())
        }
    }, [isSuccessCreate])

    useEffect(() => {
        if (isSuccessAdd || isSuccessMakeGroup || isSuccessRemove) {
            if (isSuccessAdd) {
                setShowAddChat(false)
                setKeyword("")
            }
            dispatch(a.getRoomDetail({ id: dataRoom?.id, personal: typeList == 'single' }))
        }
        if (isSuccessEditDesc || isSuccessUpAvatar || isSuccessUpGroupName) getChatList()
    }, [isSuccessAdd, isSuccessMakeGroup || isSuccessRemove, isSuccessEditDesc, isSuccessUpAvatar, isSuccessUpGroupName])

    useEffect(() => {
        setLoading(true)
        qiscus.init({
            AppId: appId,
            options: {
                loginSuccessCallback: (data) => {
                    getChatList()
                    joinChannel()
                    getCountChat()
                },
                newMessagesCallback: (messages) => {
                    tempChat = messages[0]
                    getChatList()
                    fetchRoom()
                    getCountChat()
                },
                commentReadCallback: (messages) => {
                    getChatList()
                    fetchRoom()
                    getCountChat()
                },
                commentSentCallback: function (data) {
                    getChatList()
                    fetchRoom()
                },
                commentDeletedCallback: (data) => {
                    getChatList()
                    fetchRoom()
                },
                commentDeliveredCallback: (data) => {
                    getChatList()
                    fetchRoom()
                }
            }
        })

        const name = `${user.first_name} ${user.last_name}`
        qiscus.setUser(user.ID, user.user_login, name, user.avatar)
        dispatch(a.doCheckToken())
    }, [])

    const renderOption = () => {
        const option = [
            {
                id: 0,
                text: selectedId?.name,
                title: true
            },
            {
                id: 1,
                text: 'Delete chat',
                onPress: () => setAlertDelete(true)
            }
        ]
        return (
            <>
                <SimpleModal
                    visible={showOption}
                    onClose={() => setShowOption(false)}
                    data={option}
                    style="sm:w-1/2"
                />
                <Alert
                    title="Delete chat"
                    subTitle="Are you sure you want to delete this chat?"
                    visible={alertDelete}
                    onClick={() => {
                        qiscus.clearRoomMessages(selectedId?.id).then(() => {
                            getChatList()
                            setSelectedId({})
                            setAlertDelete(false)
                            Toast.info('Chat deleted')
                        }).catch(() => setAlertDelete(false))
                    }}
                    onClose={() => setAlertDelete(false)}
                />
            </>
        )
    }

    const renderTab = useCallback(({ item }) => {
        const active = activeTab == item.id
        return (
            <button onClick={() => {
                setActiveTab(item.id)
                tempTab = item.id
            }} key={item.id} className={`w-1/3 ${active && 'border-b-2 border-red-500'} flex justify-center`}>
                <div className="flex items-center my-2.5">
                    <p className={`text-sm ${active && 'text-red-500'}`}>{item.label}</p>
                    {item.badge ? <div className={`bg-red-500 rounded-full text-white text-xs flex items-center justify-center ml-1 h-1.5 w-1.5 -mt-1.5`}></div> : null}
                </div>
            </button>
        )
    })

    const renderItem = useCallback(({ item, index }) => {
        const time = moment(item?.last_comment_message_created_at).format("D/MM HH:mm")
        const name = `${user.first_name} ${user.last_name}`
        const username = item.last_comment?.username?.split(" ")[0]
        const lastSender = typeList == 'single' ? '' : item.last_comment.username == name ? 'You: ' : `${username}:`
        const lastMessage = item.last_comment_message ? item.last_comment_message : item?.last_comment_id ? '' : "gc"
        const userId = item?.participants.filter(x => x.email !== qiscus.userData.email)[0]?.email
        const isOnline = item?.participants.filter(x => x.email !== qiscus.userData.email)[0]?.extras?.online
        const options = item?.options && JSON.parse(item?.options)
        const room = {
            id: item.id,
            name: item.name,
            avatar: item.avatar,
            lastCommentId: item?.last_comment_id,
            userId,
            member: options && options?.participants ? options?.participants : item?.participants.length,
            myId: user.ID
        }
        return (
            <button
                id="parent"
                onClick={(e) => {
                    if (e.target.id !== 'child') {
                        setDataRoom(room)
                        setShowRoom(true)
                    }
                }}
                key={item.id}
                className={`flex items-center w-full py-2 show ${!mobile && dataRoom?.id == item.id && 'bg-red-100'} ${index == data.length - 1 && 'mb-14'} sm:mb-0`}>
                <div className="w-16 sm:w-20 lg:w-16 mr-1 sm:mr-2 ml-3 sm:ml-5 relative">
                    {item?.avatar ?
                        <img src={item?.avatar} className="w-12 h-12 object-cover rounded-full bg-gray-100" /> :
                        <div className="w-12 h-12 object-cover rounded-full bg-gray-100 flex items-center justify-center">
                            <HiUserGroup className="text-gray-500 text-2xl" />
                        </div>
                    }
                    {isOnline && typeList == 'single' &&
                        <div className={`bg-green-500 rounded-full absolute bottom-0 right-0 h-3 w-3 border`}></div>
                    }
                </div>
                <div className={`flex flex-col ${typeList !== 'forum' ? 'justify-between' : 'justify-center'} w-full mr-3 sm:mr-5 h-10`}>
                    <div className="flex items-center justify-between w-full">
                        <p className={`text-sm sm:text-md font-semibold line-clamp-1 text-left w-4/6`}>{item.name}</p>
                        {typeList !== 'forum' && <p className={`text-xs font-light text-gray-500 text-right w-2/6 line-clamp-1`}>{time}</p>}
                    </div>
                    {typeList !== 'forum' &&
                        <div className="flex items-center justify-between w-full">
                            <p className="text-xs sm:text-sm text-gray-400 line-clamp-1 text-left break-all">
                                {`${lastMessage !== 'gc' ? lastSender : 'Group created'} ${lastMessage !== 'gc' ? lastMessage : ''}`}
                            </p>
                            <div className="flex items-center">
                                {item?.count_notif !== 0 && <div className={`bg-red-500 rounded-full h-4 ${item.count_notif.toString().length < 3 ? 'w-4' : 'w-5'}`}>
                                    <p className="text-xs text-white">{item?.count_notif}</p>
                                </div>}
                                {typeList === 'single' &&
                                    <div className={`w-7 hide justify-center items-center rounded-md -ml-0.5 -mr-2`}>
                                        <IoIosArrowDown
                                            id="child"
                                            className={`text-lg text-gray-400 w-full`}
                                            onClick={() => {
                                                setShowOption(true)
                                                setSelectedId({ name: item.name, id: [item.unique_id] })
                                            }}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    }
                </div>
            </button>
        )
    })

    return (
        <Base
            primary
            hideSearch
            hideBottom={showAddChat || showDetail || showRoom}
            main={
                <div className="h-screen-93 sm:h-screen">
                    <div className="bg-white h-full rounded-md flex items-start w-full">
                        <div className={`border-r border-l h-full flex flex-col w-full md:w-2/5 ${dataRoom.id && showRoom && mobile && 'hidden'} relative`}>
                            <div className="flex items-center justify-between h-14 w-full border-b">
                                <div className="font-bold text-gray-800 text-lg pl-2 sm:pl-5 flex items-center">Chat</div>
                                <IconAdd className="text-red-500 text-xl mr-2 sm:mr-5" onClick={() => setShowAddChat(true)} />
                            </div>
                            <FlatList
                                data={tab}
                                renderItem={renderTab}
                                containerStyle="flex items-center px-2 sm:px-5 border-b"
                            />
                            <FlatList
                                data={data}
                                renderItem={renderItem}
                                style="overflow-y-auto h-screen-90"
                                loading={loading}
                                ListFooterComponent={() => (
                                    <div className='flex justify-center w-full mt-5'>
                                        <div className={`h-2 w-2 bg-current rounded-full bg-red-500 mr-1 animate-bounce`} />
                                        <div className={`h-2 w-2 bg-current rounded-full bg-red-500 mr-1 animate-bounce200`} />
                                        <div className={`h-2 w-2 bg-current rounded-full bg-red-500 animate-bounce400`} />
                                    </div>
                                )}
                            />
                            {renderOption()}
                        </div>
                        <ChatRoom
                            visible={dataRoom.id && !showDetail}
                            data={{
                                room, showRoom, mobile, dataRoom, typeList, showDetail, listMember,
                                onClickBack: () => {
                                    setShowRoom(false)
                                    setShowDetail(false)
                                },
                                onClickHeader: () => typeList !== 'forum' && setShowDetail(true),
                                loadMore, loading: loadingRoom, personal: typeList == 'single',
                            }}
                        />
                        <RoomDetail
                            visible={dataRoom.id && showDetail}
                            data={{
                                profileChat, showDetail, mobile, dataRoom, typeList,
                                onClickBack: () => setShowDetail(false), personal: typeList == 'single',
                                onExitGroup: handleExitGroup,
                                onAddMember: () => setShowAddChat(true),
                                onClickOption: handleClickOption,
                                success: isSuccessMakeGroup || isSuccessRemove,
                                loading: isLoadingOption || isLoadingRoom
                            }}
                        />
                        <AddChat
                            visible={showAddChat}
                            onClose={() => {
                                setShowAddChat(false)
                                setKeyword("")
                            }}
                            type={typeList}
                            onSearch={setKeyword}
                            loading={isLoading || isLoadingCreate}
                            data={listUser}
                            user={user}
                            onOpenChat={handleOpenChat}
                            onCreateGroup={handleCreateGroup}
                            detail={showDetail}
                            onAddMember={(data) => dispatch(a.addParticipants({ room_id: dataRoom.id, user_id: data }))}
                        />
                    </div>
                </div>
            }
        />
    )
}

export { Chat }
