import { takeEvery, put } from 'redux-saga/effects'
import * as t from '../const.config'
import * as a from '../../config/api'

export const BikinEvent = [
    takeEvery(t.ADD_EVENT, workerAddEvent),
    takeEvery(t.UPDATE_EVENT, workerUpdateEvent),
    takeEvery(t.REMOVE_EVENT, workerRemoveEvent),
    takeEvery(t.GET_LIST_RSVP, workerGetListRsvp),
    takeEvery(t.DO_APPROVE_RSVP, workerDoApproveRsvp),
]

function* workerAddEvent(action) {
    const body = action.payload
    yield put({ type: t.ADD_EVENT_START })
    try {
        const data = yield a.getDataFromApi(body, a.addEventApi, 'respond', 'form')
        yield put({ type: t.ADD_EVENT_SUCCESS, data })
    } catch (error) {
        yield put({ type: t.ADD_EVENT_FAILED })
    }
}

function* workerUpdateEvent(action) {
    const { body, gcal } = action.payload
    yield put({ type: t.UPDATE_EVENT_START, gcal })
    try {
        const data = yield a.getDataFromApi(body, a.updateEventApi, '', 'form')
        yield put({ type: t.UPDATE_EVENT_SUCCESS, gcal })
    } catch (error) {
        yield put({ type: t.UPDATE_EVENT_FAILED, gcal })
    }
}

function* workerRemoveEvent(action) {
    const body = action.payload
    yield put({ type: t.REMOVE_EVENT_START })
    try {
        const data = yield a.getDataFromApi(body, a.removeEventApi)

        yield put({ type: t.REMOVE_EVENT_SUCCESS })
    } catch (error) {
        yield put({ type: t.REMOVE_EVENT_FAILED })
    }
}

function* workerGetListRsvp(action) {
    const body = action.payload
    yield put({ type: t.GET_LIST_RSVP_START })
    try {
        const data = yield a.getDataFromApi(body, a.getRsvpApi, 'page')
        const pages = data.page
        const isLoadMore = body.callpage > 1

        yield put({ type: t.GET_LIST_RSVP_SUCCESS, data: data.data?.detail, isLoadMore, pages })
    } catch (error) {
        yield put({ type: t.GET_LIST_RSVP_FAILED })
    }
}

function* workerDoApproveRsvp(action) {
    const body = action.payload
    yield put({ type: t.DO_APPROVE_RSVP_START })
    try {
        const data = yield a.getDataFromApi(body, a.doApproveRsvpApi)

        yield put({ type: t.DO_APPROVE_RSVP_SUCCESS })
    } catch (error) {
        yield put({ type: t.DO_APPROVE_RSVP_FAILED })
    }
}