import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Icon from 'react-web-vector-icons'
import * as a from '../../redux/actions'
import { ActionPost, Base, CommentSection } from '../../components'
import { useParams } from 'react-router-dom'
import { Spinner } from '../../components'

function DetailArticle() {
    const dispatch = useDispatch()
    const { id, title, idContent } = useParams()
    const { listComment, commentLoading } = useSelector(state => state.feedCommunity)
    const { detailArticle, isLoading } = useSelector(state => state.articleCommunity)


    const fetchData = (loading) => {
        dispatch(a.getArticleCommunity({ id: null, idArticle: title ? id : idContent, type: 'byId', loading }))
    }

    const doLike = async (id, type) => {
        if (!isLoading) {
            await dispatch(a.doLikePost({ id, type, loading: false }))
            fetchData(false)
        }
    }

    React.useEffect(() => {
        fetchData()
        fetchComment(null, true, 0, true)
    }, [])

    const fetchComment = async (id, loading, callpage = 0, reset,) => {
        await dispatch(a.doResetListComment())
        await dispatch(a.getCommentPost({
            id: idContent,
            loading, next: callpage, reset
        }))
    }

    const showComment = (id) => {
        fetchComment(idContent, true, 0, false)
    }

    return (
        <Base
            hideSearch
            main={
                isLoading ?
                    <Spinner size={12} top={12} />
                    :
                    <div className="my-6 px-3 md:px-0 mb-20">
                        <div>
                            <h2 className="text-lg md:text-xl pb-1">{detailArticle?.post_title}</h2>
                            <a className="text-red-500 text-sm md:text-base" href={detailArticle?.link}>
                                by {detailArticle?.display_name} <span className="text-black"> | {detailArticle?.post_date}</span>
                            </a>
                        </div>
                        <div className="pt-6">
                            {detailArticle.featuredimage ?
                                <img
                                    className="object-contain h-auto w-full mb-4"
                                    src={detailArticle?.featuredimage[0]?.image}
                                    alt=""
                                /> : null}
                            <div dangerouslySetInnerHTML={{ __html: detailArticle?.post_content }} />
                            <ActionPost
                                like={detailArticle?.liked_count}
                                comment={detailArticle?.comment_count}
                                isLiked={detailArticle?.is_liked}
                                onClickLike={() => doLike(detailArticle?.id, detailArticle?.is_liked ? "unlike" : "like")}
                                onClickComment={() => showComment(id)}
                                style="mt-5 py-2"
                            />
                            {commentLoading ?
                                <div className="flex items-center justify-center m-2">
                                    <Spinner size={12} />
                                </div>
                                :
                                <CommentSection
                                    id={idContent}
                                    dataComment={listComment}
                                />
                            }
                        </div>
                    </div>
            }
            right={<div />}
        />
    )
}

export { DetailArticle }
