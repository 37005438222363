import { takeEvery, call, put } from 'redux-saga/effects'
import * as t from '../const.config'
import Axios from '../../utils/server/baseURL'
import qs from 'qs'

export const MessageGlobal = [
    takeEvery(t.MESSAGE_GLOBAL_LOADING, workerGetMessage),
]

const getMessage = async token => {
    try {
        const data = await Axios({
            url: `/api/custom_service/?service=get_maintenance`,
            data: qs.stringify({
                ACCESS_TOKEN: token,
            })
        })
        return data
    } catch (error) {
        return error
    }
}

function* workerGetMessage(action) {
    try {
        const response = yield call(getMessage, action.payload)
        if (response.data.respond === 1) {
            yield put({
                type: t.MESSAGE_GLOBAL_SUCCESS,
                data: response.data.result
            })
        } else {
            throw response
        }
    } catch (error) {
        yield put({
            type: t.MESSAGE_GLOBAL_FAILED
        })
    }
}