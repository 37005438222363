import React from 'react'
import Helmet from 'react-helmet'
import { useSelector } from "react-redux"

const TitleComponent = ({ title }) => {
    const { count } = useSelector(state => state.chat)
    const { isSuccessSaveToken } = useSelector(state => state.user)
    const sufix = " | SocialConnext"
    const defaultTitle = 'SocialConnext';
    return (
        <Helmet>
            <title>{title ? `${count.all && isSuccessSaveToken ? '(' + count.all + ')' : ''} ${title + sufix}` : defaultTitle}</title>
        </Helmet>
    );
};

const Title = ({ component: Component, title }) => {
    return class Title extends React.Component {
        render() {
            return (
                <React.Fragment>
                    <TitleComponent title={title} />
                    <Component {...this.props} />
                </React.Fragment>
            );
        }
    };
};

export { Title }