import { takeEvery, call, put } from 'redux-saga/effects'
import * as t from '../../const.config'
import * as a from '../../../config/api'
// import Axios from '../../../utils/server/baseURL'
// import qs from 'qs'

export const BannerHome = [
    takeEvery(t.BANNER_START, workerGetBanners)
]


// const banner = async token => {
//     try {
//         const data = await Axios({
//             url: `/api/getpages/?taxonomy_id=301`,
//             data: qs.stringify({
//                 ACCESS_TOKEN: token
//             })
//         })
//         return data
//     } catch (error) {
//         return error
//     }
// }

// function* workerGetBanner(action) {
//     try {
//         const response = yield call(banner, action.payload)
//         if (response.data.respond === 1) {
//             yield put({
//                 type: t.BANNER_SUCCESS,
//                 data: response.data.result
//             })
//         } else {
//             throw response
//         }
//     } catch (error) {
//         yield put({
//             type: t.BANNER_FAILED,
//             message: error.data.message
//         })
//     }
// }

function* workerGetBanners() {
    yield put({ type: t.BANNER_LOADING })
    try {
        const body = {
        }
        const data = yield a.getDataFromApi(body, a.getBanner)

        yield put({ type: t.BANNER_SUCCESS, data })
    } catch (error) {
        yield put({ type: t.BANNER_FAILED })
    }
}