import * as t from '../../const.config'

const initialState = {
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    commentLoading: false,
    commentSuccess: false,
    commentFailed: false,
    listPost: [],
    listPostbyId: [],
    listCategory: [],
    listCity: [],
    listProvince: [],
    followData: {},
    listComment: [],
    pages: 0,
    pagging: {},
    profile: [],
    listCommunity: [],
    rating: "notnow"
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case t.GET_POST_START:
            return {
                ...state,
                isLoading: action.loading ? true : false,
                isSuccess: false,
                isFailed: false,
                listPostbyId: action.reset ? [] : state.listPostbyId,
                followData: {},
                profile: action.reset ? [] : state.profile
            }
        case t.GET_POST_SUCCESS:
            let stateData = action.state === "listPost" ? state.listPost : state.listPostbyId
            const data = action.isLoadMore ? stateData.concat(action.data) :
                !action.hasItem ? action.data.length ? action.data : stateData :
                    action.data
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                [action.state]: data,
                pages: action.pages
            }
        case t.GET_POST_FAILED:
            return {
                ...state,
                isLoading: false,
                isFailed: true,
            }
        case t.GET_CATEGORY_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
                isFailed: false,
            }
        case t.GET_CATEGORY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                listCategory: action.data
            }
        case t.GET_CATEGORY_FAILED:
            return {
                ...state,
                isLoading: false,
                isFailed: true,
            }
        case t.GET_CITY_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
                isFailed: false,
            }
        case t.GET_CITY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                listCity: action.data
            }
        case t.GET_CITY_FAILED:
            return {
                ...state,
                isLoading: false,
                isFailed: true,
            }
        case t.GET_PROVINCE_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
                isFailed: false,
            }
        case t.GET_PROVINCE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                listProvince: action.data
            }
        case t.GET_PROVINCE_FAILED:
            return {
                ...state,
                isLoading: false,
                isFailed: true,
            }
        case t.DO_FOLLOW_START:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                isFailed: false,
                followData: {}
            }
        case t.DO_FOLLOW_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                followData: action.data
            }
        case t.DO_FOLLOW_FAILED:
            return {
                ...state,
                isLoading: false,
                isFailed: true,
            }
        case t.DO_LIKE_START:
            return {
                ...state,
                isLoading: action.loading,
                isSuccess: false,
                isFailed: false,
            }
        case t.DO_LIKE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
            }
        case t.DO_LIKE_FAILED:
            return {
                ...state,
                isLoading: false,
                isFailed: true,
            }
        case t.GET_COMMENT_START:
            return {
                ...state,
                commentLoading: action.loading,
                commentSuccess: false,
                commentFailed: false,
                listComment: action.reset ? [] : state.listComment
            }
        case t.GET_COMMENT_SUCCESS:
            const stateDataComment = state.listComment
            const dataComment = action.isLoadMore ? stateDataComment.concat(action.data) : action.data
            return {
                ...state,
                commentLoading: false,
                commentSuccess: true,
                pagging: action.page,
                listComment: dataComment
            }
        case t.GET_COMMENT_FAILED:
            return {
                ...state,
                commentLoading: false,
                commentFailed: true,
            }
        case t.DO_COMMENT_START:
            return {
                ...state,
                commentLoading: false,
                commentSuccess: false,
                commentFailed: false,
            }
        case t.DO_COMMENT_SUCCESS:
            return {
                ...state,
                commentLoading: false,
                commentSuccess: true,
            }
        case t.DO_COMMENT_FAILED:
            return {
                ...state,
                commentLoading: false,
                commentFailed: true,
            }
        case t.DO_RESET_LIST_COMMENT:
            return {
                ...state,
                listComment: [],
            }
        case t.GET_PROFILE_COMMUNITY_START:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                isFailed: false,
            }
        case t.GET_PROFILE_COMMUNITY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                profile: action.data
            }
        case t.GET_PROFILE_COMMUNITY_FAILED:
            return {
                ...state,
                isLoading: false,
                isFailed: true,
            }
        case t.GET_COMMUNITY_LIST_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
                isFailed: false,
                listCommunity: []
            }
        case t.GET_COMMUNITY_LIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                listCommunity: action.data
            }
        case t.GET_COMMUNITY_LIST_FAILED:
            return {
                ...state,
                isLoading: false,
                isFailed: true,
            }
        case t.DO_RESET_COMMUNITY_LIST:
            return {
                ...state,
                listCommunity: []
            }
        case t.DO_SET_POPUP_RATING:
            return {
                ...state,
                rating: action.status
            }
        default:
            return state;
    }
}

export default reducer;