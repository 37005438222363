import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'
import * as URL from './const'
import * as page from './component'
import { useSelector } from 'react-redux'
import { Page404 } from '../pages'

const RoutersConfig = () => {
  const { isSuccessSaveToken } = useSelector(state => state.user)
  return (
    <Router>
      <Switch>

        <Route exact path={URL.Login}>
          {isSuccessSaveToken ? <Redirect to={URL.Community} /> : <Route path={URL.Login} component={page.Login} />}
        </Route>
        <Route exact path={URL.Register}>
          {isSuccessSaveToken ? <Redirect to={URL.Community} /> : <Route path={URL.Register} component={page.Register} />}
        </Route>


        <Route path={URL.Login} component={page.Login} />
        <Route path={URL.Register} component={page.Register} />
        <Route path={URL.FAQ} component={page.FAQ} />

        <Route exact path={URL.Landing} component={page.Landing} />
        <PrivateRoute path='/'>
          <Route path={URL.Community} component={page.Feed} exact />
          <Route path={URL.CommunityHome} component={page.CommunityHome} exact />
          <Route path={URL.Event} component={page.Event} exact />
          <Route path={URL.EventDetail} component={page.EventDetail} />
          <Route path={URL.Photo} component={page.Photo} exact />
          <Route path={URL.PhotoDetail} component={page.PhotoDetail} />
          <Route path={URL.Member} component={page.Member} />
          <Route path={URL.ArticleCommunity} component={page.Articles} exact />
          <Route path={URL.ArticleDetail} component={page.DetailArticle} />
          <Route path={URL.PodcastCommunity} component={page.Podcasts} exact />

          <Route path={URL.BikinEvent} component={page.FormEvent} />
          <Route path={URL.ListEvent} component={page.ListEvent} />
          <Route path={URL.RsvpEvent} component={page.RsvpEvent} exact />
          <Route path={URL.ListRsvp} component={page.ListRsvp} exact />

          <Route path={URL.Chat} component={page.Chat} />

          <Route path={URL.AskMentor} component={page.AskMentor} exact />
          <Route path={URL.AskMentorDetail} component={page.AskMentorDetail} />
          <Route path={URL.Mentors} component={page.Mentors} exact />

          <Route path={URL.Articles} component={page.Articles} />
          <Route path={URL.DetailArticle} component={page.DetailArticle} />

          <Route path={URL.Podcasts} component={page.Podcasts} />
          <Route path={URL.Profile} component={page.Profile} />
          <Route path={URL.notFound} component={Page404} />
        </PrivateRoute>
      </Switch>
    </Router>
  )
}

function PrivateRoute({ children, ...rest }) {
  const { isSuccessSaveToken, isTokenValid, isLogin } = useSelector(state => state.user)
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isSuccessSaveToken ? (
          children
        ) : (
          !isTokenValid && isLogin && alert('Sesi login anda telah berakhir. Harap masuk lagi.'),
          <Redirect
            to={{
              pathname: URL.Login,
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

export default RoutersConfig