import * as t from '../../const.config'

export const getPostCommunity = (item) => ({
    type: t.GET_POST,
    payload: item
})

export const getCategoryCommunity = () => ({
    type: t.GET_CATEGORY,
})

export const getProvinceCommunity = () => ({
    type: t.GET_PROVINCE,
})

export const getCityCommunity = (item) => ({
    type: t.GET_CITY,
    payload: item
})

export const doFollowCommunity = (item) => ({
    type: t.DO_FOLLOW,
    payload: item
})

export const doLikePost = (item) => ({
    type: t.DO_LIKE,
    payload: item
})

export const getCommentPost = (item) => ({
    type: t.GET_COMMENT,
    payload: item
})

export const doCommentPost = (item) => ({
    type: t.DO_COMMENT,
    payload: item
})

export const doResetListComment = () => ({
    type: t.DO_RESET_LIST_COMMENT
})

export const getProfileCommunity = (item) => ({
    type: t.GET_PROFILE_COMMUNITY,
    payload: item
})

export const doResetAllPostDetail = () => ({
    type: t.DO_RESET_ALL_POST_DETAIL,
})

export const doResetCommunityList = () => ({
    type: t.DO_RESET_COMMUNITY_LIST
})

export const getCommunityList = (item) => ({
    type: t.GET_COMMUNITY_LIST,
    payload: item
})