import React, { useState, useEffect } from 'react'
import { IoMdExit, IoMdClose, IoMdCamera } from 'react-icons/io'
import { FaUserPlus } from 'react-icons/fa'
import { MdModeEdit } from 'react-icons/md'
import { FlatList, Alert, SimpleModal, Modal, Spinner } from '../../components'
import { useDispatch, useSelector } from "react-redux"
import * as a from '../../redux/actions'
import Toast from '../../utils/toast'

function RoomDetail({ visible, data }) {
    const [showAlert, setShowAlert] = useState(false)
    const [showOption, setShowOption] = useState(false)
    const [userData, setUserData] = useState({})
    const [alertOption, setAlertOption] = useState({})
    const [showEdit, setShowEdit] = useState(false)
    const { onClickBack, dataRoom, personal, profileChat, showDetail, mobile, onExitGroup,
        onAddMember, onClickOption, success, loading, typeList
    } = data
    const [name, setName] = useState("")
    const [description, setDesc] = useState("")
    const [img, setImg] = useState({})
    const dispatch = useDispatch()
    const { user } = useSelector(state => state.user)
    const { isLoadingRoom, isSuccessEditDesc, isSuccessUpAvatar, isSuccessUpGroupName, roomDetail } = useSelector(state => state.chat)
    const isAdmin = roomDetail?.admins && roomDetail?.admins.length && roomDetail?.admins.includes(dataRoom?.myId) || false
    const profile = [
        // { id: 0, label: 'Email', value: profileChat?.email || "-" },
        // { id: 1, label: 'Nomor telepon', value: profileChat?.phone || "-" },
        { id: 2, label: 'Hobi', value: profileChat?.hobby || "-" },
        { id: 3, label: 'Bergabung sejak', value: profileChat?.joined || "-" },
        { id: 4, label: 'Deskripsi', value: profileChat?.description || "-" },
    ]
    let option = [
        { id: 0, text: userData.username, onPress: () => null, title: true },
        ...(!userData?.admin ? [{
            id: 1, text: 'Make group admin', onPress: () => setAlertOption({
                title: null, subTitle: `make ${userData.username} as admin`, type: 'admin'
            })
        }] : []),
        {
            id: 2, text: 'Remove from group', onPress: () => setAlertOption({
                title: null, subTitle: `remove ${userData.username} from group`, type: 'remove'
            })
        },
    ]

    const handlePickImage = (e) => {
        e.preventDefault()
        const file = e.target.files[0]
        setImg({ url: URL.createObjectURL(file), file })
    }

    const hasWhiteSpace = (text) => {
        return /^\s/.test(text)
    }

    const handleClickSave = () => {
        if (img?.url !== roomDetail?.room_avatar_url) {
            const body = new FormData()
            body.append('ACCESS_TOKEN', user.access_token_data.token)
            body.append('file', img?.file)
            body.append('room_id', dataRoom?.id)
            body.append('type', 'file')
            dispatch(a.updateAvatarGroup(body))
        }
        if (name && !hasWhiteSpace(name) && name !== dataRoom?.name) {
            dispatch(a.updateGroupName({ room_id: dataRoom?.id, room_name: name }))
        }
        if (description !== roomDetail?.description) {
            dispatch(a.editDesc({ description, room_id: dataRoom?.id }))
        }
    }

    useEffect(() => {
        setAlertOption({})
        setUserData({})
    }, [success])

    useEffect(() => {
        setName(roomDetail?.room_name)
        setDesc(roomDetail?.description)
        setImg({ url: roomDetail?.room_avatar_url })
    }, [showEdit])

    useEffect(() => {
        if (isSuccessEditDesc || isSuccessUpAvatar || isSuccessUpGroupName) {
            dispatch(a.getRoomDetail({ id: dataRoom?.id, personal: typeList == 'single' }))
            setShowEdit(false)
        }
    }, [isSuccessEditDesc, isSuccessUpAvatar, isSuccessUpGroupName])

    const renderModalEdit = () => {
        const style = {
            label: 'text-sm font-semibold mb-1 text-gray-600',
            input: 'outline-none text-sm w-full py-2 px-3 mb-3 border rounded-md'
        }
        return (
            <Modal visible={showEdit} className="flex-col px-5 py-3" containerStyle="w-full">
                <div className="flex items-center mb-3">
                    <IoMdClose className="text-2xl mr-2" onClick={() => setShowEdit(false)} />
                    <p className="text-base font-semibold">Edit group</p>
                </div>
                <div className="w-full flex justify-center mb-5">
                    <div className="relative">
                        <img src={img?.url} className="h-32 w-32 object-cover rounded-full border" />
                        <input type="file" onChange={handlePickImage} id="upload" hidden />
                        <label for="upload">
                            <IoMdCamera className="text-3xl mr-2 absolute right-0.5 bottom-0 bg-red-500 text-white rounded-full p-1" />
                        </label>
                    </div>
                </div>
                <input
                    className={style.input}
                    placeholder="Name"
                    value={name}
                    onChange={e => setName(e.target.value)}
                />
                <input
                    className={style.input}
                    placeholder="Description"
                    value={description}
                    onChange={e => setDesc(e.target.value)}
                />
                <button
                    onClick={handleClickSave}
                    className="text-white bg-red-500 w-full rounded-md py-2 font-semibold text-sm mt-6 mb-3">
                    {isLoadingRoom ? <Spinner size={5} /> : 'Save'}
                </button>
            </Modal>
        )
    }

    const renderItem = ({ item }) => {
        const name = item?.user_id == dataRoom?.myId ? 'You' : item?.username
        const admin = roomDetail?.admins && roomDetail?.admins.length && roomDetail?.admins.includes(item?.user_id) || false
        const isOnline = item.extras?.online
        return (
            <button onClick={() => {
                if (name !== 'You' && isAdmin) {
                    setShowOption(true)
                    setUserData({ ...item, admin })
                }
            }} className="flex items-center justify-between mb-2 w-full px-3">
                <div className="flex items-center" >
                    <div class="relative">
                        <img src={item?.avatar_url} className="h-8 w-8 object-cover rounded-full mr-2" />
                        {isOnline &&
                            <div className={`bg-green-500 rounded-full absolute -bottom-0.5 right-1.5 h-2.5 w-2.5 border`}></div>
                        }
                    </div>
                    <p className="text-xs sm:text-sm text-gray-700">{name}</p>
                </div>
                {admin && <div className="bg-red-500 text-white text-xs px-1 py-0.5 rounded-2xl">Admin</div>}
            </button>
        )
    }

    return (
        visible ?
            <div className={`flex w-full md:w-3/5 h-full flex-col border-r border-l md:border-l-0 bg-gray-50 ${!showDetail && mobile && 'hidden'} relative`}>
                <SimpleModal
                    visible={showOption}
                    onClose={() => setShowOption(false)}
                    data={option}
                    style="sm:w-1/3"
                />
                {renderModalEdit()}
                <div className="flex items-center w-full border-b px-3 bg-white h-14">
                    <IoMdClose className="text-2xl mr-2" onClick={onClickBack} />
                    <div className="flex flex-col justify-center w-full">
                        <p className={`text-sm font-semibold line-clamp-1 text-left w-4/6`}>Info {personal ? 'Pribadi' : 'Grup'}</p>
                    </div>
                </div>
                <div className={`h-full overflow-y-auto w-full relative`}>
                    {isAdmin && !personal && <MdModeEdit className="text-xl absolute right-2 top-2" onClick={() => setShowEdit(true)} />}
                    <div className={`bg-white pt-5 pb-3 flex flex-col items-center justify-center w-full`}>
                        <button>
                            <img src={personal ? profileChat?.avatar : roomDetail?.room_avatar_url} className="h-40 w-40 sm:h-56 sm:w-56 object-cover rounded-full" />
                        </button>
                        <p className={`text-lg font-semibold w-full text-center mt-3`}>{personal ? profileChat?.name : roomDetail?.room_name}</p>
                    </div>
                    <div className={`bg-white px-3 py-2 mt-2`}>
                        <p className="text-sm text-red-500 mb-1">{personal ? "Tentang" : "Deskripsi"}</p>
                        {personal ?
                            profile.map(x => {
                                return (
                                    <div key={x.id} className="flex items-center mb-1">
                                        <p className="text-xs sm:text-sm text-gray-700 w-1/3">{x.label}</p>
                                        <p className="text-xs sm:text-sm text-gray-700">{x.value}</p>
                                    </div>
                                )
                            })
                            :
                            <p className="text-sm text-gray-700">{roomDetail?.description || "Tidak ada deskripsi"}</p>
                        }
                    </div>
                    {!personal &&
                        <>
                            <div className="mt-2 bg-white py-2">
                                <FlatList
                                    data={roomDetail?.participants_name || []}
                                    renderItem={renderItem}
                                    ListHeaderComponent={() => (
                                        <>
                                            <p className="text-sm text-red-500 mb-2 ml-3">{roomDetail?.participants_name && roomDetail?.participants_name.length || 0} Peserta</p>
                                            {isAdmin &&
                                                <button onClick={onAddMember} className="flex items-center mb-2 w-full px-3">
                                                    <div className="h-8 w-8 mr-2 bg-red-500 flex items-center justify-center rounded-full">
                                                        <FaUserPlus className="text-lg text-white" />
                                                    </div>
                                                    <div className="flex items-center" >
                                                        <p className="text-xs sm:text-sm text-gray-700">Tambah peserta</p>
                                                    </div>
                                                </button>
                                            }
                                        </>
                                    )}
                                />
                            </div>
                            <div className={`bg-white px-3 py-3 ${personal && 'h-full'} mt-2 md:mb-2 mb-14`}>
                                <button onClick={() => setShowAlert(true)} className="flex items-center text-md text-red-500">
                                    <IoMdExit className="text-xl mr-3 ml-1" />
                                    Keluar grup
                                </button>
                            </div>
                        </>
                    }
                </div>
                <Alert
                    title="Keluar grup"
                    subTitle="Apa kamu yakin akan keluar dari grup ini?"
                    visible={showAlert}
                    onClick={() => {
                        onExitGroup()
                        setShowAlert(false)
                    }}
                    onClose={() => setShowAlert(false)}
                />
                <Alert
                    title={null}
                    subTitle={`Are you sure you want to ${alertOption.subTitle || ''}`}
                    visible={Object.keys(alertOption).length ? true : false}
                    onClick={() => !loading && onClickOption(alertOption.type, userData?.user_id)}
                    onClose={() => setAlertOption({})}
                    loading={loading}
                />
            </div> : null
    )
}

export default RoomDetail