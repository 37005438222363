import { takeEvery, put } from 'redux-saga/effects'
import * as t from '../../const.config'
import * as a from '../../../config/api'

export const FeedCommunity = [
    takeEvery(t.GET_POST, workerGetPost),
    takeEvery(t.GET_CATEGORY, workerGetCategory),
    takeEvery(t.GET_PROVINCE, workerGetProvince),
    takeEvery(t.GET_CITY, workerGetCity),
    takeEvery(t.DO_FOLLOW, workerDoFollow),
    takeEvery(t.DO_LIKE, workerDoLike),
    takeEvery(t.GET_PROFILE_COMMUNITY, workerGetProfileCommunity),
    takeEvery(t.GET_COMMUNITY_LIST, workerGetCommunityList),
    takeEvery(t.GET_COMMENT, workerGetComment),
    takeEvery(t.DO_COMMENT, workerDoComment),
]

function* workerGetPost(action) {
    const { item = {}, reset = true, loading } = action.payload
    const isLoading = loading ? loading : reset
    yield put({ type: t.GET_POST_START, reset: reset === false ? false : true, loading: isLoading })
    try {
        const body = {
            perpage: 10,
            callpage: 1,
            ...item
        }
        const data = yield a.getDataFromApi(body, a.getPostApi, 'page')
        const state = item?.community_id ? "listPostbyId" : "listPost"
        const pages = data.page
        const isLoadMore = item.callpage > 1
        const hasItem = item ? Object.keys(item).length ? true : false : false

        yield put({ type: t.GET_POST_SUCCESS, data: data.data, state, isLoadMore, pages, hasItem })
    } catch (error) {
        yield put({ type: t.GET_POST_FAILED })
    }
}

function* workerGetCategory() {
    yield put({ type: t.GET_CATEGORY_START })
    try {
        const data = yield a.getDataFromApi({}, a.getCategoryApi)

        yield put({ type: t.GET_CATEGORY_SUCCESS, data })
    } catch (error) {
        yield put({ type: t.GET_CATEGORY_FAILED })
    }
}

function* workerGetProvince() {
    yield put({ type: t.GET_PROVINCE_START })
    try {
        const body = {
            ACCESS_TOKEN: null,
            CONSUMER_KEY: a.CONSUMER_KEY,
            SECRET_KEY: a.SECRET_KEY
        }
        const data = yield a.getDataFromApi(body, a.getProvinceApi)

        yield put({ type: t.GET_PROVINCE_SUCCESS, data })
    } catch (error) {
        yield put({ type: t.GET_PROVINCE_FAILED })
    }
}

function* workerGetCity(action) {
    const { id } = action.payload
    yield put({ type: t.GET_CITY_START })
    try {
        const body = {
            ACCESS_TOKEN: null,
            CONSUMER_KEY: a.CONSUMER_KEY,
            SECRET_KEY: a.SECRET_KEY
        }
        const data = yield a.getDataFromApi(body, `${a.getCityApi}&id_prov=${id}`)

        yield put({ type: t.GET_CITY_SUCCESS, data })
    } catch (error) {
        yield put({ type: t.GET_CITY_FAILED })
    }
}

function* workerDoFollow(action) {
    const { id, type } = action.payload
    yield put({ type: t.DO_FOLLOW_START })
    try {
        const body = {
            authorid: id
        }
        const api = type === "follow" ? a.doFollowApi : a.doUnfollowApi
        const data = yield a.getDataFromApi(body, api, 'msg')
        const msg = type === "follow" ? "Author has been followed successfully" : "Author has been unfollowed successfully"
        const newData = {
            success: data === msg ? true : false,
            id, type
        }

        yield put({ type: t.DO_FOLLOW_SUCCESS, data: newData })
    } catch (error) {
        yield put({ type: t.DO_FOLLOW_FAILED })
    }
}

function* workerDoLike(action) {
    const { id, type, loading = true } = action.payload
    yield put({ type: t.DO_LIKE_START, loading })
    try {
        const api = type === "like" ? `${a.doLikePostApi}&post_id=${id}` : `${a.doUnlikePostApi}&post_id=${id}`
        const data = yield a.getDataFromApi({}, api, 'msg')

        yield put({ type: t.DO_LIKE_SUCCESS, data })
    } catch (error) {
        yield put({ type: t.DO_LIKE_FAILED })
    }
}

function* workerGetProfileCommunity(action) {
    const { id } = action.payload
    yield put({ type: t.GET_PROFILE_COMMUNITY_START })
    try {
        const body = {
            community_id: id
        }
        const data = yield a.getDataFromApi(body, a.getProfileApi)

        yield put({ type: t.GET_PROFILE_COMMUNITY_SUCCESS, data })
    } catch (error) {
        yield put({ type: t.GET_PROFILE_COMMUNITY_FAILED })
    }
}

function* workerGetCommunityList(action) {
    const { keyword } = action.payload
    yield put({ type: t.GET_COMMUNITY_LIST_START })
    try {
        const body = {
            search: keyword
        }
        const data = yield a.getDataFromApi(body, a.getCommunityApi)

        yield put({ type: t.GET_COMMUNITY_LIST_SUCCESS, data })
    } catch (error) {
        yield put({ type: t.GET_COMMUNITY_LIST_FAILED })
    }
}

function* workerGetComment(action) {
    const { id, loading = true, next, reset = false } = action.payload
    yield put({ type: t.GET_COMMENT_START, loading, reset })
    try {
        // const api = `${getCommentApi}&callpage=${page}&perpage=10&post_id=${id}`
        const api = `${a.getCommentApi}/?post_id=${id}&callpage=${next}&perpage=10`
        const data = yield a.getDataFromApi({}, api, 'comment')
        const isLoadMore = next > 1
        const page = data.page

        yield put({ type: t.GET_COMMENT_SUCCESS, data: data.data, isLoadMore, page })
    } catch (error) {
        yield put({ type: t.GET_COMMENT_FAILED })
    }
}

function* workerDoComment(action) {
    const { id, comment, idComment } = action.payload
    yield put({ type: t.DO_COMMENT_START })
    try {
        const parentId = idComment ? `&parentid=${idComment}` : ''
        const api = `${a.doCommentApi}/?post_id=${id}&content=${comment}${parentId}`
        const data = yield a.getDataFromApi({}, api, 'msg')

        yield put({ type: t.DO_COMMENT_SUCCESS, data })
    } catch (error) {
        yield put({ type: t.DO_COMMENT_FAILED })
    }
}