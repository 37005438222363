import * as t from '../const.config'

const initialState = {
    isLoading: false,
    isSuccess: false,
    isSuccessRemove: false,
    isFailed: false,
    isFailedRemove: false,
    pages: 0,
    listRsvp: [],
    isLoadingAcc: false,
    isSuccessAcc: false,
    isFailedAcc: false,
    gcal: false,
    eventId: null
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case t.ADD_EVENT_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
                isFailed: false,
                eventId: null
            }
        case t.ADD_EVENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                eventId: action.data
            }
        case t.ADD_EVENT_FAILED:
            return {
                ...state,
                isLoading: false,
                isFailed: true,
            }
        case t.UPDATE_EVENT_START:
            return {
                ...state,
                isLoading: !action.gcal,
                isSuccess: false,
                isFailed: false,
            }
        case t.UPDATE_EVENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: !action.gcal,
                gcal: action.gcal,
                eventId: null
            }
        case t.UPDATE_EVENT_FAILED:
            return {
                ...state,
                isLoading: false,
                isFailed: !action.gcal,
                gcal: action.gcal
            }
        case t.REMOVE_EVENT_START:
            return {
                ...state,
                isLoading: true,
                isSuccessRemove: false,
                isFailedRemove: false,
            }
        case t.REMOVE_EVENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccessRemove: true,
            }
        case t.REMOVE_EVENT_FAILED:
            return {
                ...state,
                isLoading: false,
                isFailedRemove: true,
            }
        case t.GET_LIST_RSVP_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
                isFailed: false,
                isLoadingAcc: false,
                isSuccessAcc: false,
                isFailedAcc: false,
            }
        case t.GET_LIST_RSVP_SUCCESS:
            const stateData = state.listRsvp
            const data = action.isLoadMore ? stateData.concat(action.data) : action.data
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                listRsvp: data,
                pages: action.pages
            }
        case t.GET_LIST_RSVP_FAILED:
            return {
                ...state,
                isLoading: false,
                isFailed: true,
            }
        case t.DO_APPROVE_RSVP_START:
            return {
                ...state,
                isLoadingAcc: true,
                isSuccessAcc: false,
                isFailedAcc: false,
                gcal: false
            }
        case t.DO_APPROVE_RSVP_SUCCESS:
            return {
                ...state,
                isLoadingAcc: false,
                isSuccessAcc: true,
            }
        case t.DO_APPROVE_RSVP_FAILED:
            return {
                ...state,
                isLoadingAcc: false,
                isFailedAcc: true,
            }
        case t.RESET_BIKIN_EVENT:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                isFailed: false,
                isSuccessRemove: false,
                isFailedRemove: false,
                listRsvp: [],
                isLoadingAcc: false,
                isSuccessAcc: false,
                isFailedAcc: false,
                gcal: false,
                eventId: null
            }
        case t.SET_GCAL:
            return {
                ...state,
                gcal: action.payload
            }
        default:
            return state;
    }
}

export default reducer;