import { createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import logger from 'redux-logger'
import { persistStore, persistReducer } from 'redux-persist'
import rootSaga from './sagas'
import reducers from './reducers'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
    key: 'root',
    storage: storage
}

const persistedReducer = persistReducer(persistConfig, reducers)
const sagaMiddleware = createSagaMiddleware()
const middleware = applyMiddleware(sagaMiddleware)
let store = createStore(persistedReducer, middleware)
let persistor = persistStore(store)

sagaMiddleware.run(rootSaga)

export { store, persistor, sagaMiddleware }