import * as t from '../../const.config'

const initialState = {
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    listEvent: [],
    registerData: {},
    detailEvent: {},
    pages: 0,
    isLoadingReg: false,
    isSuccessReg: false,
    isFailedReg: false,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case t.GET_EVENT_COMMUNITY_START:
            return {
                ...state,
                isLoading: action.loading,
                isSuccess: false,
                isFailed: false,
                listEvent: action.reset ? [] : state.listEvent,
                registerData: {},
                detailEvent: action.reset ? {} : state.detailEvent,
                isSuccessReg: false,
                isFailedReg: false,
            }
        case t.GET_EVENT_COMMUNITY_SUCCESS:
            const stateData = state.listEvent
            const data = action.isLoadMore ? stateData.concat(action.data) : action.data
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                [action.state]: data,
                pages: action.state === "listEvent" ? action.pages : state.pages
            }
        case t.GET_EVENT_COMMUNITY_FAILED:
            return {
                ...state,
                isLoading: false,
                isFailed: true,
            }
        case t.DO_REGISTER_EVENT_START:
            return {
                ...state,
                isLoadingReg: true,
                isSuccessReg: false,
                isFailedReg: false,
                registerData: {}
            }
        case t.DO_REGISTER_EVENT_SUCCESS:
            return {
                ...state,
                isLoadingReg: false,
                isSuccessReg: true,
                registerData: action.data,
            }
        case t.DO_REGISTER_EVENT_FAILED:
            return {
                ...state,
                isLoadingReg: false,
                isFailedReg: true,
            }
        case t.DO_RESET_EVENT_DETAIL:
        case t.DO_RESET_ALL_POST_DETAIL:
            return {
                ...state,
                detailEvent: {}
            }
        default:
            return state;
    }
}

export default reducer;