import { takeEvery, call, put } from 'redux-saga/effects'
import * as t from '../../const.config'
import Axios from '../../../utils/server/baseURL'
import qs from 'qs'

export const Bookmarks = [
    takeEvery(t.GET_BOOKMARK_LOADING, workerGetBookmarks),
]


const bookmarks = async token => {
    try {
        const data = await Axios({
            url: `/api/custom_service/?service=getbookmarks`,
            data: qs.stringify({
                ACCESS_TOKEN: token
            })
        })
        return data
    } catch (error) {
        return error
    }
}

function* workerGetBookmarks(action) {
    try {
        const response = yield call(bookmarks, action.payload)
        if (response.data.respond === 1) {
            yield put({
                type: t.GET_BOOKMARK_SUCCSESS,
                bookmarks: response.data.result[0]
            })
        } else {
            throw response
        }
    } catch (error) {
        yield put({
            type: t.GET_BOOKMARK_FAILURE,
            message: error.data.message
        })
    }
}