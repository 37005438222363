import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import * as a from '../../redux/actions'
import { Spinner } from '../Loading/Spinner'


function CommentSection({ dataComment, id, autoFocusInput }) {
    const { user } = useSelector(state => state.user)
    const [subComment, setSubComment] = React.useState(false)
    const { listComment, commentLoading } = useSelector(state => state.feedCommunity)
    const dispatch = useDispatch()

    const handleFormSubmit = (e) => {
        e.preventDefault();
        let comment = e.target.elements.comment?.value
        console.log(comment)
        dispatch(a.doCommentPost({ id, comment }))
        dispatch(a.getCommentPost({
            id,
            loading: true, next: 0, reset: true
        }))
        e.target.reset()
    };
    return (
        <div className="border-t p-4">
            <div>
                <form
                    className="flex space-x-1"
                    onSubmit={handleFormSubmit}
                >
                    <div>
                        <img
                            src={user?.avatar}
                            alt={user?.display_name}
                            className="h-9 w-9 bg-red-500 object-cover rounded-full border-red-500 border"
                        />
                    </div>
                    <input
                        id="comment"
                        name="comment"
                        required
                        autoFocus={autoFocusInput}
                        className="outline-none bg-gray-100 text-sm w-full py-2 px-3 rounded-full"
                        placeholder="Tulis komentar"
                    />
                </form>
                {commentLoading ?
                    <div className="flex items-center justify-center m-2">
                        <Spinner size={12} />
                    </div> :
                    dataComment.map(item => (
                        <div className="flex py-2">
                            <img
                                src={item.author[0]?.avatar}
                                alt={item?.first_name}
                                className="h-9 w-9 bg-red-500 object-cover rounded-full border-red-500 border"
                            />
                            <div className="ml-2">
                                <div className="px-3 py-1 bg-gray-100 rounded-2xl">
                                    <p className="font-bold pb-1">{`${item?.author[0]?.first_name} ${item?.author[0]?.last_name}`}</p>
                                    <p>{item?.comment_content}</p>
                                </div>
                                <div className="flex space-x-2 items-center">
                                    <p className="text-sm">{moment(item.comment_date).fromNow()}</p>
                                    {/* <button onClick={()=> setSubComment(!subComment)}>Reply</button> */}
                                </div>
                                {subComment ?
                                    <form className="flex space-x-1 mt-1">
                                        <div>
                                            <img
                                                src={user?.avatar}
                                                alt={user?.first_name}
                                                className="h-9 w-9 bg-red-500 object-cover rounded-full border-red-500 border"
                                            />
                                        </div>
                                        <input
                                            className="outline-none bg-gray-100 text-sm w-full py-2 px-3 rounded-full"
                                            placeholder="Tulis komentar"
                                        />
                                    </form> : null}
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    )
}

export { CommentSection }
