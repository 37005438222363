import React from 'react'
import Icon from 'react-web-vector-icons'
import { images } from '../../assets'
import { info, socialMedia, link } from '../../Dao'

const data = {
    imgSc: images.scWhite,
    address: 'Jl. Kemang Selatan IX No.1B, RT.1/RW.2, Bangka, Kec. Mampang Prpt., Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12730 (021) 71796721',
    sosmed: [
        { id: 0, img: images.fb },
        { id: 1, img: images.twitter },
        { id: 2, img: images.fb },
        { id: 3, img: images.twitter },
    ]
}

function Footer() {
    return (
        <div class="w-full flex mt-14 sm:mt-14 flex-col">
            <div class="bg-red-600 flex flex-col w-full items-center justify-around py-5 px-10">
                <img src={info.logo} class="h-6 sm:h-7 object-contain mb-2 self-center sm:self-center" />
                <div class="self-center text-center sm:text-center w-1/2 my-3">
                    <p class="text-white text-sm sm:text-base font-bold">Jakarta</p>
                    <p class="text-white text-sm sm:text-base">{info.address}</p>
                </div>
                <p class="text-white text-sm sm:text-base font-bold self-center lg:mb-2 mt-2">Download Sekarang!</p>
                <div className="flex justify-center mb-2">
                    <a href={link.playstore} target="_blank" className="block mt-4 lg:inline-block lg:mt-0 mr-2">
                        <img src={images.downloads.google.default} class="w-auto h-10 object-contain" alt="Play Store" />
                    </a>
                    <a href={link.appstore} target="_blank" className="block mt-4 lg:inline-block lg:mt-0 mr-4">
                        <img src={images.downloads.apple.default} class="w-auto h-10 object-contain" alt="Apple Store" />
                    </a>
                </div>
                <p class="text-white text-sm sm:text-base font-bold self-center mb-1 mt-2">Ikuti Kami</p>
                <div class="flex justify-center">
                    {socialMedia.map(x => (
                        <button className="space-x-1 flex ml-1 mr-1" onClick={() => window.open(x.path, "_blank")} key={x.name}>
                            <Icon
                                name={x.img}
                                font="AntDesign"
                                color='white'
                                size={20}
                            />
                        </button>
                    ))}
                </div>
            </div>
            <div class="h-12 bg-red-700 flex items-center justify-center">
                <p class="text-white text-xs sm:text-sm">SocialConnext Copyright 2021</p>
            </div>
        </div>
    )
}

export { Footer }
