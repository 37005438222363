import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { images } from '../../assets';
import { Header, Carousel, Footer } from '../../components';
import { link, langId } from '../../Dao';
import { Community, Register } from '../../routers/const'

function Landing(props) {
  const headText = "text-center text-lg sm:text-2xl md:text-3xl lg:text-4xl text-black-500 font-bold"
  const history = useHistory()
  const { isSuccessSaveToken } = useSelector(state => state.user)

  useEffect(() => {
    isSuccessSaveToken && history.push(Community)
  }, [])

  return (
    <>
      <div className="w-full container mx-auto">
        <Header landing={true} />
        <section class="px-9 mt-16 sm:mt-32 flex pb-16">
          <div class="w-full text-teal-lighter flex-row md:flex justify-center md:justify-start text-center md:text-left">
            <div class="md:w-1/2 w-full flex flex-col justify-center">
              <p class="leading-none sm:text-3xl md:text-3xl lg:text-4xl text-lg text-red-500 font-bold">
                {langId.landing.heroTitle}
              </p>
              <p class="my-5 text-sm sm:text-base md:text-lg lg:text-xl">
                {langId.landing.heroDes}
              </p>
              {!isSuccessSaveToken ?
                <div>
                  <button onClick={() => history.push(Register)} className="rounded-md w-full sm:w-auto flex-none bg-red-500 hover:bg-red-700 text-white text-lg leading-6 font-semibold py-3 px-6 border border-transparent  focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-red-700 focus:outline-none transition-colors duration-200">
                    Daftar Sekarang
                  </button>
                </div> : null}
              {/* <div className="flex w-full justify-center md:justify-start">
                <a href={link.playstore} target="_blank" className="block mt-4 lg:inline-block lg:mt-0 mr-4">
                  <img src={images.downloads.google.default} class="w-auto h-10 object-contain" alt="Play Store" />
                </a>
                <a href={link.appstore} className="block mt-4 lg:inline-block lg:mt-0 mr-4">
                  <img src={images.downloads.apple.default} class="w-auto h-10 object-contain" alt="Apple Store" />
                </a>
              </div> */}
            </div>
            <div class="md:w-1/2 md:justify-center md:flex md:pt-0 pt-10">
              <img src={images.preview.default} alt="Mobile Phone" />
            </div>
          </div>
        </section>
        <section class="px-10 mt-14 flex pb-10">
          <div class="w-full flex flex-col items-center">
            <div class="w-2/3">
              <p class={headText}>
                {langId.landing.subTitle1}
              </p>
            </div>
            <div class="w-full justify-center flex mt-5">
              <Carousel />
            </div>
          </div>
        </section>
        <section class="px-3 lg:px-0 mt-14 sm:mt-14 flex pb-10 justify-center">
          <img src={images.landing.joined} alt="Komunitas bergabung" />
        </section>
      </div>
      {!isSuccessSaveToken ?
        <div class="bg-red-100 flex flex-col justify-center pt-16 mt-5">
          <p id="downloads" class={`${headText} mb-5 w-1/2 self-center`}>
            Yuk daftar sekarang dan menjadi Connextian
          </p>
          <div class="self-center">
            <button onClick={() => history.push(Register)} className="rounded-md bg-red-500 hover:bg-red-700 text-white text-lg leading-6 font-semibold py-3 px-16 lg:px-20 border border-transparent  focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-red-700 focus:outline-none transition-colors duration-200">
              Daftar
            </button>
          </div>
          <Footer />
        </div>
        :
        <Footer />
      }
    </>
  );
}

export { Landing };