import React, { useState, useEffect } from 'react'
import { Base, Spinner } from '../../components'
import { useDispatch, useSelector } from 'react-redux'
import * as a from '../../redux/actions'
import { Form, Input, DatePicker, TimePicker, Switch } from 'antd'
import Icon from 'react-web-vector-icons'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import Toast from '../../utils/toast'
import gcalendar from '../../utils/gcalendar'

function FormEvent(props) {
    const history = useHistory()
    const [form] = Form.useForm()
    const { isLoading, isSuccess, isFailed, gcal, eventId } = useSelector(state => state.bikinEvent)
    const type = props.location.state?.type || 'add'
    const data = props.location.state?.data || {}
    const [img, setImg] = useState({ url: type === 'add' ? '' : data?.featuredimage[0]?.image && data?.featuredimage[0]?.image.split('uploads')[1] ? data?.featuredimage[0]?.image : '' })
    const [free, setFree] = useState(type === 'add' ? false : !data?.ticket_price || data?.ticket_price === "undefined")
    const [field, setField] = useState({})
    const dispatch = useDispatch()
    const style = {
        label: 'text-sm font-semibold mb-1 text-gray-600',
        input: 'outline-none text-sm w-full py-2 px-3 mb-3 border rounded-md'
    }

    const handlePickImage = (e) => {
        e.preventDefault()
        const file = e.target.files[0]
        setImg({ url: URL.createObjectURL(file), file })
    }

    const convert = (str) => {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }

    const onCreate = (values, val) => {
        if (img?.url) {
            setField(values)
            const tanggal = convert(values.tanggal_event)
            const waktuStart = moment(values.waktu_mulai).format("HH:mm")
            const body = new FormData()
            body.append("subject", values.post_title)
            body.append("content", values.post_content)
            body.append("tanggal", tanggal)
            body.append("lokasi", values.lokasi)
            body.append("waktu", waktuStart)
            body.append("file[]", img?.file?.name ? img?.file : null)
            body.append("ticket_price", free ? '' : values.ticket_price)
            body.append("payment_info", free ? '' : values.payment_info)

            if (type === 'add' && !val) dispatch(a.addEvent(body))
            else {
                body.append("id", data.id || eventId)
                body.append("calendar_id", data?.calendar_id || values.calendar_id || '')
                body.append("cal_ios", data?.cal_ios || '')
                body.append("cal_android", data?.cal_android || '')
                body.append("guest", data?.guest || '')
                dispatch(a.updateEvent({ body, gcal: val }))
            }
        } else Toast.error(`Gambar event tidak boleh kosong.`)
    }

    useEffect(() => {
        if (isSuccess) {
            if (field?.post_title) {
                Toast.info(`Berhasil ${type === 'add' ? 'tambah' : 'update'} event. ${type === 'add' ? 'Menambahkan' : 'Memperbarui'} event ke Google Calendar...`)
                setTimeout(() => {
                    gcalendar({
                        type,
                        data: { ...field, calendar_id: data?.calendar_id },
                        handleEvent: (e) => !data?.calendar_id ? onCreate({ ...field, calendar_id: e.id }, true) : history.goBack()
                    })
                }, 2000)
            }
        }
        if (isFailed) Toast.error(`Gagal ${type === 'add' ? 'tambah' : 'update'} event`)
        if (gcal) history.goBack()
    }, [isSuccess, isFailed, gcal])

    return (
        <Base
            primary
            rightIcon={() => null}
            main={
                <div class="px-3">
                    <button className="flex items-center mb-5" onClick={() => history.goBack()}>
                        <Icon
                            name='left'
                            font='AntDesign'
                            color='#4B5563'
                            size={18}
                        />
                        <p className="ml-3 text-lg font-semibold">{type === 'update' ? 'Perbarui' : 'Tambah'} Event</p>
                    </button>
                    <Form
                        onFinish={() =>
                            form
                                .validateFields()
                                .then((values) => {
                                    //   form.resetFields()
                                    onCreate(values)
                                })
                                .catch((info) => {
                                    console.log('Validate Failed:', info)
                                })
                        }
                        form={form}
                        layout="vertical"
                        name="form_in_modal"
                        initialValues={{
                            ...data,
                            tanggal_event: type === 'update' && moment(convert(data.tanggal_event)),
                            waktu_mulai: type === 'update' && moment(data.waktu_mulai, 'HH:mm:ss')
                        }}
                    >
                        <Form.Item name="file">
                            <Input type="file" id="upload" hidden onChange={handlePickImage} accept="image/*" />
                            <div class="w-full flex flex-col items-center relative">
                                <img src={img?.url || "https://heduparts.com/uploads/placeholder.png"} alt="" class="bg-gray-200 w-2/3" />
                                <div className="w-2/3 flex items-center justify-between absolute bottom-0">
                                    {img?.url ?
                                        <button
                                            onClick={e => {
                                                e.preventDefault()
                                                setImg({ url: "" })
                                            }}
                                            class="bg-white text-white h-8 w-8 font-bold border rounded-tr-md">
                                            <Icon
                                                name='delete'
                                                font='AntDesign'
                                                color='#EF4444'
                                                size={16}
                                            />
                                        </button>
                                        :
                                        <div className="w-8" />
                                    }
                                    <label
                                        for="upload"
                                        class="bg-white text-white h-8 w-8 font-bold border ml-2 rounded-tl-md flex items-center justify-center">
                                        <Icon
                                            name='upload'
                                            font='AntDesign'
                                            color='#EF4444'
                                            size={16}
                                        />
                                    </label>
                                </div>
                            </div>
                        </Form.Item>
                        <p className={style.label}>Judul</p>
                        <Form.Item
                            name="post_title"
                            rules={[
                                {
                                    required: true,
                                    message: 'Masukkan judul event!',
                                },
                            ]}
                        >
                            <Input placeholder="Judul" />
                        </Form.Item>
                        <p className={style.label}>Deskripsi</p>
                        <Form.Item
                            name="post_content"
                            rules={[
                                {
                                    required: true,
                                    message: 'Masukkan deskripsi event!',
                                },
                            ]}
                        >
                            <Input.TextArea placeholder="Deskripsi" type="textarea" />
                        </Form.Item>
                        <p className={style.label}>Lokasi</p>
                        <Form.Item
                            name="lokasi"
                            rules={[
                                {
                                    required: true,
                                    message: 'Masukkan lokasi event!',
                                },
                            ]}
                        >
                            <Input placeholder="Lokasi" />
                        </Form.Item>
                        <div class="flex flex-row items-center">
                            <div class="w-1/3">
                                <p className={style.label}>Tanggal</p>
                                <Form.Item
                                    style={{ marginRight: 10 }}
                                    name="tanggal_event"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Masukkan tanggal event!',
                                        },
                                    ]}
                                >
                                    <DatePicker placeholder="Tanggal" />
                                </Form.Item>
                            </div>
                            <div class="w-1/3">
                                <p className={style.label}>Jam Dimulai</p>
                                <Form.Item
                                    style={{ marginRight: 10 }}
                                    name="waktu_mulai"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Masukkan jam dimulai nya event!',
                                        },
                                    ]}
                                >
                                    <TimePicker format="HH:mm" placeholder="Start" />
                                </Form.Item>
                            </div>
                        </div>

                        <Form.Item>
                            <Switch checked={free} onChange={setFree} /> Event Gratis
                        </Form.Item>

                        <p className={style.label}>Harga</p>
                        <Form.Item name="ticket_price">
                            <Input disabled={free} placeholder="Harga" />
                        </Form.Item>
                        <p className={style.label}>Info Rekening</p>
                        <Form.Item name="payment_info">
                            <Input disabled={free} placeholder="Contoh: BCA 123456 a/n Rudi" />
                        </Form.Item>

                        <div className="flex justify-center mb-14 lg:mb-0">
                            <button disabled={isLoading} className="bg-red-500 px-14 py-2.5 rounded-md text-white text-base font-bold mt-2 mb-14 self-center">
                                {isLoading ?
                                    <Spinner size={6} top={0} /> :
                                    type === 'add' ? "Bikin Event" : "Update Event"
                                }
                            </button>
                        </div>
                    </Form>
                </div>
            }
            right={<div />}
        />
    )
}

export { FormEvent }
