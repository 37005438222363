import React from 'react'
import { useHistory } from 'react-router-dom'
import Icon from 'react-web-vector-icons'
import { Menu } from '@headlessui/react'
import { bottomBar, bottomBarSecondary } from '../../Dao'
import { classNamesMenu, DropDown } from '../DropDown'
import { useSelector } from 'react-redux'

function Bottom() {
    const { count } = useSelector(state => state.chat)
    const history = useHistory()
    const active_btn = (item) => window.location.pathname == item.path
    return (
        <div class="fixed w-full sm:w-1/4 md:block lg:hidden z-50">
            <section id="bottom-navigation" class="block fixed inset-x-0 bottom-0 bg-white shadow">
                <div id="tabs" class="flex justify-between">
                    {bottomBar.map(item => (
                        <button onClick={() => history.push(item.path)} class={`w-full ${active_btn(item) ? 'text-red-500' : 'text-gray-600'} focus:text-gray-600 hover:text-red-500 justify-center inline-block text-center pt-2 pb-1`}>
                            <div className="relative w-24">
                                <Icon
                                    name={item.icon}
                                    font='AntDesign'
                                    color={`${active_btn(item) ? '#EF4444' : '#4B5563'}`}
                                    size={24}
                                />
                                {count.all !== 0 && item.label === 'Message' &&
                                    <div className={`bg-red-500 rounded-full text-xs text-white ml-1 absolute top-0.5 right-7 h-4 ${count.all.toString().length < 3 ? 'w-4' : 'w-5'}`}>
                                        {count.all}
                                    </div>
                                }
                                <span class="tab tab-home block text-xs ">{item.label}</span>
                            </div>
                        </button>
                    ))}
                    <DropDown
                        bottom
                        style="bottom-20 w-48 right-5"
                        defaultStyle="w-full text-gray-600 focus:text-gray-600 hover:text-red-500 justify-center inline-block text-center pt-2 pb-1"
                        title={
                            <div>
                                <Icon
                                    name='bars'
                                    font='AntDesign'
                                    color='#4B5563'
                                    size={24}
                                />
                                <span class="tab tab-home block text-xs">More</span>
                            </div>
                        }
                        renderItem={
                            bottomBarSecondary.map(item => (
                                <Menu.Item>
                                    {({ active }) => (
                                        <button
                                            onClick={() => history.push({ pathname: item.path, state: { fromSide: true } })}
                                            className={classNamesMenu(
                                                active ? 'bg-red-100 text-gray-900' : 'text-gray-700',
                                                'block w-full text-left p-4 text-sm items-center flex'
                                            )}
                                        >
                                            <Icon
                                                name={item.icon}
                                                font='AntDesign'
                                                color='#4B5563'
                                                size={24}
                                            />
                                            <span className="ml-2">
                                                {item.label}
                                            </span>
                                        </button>
                                    )}
                                </Menu.Item>
                            ))
                        }
                    />
                </div>
            </section>
        </div>
    )
}

export { Bottom }
