import { takeEvery, put, call } from 'redux-saga/effects'
import * as t from '../const.config'
import Axios from '../../utils/server/baseURL'
import qs from 'qs'

export const Points = [
    takeEvery(t.ADD_PONT_START, workerAddPoint),
]

const addPoint = async params => {
    try {
        const data = await Axios({
            url: `/api/custom_service/?service=addpoint&content_id=${params.id}&type=${params.type}`,
            data: qs.stringify({
                ACCESS_TOKEN: params.token
            })
        })
        return data
    } catch (error) {
        return error
    }
}

function* workerAddPoint(action) {
    try {
        const response = yield call(addPoint, action.payload)
        if (response.data.respond === 1) {
            yield put({
                type: t.ADD_PONT_SUCCESS,
                point: response.data.result
            })
        } else {
            throw response
        }
    } catch (error) {
        yield put({
            type: t.ADD_PONT_FAILED
        })
    }
}