import React, { useState, useEffect } from 'react'
import { Spinner, ActionPost, Base, CommentSection } from '../../components'
import * as a from '../../redux/actions'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'

function PhotoDetail(props) {
    const dispatch = useDispatch()
    const history = useHistory()
    const params = useParams()
    const { listComment, commentLoading } = useSelector(state => state.feedCommunity)
    const { detailPhoto, isLoading } = useSelector(state => state.photoCommunity)
    const data = detailPhoto

    const fetchData = (loading) => {
        dispatch(a.getPhotoCommunity({ id: params?.id, idPhoto: params?.idContent, type: 'byId', loading }))
    }

    const doLike = async (id, type) => {
        if (!isLoading) {
            await dispatch(a.doLikePost({ id, type, loading: false }))
            fetchData(false)
        }
    }

    useEffect(() => { 
        fetchData()
        fetchComment(null, true,0, true)
    }, [])

    const fetchComment = async (id,loading, callpage = 0, reset,) => {
        await dispatch(a.doResetListComment())
        await dispatch(a.getCommentPost({
            id:params?.idContent,
            loading, next: callpage, reset
        }))
    }
    
    const showComment = (id) => {
        fetchComment(params?.idContent,true,0,false)
    }

    return (
        <Base
            hideSearch
            main={
                isLoading ?
                    <Spinner size={12} top={3} /> :
                    <div className="w-full flex flex-col mb-20 lg:justify-between pt-0 lg:pt-10 ">
                        <div className="flex flex-col items-center">
                            <img src={data?.image || ''} className="w-full object-contain" />
                            <p className="text-lg font-semibold mt-5">{data?.album_title}</p>
                            <p className="text-base font-light">{data?.album_content}</p>
                        </div>
                        <ActionPost
                            like={data?.liked_count}
                            comment={data?.comment_count}
                            onClickLike={() => doLike(data?.ID, data?.is_liked ? "unlike" : "like")}
                            isLiked={data?.is_liked}
                            onClickComment={() => showComment(data?.id)}
                            style="mt-5 py-2"
                        />
                        {commentLoading?
                                    <div className="flex items-center justify-center m-2">
                                        <Spinner size={12} />    
                                    </div>
                                :
                                <CommentSection 
                                    id={params?.idContent}
                                    dataComment={listComment} 
                                />
                            }
                    </div>
            }
            right={<></>}
        />
    )
}

export { PhotoDetail }
