import React from 'react'
import './Carousel.css';
import { images } from '../../assets';

const data = [
    {
        id: 0,
        image: images.landing.askMentor,
        label: 'Ask Mentor'
    },
    {
        id: 2,
        image: images.landing.community,
        label: 'Community'
    },
    {
        id: 3,
        image: images.landing.articles,
        label: 'Articles'
    },
    {
        id: 4,
        image: images.landing.podcasts,
        label: 'Podcasts'
    },
]
const Carousel = () => {
    const [index, setIndex] = React.useState(0)
    // We are using react ref to 'tag' each of the images. Below will create an array of
    // objects with numbered keys. We will use those numbers (i) later to access a ref of a
    // specific image in this array.
    const refs = data.reduce((acc, val, i) => {
        acc[i] = React.createRef();
        return acc;
    }, {});

    const scrollToImage = i => {
        setIndex(i)
        // Now, this is where the magic happens. We 'tagged' each one of the images with a ref,
        // we can then use built-in scrollIntoView API to do eaxactly what it says on the box - scroll it into
        // your current view! To do so we pass an index of the image, which is then use to identify our current
        // image's ref in 'refs' array above.
        refs[i].current.scrollIntoView({
            //     Defines the transition animation.
            behavior: 'smooth',
            //      Defines vertical alignment.
            block: 'nearest',
            //      Defines horizontal alignment.
            inline: 'start',
        });
    };

    return (
        <div class="flex w-full flex-col">
            <div className="carousel">
                {data.map((x, i) => (
                    <div class="w-full flex-shrink-0" key={x.id} ref={refs[i]}>
                        <img src={x.image} alt={x.label} className="w-full object-contain" />
                    </div>
                ))}
            </div>
            <div className="flex">
                {data.map((x, i) => {
                    const active = i === index
                    return (
                        <button
                            type="button"
                            onClick={() => scrollToImage(i)}
                            className={`flex-1  ${active ? 'btn-active' : ''} mx-4 sm:mx-7 py-2`}
                        >
                            <p className={`text-black-500 ${active ? 'font-bold' : ''} text-xs sm:text-sm md:text-base`}>{x.label}</p>
                        </button>
                    )
                })}
            </div>
        </div >
    );
};

export { Carousel }