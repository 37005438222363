import * as a from '../const.config'

const initialState = {
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    profile: {},
    isSuccessUpPhoto: true,
    isLoadingUpPhoto: false,
    profileChat: {},
    theme: 'light'
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case a.GET_PROFILE_LOADING:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
                isFailed: false,
                isSuccessUpPhoto: false,
                isLoadingUpPhoto: false
            }
        case a.GET_PROFILE_SUCCSESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                [action.state]: action.data
            }
        case a.GET_PROFILE_FAILED:
            return {
                ...state,
                isLoading: false,
                isFailed: true,
            }
        case a.UPDATE_PROFILE_PHOTO_LOADING:
            return {
                ...state,
                isLoadingUpPhoto: true,
                isSuccessUpPhoto: false,
                isFailed: false,
            }
        case a.UPDATE_PROFILE_PHOTO_SUCCSESS:
            return {
                ...state,
                isLoadingUpPhoto: false,
                isSuccessUpPhoto: true,
            }
        case a.UPDATE_PROFILE_PHOTO_FAILED:
            return {
                ...state,
                isLoadingUpPhoto: false,
                isFailed: true,
            }
        case a.UPDATE_PROFILE_LOADING:
            return {
                ...state,
                isLoadingUpPhoto: true,
                isSuccessUpPhoto: false,
                isFailed: false,
            }
        case a.UPDATE_PROFILE_SUCCSESS:
            return {
                ...state,
                isLoadingUpPhoto: false,
                isSuccessUpPhoto: true,
            }
        case a.UPDATE_PROFILE_FAILED:
            return {
                ...state,
                isLoadingUpPhoto: false,
                isFailed: true,
            }
        case a.DO_RESET_PROFILE:
            return {
                ...state,
                [action.payload]: {},
            }
        case a.DO_CHANGE_THEME:
            return {
                ...state,
                theme: state.theme === 'dark' ? 'light' : 'dark',
            }
        default:
            return state;
    }
}

export default reducer