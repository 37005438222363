import React from 'react'
import { useHistory } from 'react-router-dom'
import { Header } from '../../components'
import {Community} from '../../routers/const'

function Page404() {
    const history = useHistory()
    return (
        <div className="w-full container mx-auto">
            <Header landing={true} />
        
            <div class="bg-white overflow-hidden pb-8">
            <div class="border-t border-gray-200 text-center pt-8">
            <h1 class="text-9xl font-bold text-red-400">404</h1>
            <h1 class="text-2xl font-medium py-8">oops! Page not found</h1>
            <p class="text-xl pb-8 px-12 font-medium">Oops! The page you are looking for does not exist. It might have been moved or deleted.</p>
            
            <button onClick={()=> history.push(Community)} class="bg-gradient-to-l from-red-400 to-red-500 hover:from-red-500 hover:to-red-500 text-white font-semibold px-6 py-3 rounded-md mr-6">
                back to Home
            </button>
                        
            </div>
            </div>
        </div>
    )
}

export {Page404}
