const appId = 'gdilab-de-nxvf0g3fhv1'
const baseUri = `https://${appId}.qiscus.com`
const baseUriUploadImage = 'https://sdksample.qiscus.com/api/v2/mobile/upload'
const qiscusSecret = '421515cb5c7d78eb8472009d60e6487a'
const baseUriContact = 'https://dashboard-sample.herokuapp.com/api/contacts?limit=10&page='
const channel = [
    {
        "room_avatar_url": "https://d1edrlpyc25xu0.cloudfront.net/kiwari-prod/image/upload/E2nVru1t25/1507541900-avatar.png",
        "room_channel_id": "YOTJakarta1",
        "room_id": "2475863",
        "room_name": "YOT Jakarta",
        "value": "YOT Jakarta",
        "room_options": "{}",
        "room_type": "group"
    },
    {
        "room_avatar_url": "https://d1edrlpyc25xu0.cloudfront.net/kiwari-prod/image/upload/E2nVru1t25/1507541900-avatar.png",
        "room_channel_id": "YOTBekasi1",
        "room_id": "2475923",
        "room_name": "YOT Bekasi",
        "value": "YOT Bekasi",
        "room_options": "{}",
        "room_type": "group"
    },
    {
        "room_avatar_url": "https://d1edrlpyc25xu0.cloudfront.net/kiwari-prod/image/upload/E2nVru1t25/1507541900-avatar.png",
        "room_channel_id": "YOTSurabaya1",
        "room_id": "2475982",
        "room_name": "YOT Surabaya",
        "value": "YOT Surabaya",
        "room_options": "{}",
        "room_type": "group"
    },
    {
        "room_avatar_url": "https://d1edrlpyc25xu0.cloudfront.net/kiwari-prod/image/upload/E2nVru1t25/1507541900-avatar.png",
        "room_channel_id": "YOTBanyuwangi1",
        "room_id": "2475998",
        "room_name": "YOT Banyuwangi",
        "value": "YOT Banyuwangi",
        "room_options": "{}",
        "room_type": "group"
    },
    {
        "room_avatar_url": "https://d1edrlpyc25xu0.cloudfront.net/kiwari-prod/image/upload/E2nVru1t25/1507541900-avatar.png",
        "room_channel_id": "YOTYogyakarta1",
        "room_id": "2476012",
        "room_name": "YOT Yogyakarta",
        "value": "YOT Yogyakarta",
        "room_options": "{}",
        "room_type": "group"
    },
    {
        "room_avatar_url": "https://d1edrlpyc25xu0.cloudfront.net/kiwari-prod/image/upload/E2nVru1t25/1507541900-avatar.png",
        "room_channel_id": "YOTPalembang1",
        "room_id": "2476036",
        "room_name": "YOT Palembang",
        "value": "YOT Palembang",
        "room_options": "{}",
        "room_type": "group"
    },
    {
        "room_avatar_url": "https://d1edrlpyc25xu0.cloudfront.net/kiwari-prod/image/upload/E2nVru1t25/1507541900-avatar.png",
        "room_channel_id": "YOTBalikpapan1",
        "room_id": "2476083",
        "room_name": "YOT Balikpapan",
        "value": "YOT Balikpapan",
        "room_options": "{}",
        "room_type": "group"
    },
    {
        "room_avatar_url": "https://d1edrlpyc25xu0.cloudfront.net/kiwari-prod/image/upload/E2nVru1t25/1507541900-avatar.png",
        "room_channel_id": "YOTPekanbaru1",
        "room_id": "2476095",
        "room_name": "YOT Pekanbaru",
        "value": "YOT Pekanbaru",
        "room_options": "{}",
        "room_type": "group"
    },
    {
        "room_avatar_url": "https://d1edrlpyc25xu0.cloudfront.net/kiwari-prod/image/upload/E2nVru1t25/1507541900-avatar.png",
        "room_channel_id": "YOTTangerang1",
        "room_id": "2476102",
        "room_name": "YOT Tangerang",
        "value": "YOT Tangerang",
        "room_options": "{}",
        "room_type": "group"
    },
    {
        "room_avatar_url": "https://d1edrlpyc25xu0.cloudfront.net/kiwari-prod/image/upload/E2nVru1t25/1507541900-avatar.png",
        "room_channel_id": "YOTBogor1",
        "room_id": "2476122",
        "room_name": "YOT Bogor",
        "value": "YOT Bogor",
        "room_options": "{}",
        "room_type": "group"
    },
    {
        "room_avatar_url": "https://d1edrlpyc25xu0.cloudfront.net/kiwari-prod/image/upload/E2nVru1t25/1507541900-avatar.png",
        "room_channel_id": "YOTMalang1",
        "room_id": "2476249",
        "room_name": "YOT Malang",
        "value": "YOT Malang",
        "room_options": "{}",
        "room_type": "group"
    },
    {
        "room_avatar_url": "https://d1edrlpyc25xu0.cloudfront.net/kiwari-prod/image/upload/E2nVru1t25/1507541900-avatar.png",
        "room_channel_id": "YOTBengkulu1",
        "room_id": "2476262",
        "room_name": "YOT Bengkulu",
        "value": "YOT Bengkulu",
        "room_options": "{}",
        "room_type": "group"
    },
    {
        "room_avatar_url": "https://d1edrlpyc25xu0.cloudfront.net/kiwari-prod/image/upload/E2nVru1t25/1507541900-avatar.png",
        "room_channel_id": "YOTSemarang1",
        "room_id": "2476280",
        "room_name": "YOT Semarang",
        "value": "YOT Semarang",
        "room_options": "{}",
        "room_type": "group"
    },
    {
        "room_avatar_url": "https://d1edrlpyc25xu0.cloudfront.net/kiwari-prod/image/upload/E2nVru1t25/1507541900-avatar.png",
        "room_channel_id": "YOTMakassar1",
        "room_id": "2476286",
        "room_name": "YOT Makassar",
        "value": "YOT Makassar",
        "room_options": "{}",
        "room_type": "group"
    },
    {
        "room_avatar_url": "https://d1edrlpyc25xu0.cloudfront.net/kiwari-prod/image/upload/E2nVru1t25/1507541900-avatar.png",
        "room_channel_id": "YOTSamarinda1",
        "room_id": "2476297",
        "room_name": "YOT Samarinda",
        "value": "YOT Samarinda",
        "room_options": "{}",
        "room_type": "group"
    },
    {
        "room_avatar_url": "https://d1edrlpyc25xu0.cloudfront.net/kiwari-prod/image/upload/E2nVru1t25/1507541900-avatar.png",
        "room_channel_id": "YOTPadang1",
        "room_id": "2476307",
        "room_name": "YOT Padang",
        "value": "YOT Padang",
        "room_options": "{}",
        "room_type": "group"
    },
    {
        "room_avatar_url": "https://d1edrlpyc25xu0.cloudfront.net/kiwari-prod/image/upload/E2nVru1t25/1507541900-avatar.png",
        "room_channel_id": "YOTDepok1",
        "room_id": "2476312",
        "room_name": "YOT Depok",
        "value": "YOT Depok",
        "room_options": "{}",
        "room_type": "group"
    },
    {
        "room_avatar_url": "https://d1edrlpyc25xu0.cloudfront.net/kiwari-prod/image/upload/E2nVru1t25/1507541900-avatar.png",
        "room_channel_id": "YOTBandung1",
        "room_id": "2476327",
        "room_name": "YOT Bandung",
        "value": "YOT Bandung",
        "room_options": "{}",
        "room_type": "group"
    },
    {
        "room_avatar_url": "https://d1edrlpyc25xu0.cloudfront.net/kiwari-prod/image/upload/E2nVru1t25/1507541900-avatar.png",
        "room_channel_id": "YOTJember1",
        "room_id": "2476329",
        "room_name": "YOT Jember",
        "value": "YOT Jember",
        "room_options": "{}",
        "room_type": "group"
    },
    {
        "room_avatar_url": "https://d1edrlpyc25xu0.cloudfront.net/kiwari-prod/image/upload/E2nVru1t25/1507541900-avatar.png",
        "room_channel_id": "YOTSolo1",
        "room_id": "2476342",
        "room_name": "YOT Solo",
        "value": "YOT Solo",
        "room_options": "{}",
        "room_type": "group"
    },
    {
        "room_avatar_url": "https://d1edrlpyc25xu0.cloudfront.net/kiwari-prod/image/upload/E2nVru1t25/1507541900-avatar.png",
        "room_channel_id": "YOTMedan1",
        "room_id": "2476347",
        "room_name": "YOT Medan",
        "value": "YOT Medan",
        "room_options": "{}",
        "room_type": "group"
    },
    {
        "room_avatar_url": "https://d1edrlpyc25xu0.cloudfront.net/kiwari-prod/image/upload/E2nVru1t25/1507541900-avatar.png",
        "room_channel_id": "YOTBali1",
        "room_id": "2476356",
        "room_name": "YOT Bali",
        "value": "YOT Bali",
        "room_options": "{}",
        "room_type": "group"
    },
    {
        "room_avatar_url": "https://d1edrlpyc25xu0.cloudfront.net/kiwari-prod/image/upload/E2nVru1t25/1507541900-avatar.png",
        "room_channel_id": "YOTBanjarmasin1",
        "room_id": "2476363",
        "room_name": "YOT Banjarmasin",
        "value": "YOT Banjarmasin",
        "room_options": "{}",
        "room_type": "group"
    },
    {
        "room_avatar_url": "https://d1edrlpyc25xu0.cloudfront.net/kiwari-prod/image/upload/E2nVru1t25/1507541900-avatar.png",
        "room_channel_id": "YOTPurwokerto1",
        "room_id": "2476379",
        "room_name": "YOT Purwokerto",
        "value": "YOT Purwokerto",
        "room_options": "{}",
        "room_type": "group"
    },
    {
        "room_avatar_url": "https://d1edrlpyc25xu0.cloudfront.net/kiwari-prod/image/upload/E2nVru1t25/1507541900-avatar.png",
        "room_channel_id": "Bizhare1",
        "room_name": "Investasi bareng Bizhare",
    },
    {
        "room_avatar_url": "https://d1edrlpyc25xu0.cloudfront.net/kiwari-prod/image/upload/E2nVru1t25/1507541900-avatar.png",
        "room_channel_id": "Maingame1",
        "room_name": "Gamers Indonesia",
    },
    {
        "room_avatar_url": "https://d1edrlpyc25xu0.cloudfront.net/kiwari-prod/image/upload/E2nVru1t25/1507541900-avatar.png",
        "room_channel_id": "Hipwee1",
        "room_name": "Sohib Hipwee",
    },
    {
        "room_avatar_url": "https://d1edrlpyc25xu0.cloudfront.net/kiwari-prod/image/upload/E2nVru1t25/1507541900-avatar.png",
        "room_channel_id": "YOTKota1",
        "room_name": "YOT Kota",
    },
]

const channel2 = [
    {
        "room_avatar_url": "https://d1edrlpyc25xu0.cloudfront.net/gdilab-de-nxvf0g3fhv1/image/upload/UMShlsfK0e/loker.png",
        "room_channel_id": "InfoLowonganKerja",
        "room_name": "Info Lowongan Kerja",
    },
    {
        "room_avatar_url": "https://d1edrlpyc25xu0.cloudfront.net/gdilab-de-nxvf0g3fhv1/image/upload/ZONzrGcOqb/beasiswa.png",
        "room_channel_id": "InfoBootcampdanBeasiswa",
        "room_name": "Bootcamp dan Beasiswa",
    },
    {
        "room_avatar_url": "https://d1edrlpyc25xu0.cloudfront.net/kiwari-prod/image/upload/E2nVru1t25/1507541900-avatar.png",
        "room_channel_id": "UMKMLab",
        "room_name": "Coaching by UMKMLab",
    },
]

export {
    appId,
    baseUri,
    baseUriUploadImage,
    qiscusSecret,
    baseUriContact,
    channel,
    channel2
}