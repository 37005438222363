import { takeEvery, put } from 'redux-saga/effects'
import * as t from '../../const.config'
import * as a from '../../../config/api'

export const EventCommunity = [
    takeEvery(t.GET_EVENT_COMMUNITY, workerGetEventCommunity),
    takeEvery(t.DO_REGISTER_EVENT, workerDoRegisterEvent),
]

function* workerGetEventCommunity(action) {
    const { id, idEvent, type, keyword, page, reset = true, typeEvent, loading, typeList } = action.payload
    yield put({ type: t.GET_EVENT_COMMUNITY_START, reset: type === "byId" ? false : reset, loading: loading !== undefined ? loading : true })
    try {
        const body = {
            community_id: id,
            event_id: type === "byId" ? idEvent : null,
            search: keyword,
            callpage: page,
            perpage: 10,
            event_type: typeEvent,
            type: typeList
        }
        const data = yield a.getDataFromApi(body, a.getEventApi, 'page')
        const state = type === "byId" ? "detailEvent" : "listEvent"
        const newData = type === "byId" ? data.data[0] : data.data
        const pages = data.page
        const isLoadMore = page > 1

        yield put({ type: t.GET_EVENT_COMMUNITY_SUCCESS, data: newData, state, isLoadMore, pages })
    } catch (error) {
        yield put({ type: t.GET_EVENT_COMMUNITY_FAILED })
    }
}

function* workerDoRegisterEvent(action) {
    const body = action.payload
    yield put({ type: t.DO_REGISTER_EVENT_START })
    try {
        const data = yield a.getDataFromApi(body, a.doRegisterEventApi, 'msg', 'form')

        yield put({ type: t.DO_REGISTER_EVENT_SUCCESS, data })
    } catch (error) {
        yield put({ type: t.DO_REGISTER_EVENT_FAILED })
    }
}