// Auth type const
export const SIGN_IN_LOADING = 'SIGN_IN_LOADING'
export const SIGN_IN_SUCCSESS = 'SIGN_IN_SUCCSESS'
export const SIGN_IN_FAILURE = 'SIGN_IN_FAILURE'

export const SIGN_PHONE_LOADING = 'SIGN_PHONE_LOADING'
export const SIGN_PHONE_SUCCSESS = 'SIGN_PHONE_SUCCSESS'
export const SIGN_PHONE_FAILURE = 'SIGN_PHONE_FAILURE'

export const SIGN_OTP_LOADING = 'SIGN_OTP_LOADING'
export const SIGN_OTP_SUCCSESS = 'SIGN_OTP_SUCCSESS'
export const SIGN_OTP_FAILURE = 'SIGN_OTP_FAILURE'

export const SIGN_OUT_LOADING = 'SIGN_OUT_LOADING'
export const SIGN_OUT_SUCCSESS = 'SIGN_OUT_SUCCSESS'
export const SIGN_OUT_FAILURE = 'SIGN_OUT_FAILURE'

export const FORGET_PASSWORD_LOADING = 'FORGET_PASSWORD_LOADING'
export const FORGET_PASSWORD_SUCCSESS = 'FORGET_PASSWORD_SUCCSESS'
export const FORGET_PASSWORD_FAILURE = 'FORGET_PASSWORD_FAILURE'

export const SECRET_CODE_LOADING = 'SECRET_CODE_LOADING'
export const SECRET_CODE_SUCCSESS = 'SECRET_CODE_SUCCSESS'
export const SECRET_CODE_FAILURE = 'SECRET_CODE_FAILURE'

export const CLEAR_ACTION = 'hapus aksi'

export const SIGN_UP_LOADING = 'SIGN_UP_LOADING'
export const SIGN_UP_SUCCSESS = 'SIGN_UP_SUCCSESS'
export const SIGN_UP_FAILURE = 'SIGN_UP_FAILURE'

export const VERIFY_PHONE_LOADING = 'VERIFY_PHONE_LOADING'
export const VERIFY_PHONE_SUCCSESS = 'VERIFY_PHONE_SUCCSESS'
export const VERIFY_PHONE_FAILURE = 'VERIFY_PHONE_FAILURE'

export const CHANGE_PASSWORD_LOADING = 'CHANGE_PASSWORD_LOADING'
export const CHANGE_PASSWORD_SUCCSESS = 'CHANGE_PASSWORD_SUCCSESS'
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE'

export const DO_SAVE_DEVICE_TOKEN = 'DO_SAVE_DEVICE_TOKEN'
export const DO_SAVE_DEVICE_TOKEN_LOADING = 'DO_SAVE_DEVICE_TOKEN_LOADING'
export const DO_SAVE_DEVICE_TOKEN_SUCCESS = 'DO_SAVE_DEVICE_TOKEN_SUCCESS'
export const DO_SAVE_DEVICE_TOKEN_FAILED = 'DO_SAVE_DEVICE_TOKEN_FAILED'

export const DO_RESET_REGISTER_STATUS = 'DO_RESET_REGISTER_STATUS'

export const DO_CHECK_TOKEN = 'DO_CHECK_TOKEN'
export const DO_CHECK_TOKEN_LOADING = 'DO_CHECK_TOKEN_LOADING'
export const DO_CHECK_TOKEN_SUCCESS = 'DO_CHECK_TOKEN_SUCCESS'
export const DO_CHECK_TOKEN_FAILED = 'DO_CHECK_TOKEN_FAILED'

export const DO_RESET_LOGIN_STATUS = 'DO_RESET_LOGIN_STATUS'

export const DO_SET_STATE_AUTH = 'DO_SET_STATE_AUTH'

// Feed
export const MESSAGE_GLOBAL_LOADING = 'MESSAGE_GLOBAL_LOADING'
export const MESSAGE_GLOBAL_SUCCESS = 'MESSAGE_GLOBAL_SUCCESS'
export const MESSAGE_GLOBAL_FAILED = 'MESSAGE_GLOBAL_FAILED'

export const BANNER_START = 'BANNER_START'
export const BANNER_LOADING = 'BANNER_LOADING'
export const BANNER_SUCCESS = 'BANNER_SUCCESS'
export const BANNER_FAILED = 'BANNER_FAILED'

export const GET_POST = 'GET_POST'
export const GET_POST_START = 'GET_POST_START'
export const GET_POST_SUCCESS = 'GET_POST_SUCCESS'
export const GET_POST_FAILED = 'GET_POST_FAILED'

export const GET_CATEGORY = 'GET_CATEGORY'
export const GET_CATEGORY_START = 'GET_CATEGORY_START'
export const GET_CATEGORY_SUCCESS = 'GET_CATEGORY_SUCCESS'
export const GET_CATEGORY_FAILED = 'GET_CATEGORY_FAILED'

export const GET_PROVINCE = 'GET_PROVINCE'
export const GET_PROVINCE_START = 'GET_PROVINCE_START'
export const GET_PROVINCE_SUCCESS = 'GET_PROVINCE_SUCCESS'
export const GET_PROVINCE_FAILED = 'GET_PROVINCE_FAILED'

export const GET_CITY = 'GET_CITY'
export const GET_CITY_START = 'GET_CITY_START'
export const GET_CITY_SUCCESS = 'GET_CITY_SUCCESS'
export const GET_CITY_FAILED = 'GET_CITY_FAILED'

export const DO_FOLLOW = 'DO_FOLLOW'
export const DO_FOLLOW_START = 'DO_FOLLOW_START'
export const DO_FOLLOW_SUCCESS = 'DO_FOLLOW_SUCCESS'
export const DO_FOLLOW_FAILED = 'DO_FOLLOW_FAILED'

export const DO_LIKE = 'DO_LIKE'
export const DO_LIKE_START = 'DO_LIKE_START'
export const DO_LIKE_SUCCESS = 'DO_LIKE_SUCCESS'
export const DO_LIKE_FAILED = 'DO_LIKE_FAILED'

export const GET_COMMENT = 'GET_COMMENT'
export const GET_COMMENT_START = 'GET_COMMENT_START'
export const GET_COMMENT_SUCCESS = 'GET_COMMENT_SUCCESS'
export const GET_COMMENT_FAILED = 'GET_COMMENT_FAILED'

export const DO_COMMENT = 'DO_COMMENT'
export const DO_COMMENT_START = 'DO_COMMENT_START'
export const DO_COMMENT_SUCCESS = 'DO_COMMENT_SUCCESS'
export const DO_COMMENT_FAILED = 'DO_COMMENT_FAILED'

export const DO_RESET_LIST_COMMENT = 'DO_RESET_LIST_COMMENT'

export const GET_PROFILE_COMMUNITY = 'GET_PROFILE_COMMUNITY'
export const GET_PROFILE_COMMUNITY_START = 'GET_PROFILE_COMMUNITY_START'
export const GET_PROFILE_COMMUNITY_SUCCESS = 'GET_PROFILE_COMMUNITY_SUCCESS'
export const GET_PROFILE_COMMUNITY_FAILED = 'GET_PROFILE_COMMUNITY_FAILED'

export const GET_COMMUNITY_LIST = 'GET_COMMUNITY_LIST'
export const GET_COMMUNITY_LIST_START = 'GET_COMMUNITY_LIST_START'
export const GET_COMMUNITY_LIST_SUCCESS = 'GET_COMMUNITY_LIST_SUCCESS'
export const GET_COMMUNITY_LIST_FAILED = 'GET_COMMUNITY_LIST_FAILED'

export const DO_RESET_COMMUNITY_LIST = 'DO_RESET_COMMUNITY_LIST'

// Event
export const GET_EVENT_COMMUNITY = 'GET_EVENT_COMMUNITY'
export const GET_EVENT_COMMUNITY_START = 'GET_EVENT_COMMUNITY_START'
export const GET_EVENT_COMMUNITY_SUCCESS = 'GET_EVENT_COMMUNITY_SUCCESS'
export const GET_EVENT_COMMUNITY_FAILED = 'GET_EVENT_COMMUNITY_FAILED'

export const DO_REGISTER_EVENT = 'DO_REGISTER_EVENT'
export const DO_REGISTER_EVENT_START = 'DO_REGISTER_EVENT_START'
export const DO_REGISTER_EVENT_SUCCESS = 'DO_REGISTER_EVENT_SUCCESS'
export const DO_REGISTER_EVENT_FAILED = 'DO_REGISTER_EVENT_FAILED'

export const DO_RESET_EVENT_DETAIL = 'DO_RESET_EVENT_DETAIL'

// Article
export const GET_ARTICLE = 'GET_ARTICLE'
export const GET_ARTICLE_START = 'GET_ARTICLE_START'
export const GET_ARTICLE_SUCCESS = 'GET_ARTICLE_SUCCESS'
export const GET_ARTICLE_FAILED = 'GET_ARTICLE_FAILED'

export const DO_RESET_ARTICLE_DETAIL = 'DO_RESET_ARTICLE_DETAIL'

// Member
export const GET_MEMBER = 'GET_MEMBER'
export const GET_MEMBER_START = 'GET_MEMBER_START'
export const GET_MEMBER_SUCCESS = 'GET_MEMBER_SUCCESS'
export const GET_MEMBER_FAILED = 'GET_MEMBER_FAILED'

// Podcast
export const GET_PODCAST = 'GET_PODCAST'
export const GET_PODCAST_START = 'GET_PODCAST_START'
export const GET_PODCAST_SUCCESS = 'GET_PODCAST_SUCCESS'
export const GET_PODCAST_FAILED = 'GET_PODCAST_FAILED'

export const RESET_DETAIL_PODCAST = 'RESET_DETAIL_PODCAST'
export const RESET_LIST_PODCAST = 'RESET_LIST_PODCAST'

// Photos
export const GET_PHOTO = 'GET_PHOTO'
export const GET_PHOTO_START = 'GET_PHOTO_START'
export const GET_PHOTO_SUCCESS = 'GET_PHOTO_SUCCESS'
export const GET_PHOTO_FAILED = 'GET_PHOTO_FAILED'

export const DO_RESET_PHOTO_DETAIL = 'DO_RESET_PHOTO_DETAIL'

// 
export const DO_RESET_ALL_POST_DETAIL = 'DO_RESET_ALL_POST_DETAIL'

export const DO_SET_POPUP_RATING = 'DO_SET_POPUP_RATING'

// Profile
export const UPDATE_PROFILE = 'UPDATE_PROFILE'
export const UPDATE_PROFILE_LOADING = 'UPDATE_PROFILE_LOADING'
export const UPDATE_PROFILE_SUCCSESS = 'UPDATE_PROFILE_SUCCSESS'
export const UPDATE_PROFILE_FAILED = 'UPDATE_PROFILE_FAILED'

export const GET_PROFILE = 'GET_PROFILE'
export const GET_PROFILE_LOADING = 'GET_PROFILE_LOADING'
export const GET_PROFILE_SUCCSESS = 'GET_PROFILE_SUCCSESS'
export const GET_PROFILE_FAILED = 'GET_PROFILE_FAILED'

export const UPDATE_PROFILE_PHOTO = 'UPDATE_PROFILE_PHOTO'
export const UPDATE_PROFILE_PHOTO_LOADING = 'UPDATE_PROFILE_PHOTO_LOADING'
export const UPDATE_PROFILE_PHOTO_SUCCSESS = 'UPDATE_PROFILE_PHOTO_SUCCSESS'
export const UPDATE_PROFILE_PHOTO_FAILED = 'UPDATE_PROFILE_PHOTO_FAILED'

export const DO_CHANGE_THEME = 'DO_CHANGE_THEME'

export const DO_RESET_DATA = 'DO_RESET_DATA'

// Articles
export const HOME_ARTICLES_LOADING = 'HOME_ARTICLES_LOADING'
export const HOME_ARTICLES_SUCCSESS = 'HOME_ARTICLES_SUCCSESS'
export const HOME_ARTICLES_FAILURE = 'HOME_ARTICLES_FAILURE'
export const HOME_ARTICLE_DETAIL_CLEAR = 'HOME_ARTICLE_DETAIL_CLEAR'

export const HOME_ARTICLE_DETAIL_LOADING = 'HOME_ARTICLE_DETAIL_LOADING'
export const HOME_ARTICLE_DETAIL_SUCCSESS = 'HOME_ARTICLE_DETAIL_SUCCSESS'
export const HOME_ARTICLE_DETAIL_FAILURE = 'HOME_ARTICLE_DETAIL_FAILURE'

export const GET_BOOKMARK_LOADING = 'GET_BOOKMARK_LOADING'
export const GET_BOOKMARK_SUCCSESS = 'GET_BOOKMARK_SUCCSESS'
export const GET_BOOKMARK_FAILURE = 'GET_BOOKMARK_FAILURE'

// podcast
export const HOME_PODCAST_LOADING = 'HOME_PODCAST_LOADING'
export const HOME_PODCAST_SUCCSESS = 'HOME_PODCAST_SUCCSESS'
export const HOME_PODCAST_FAILURE = 'HOME_PODCAST_FAILURE'

export const HOME_PODCAST_PLAY_LOADING = 'HOME_PODCAST_PLAY_LOADING'
export const HOME_PODCAST_PLAY_SUCCSESS = 'HOME_PODCAST_PLAY_SUCCSESS'
export const HOME_PODCAST_PLAY_FAILURE = 'HOME_PODCAST_PLAY_FAILURE'

export const HOME_PODCAST_SERIES = 'HOME_PODCAST_SERIES'
export const HOME_PODCAST_SERIES_START = 'HOME_PODCAST_SERIES_LOADING'
export const HOME_PODCAST_SERIES_SUCCSESS = 'HOME_PODCAST_SERIES_SUCCSESS'
export const HOME_PODCAST_SERIES_FAILURE = 'HOME_PODCAST_SERIES_FAILURE'

// askmentor
export const MENTOR_QUESTIONS = 'MENTOR_QUESTIONS'
export const MENTOR_QUESTIONS_LOADING = 'MENTOR_QUESTIONS_LOADING'
export const MENTOR_QUESTIONS_SUCCSESS = 'MENTOR_QUESTIONS_SUCCSESS'
export const MENTOR_QUESTIONS_FAILURE = 'MENTOR_QUESTIONS_FAILURE'

export const MENTOR_MY_QUESTIONS_LOADING = 'MENTOR_MY_QUESTIONS_LOADING'
export const MENTOR_MY_QUESTIONS_SUCCSESS = 'MENTOR_MY_QUESTIONS_SUCCSESS'
export const MENTOR_MY_QUESTIONS_FAILURE = 'MENTOR_MY_QUESTIONS_FAILURE'

export const MENTOR_SUBMIT_ANSWER_LOADING = 'MENTOR_SUBMIT_ANSWER_LOADING'
export const MENTOR_SUBMIT_ANSWER_SUCCSESS = 'MENTOR_SUBMIT_ANSWER_SUCCSESS'
export const MENTOR_SUBMIT_ANSWER_FAILURE = 'MENTOR_SUBMIT_ANSWER_FAILURE'

export const MENTOR_SUBMIT_QUESTION_LOADING = 'MENTOR_SUBMIT_QUESTION_LOADING'
export const MENTOR_SUBMIT_QUESTION_SUCCSESS = 'MENTOR_SUBMIT_QUESTION_SUCCSESS'
export const MENTOR_SUBMIT_QUESTION_FAILURE = 'MENTOR_SUBMIT_QUESTION_FAILURE'

export const MENTOR_QUESTION_DETAIL_LOADING = 'MENTOR_QUESTION_DETAIL_LOADING'
export const MENTOR_QUESTION_DETAIL_SUCCSESS = 'MENTOR_QUESTION_DETAIL_SUCCSESS'
export const MENTOR_QUESTION_DETAIL_FAILURE = 'MENTOR_QUESTION_DETAIL_FAILURE'
export const MENTOR_QUESTION_DETAIL_CLEAR = 'MENTOR_QUESTION_DETAIL_CLEAR'

export const MENTOR_COMMENT_LOADING = 'MENTOR_COMMENT_LOADING'
export const MENTOR_COMMENT_SUCCSESS = 'MENTOR_COMMENT_SUCCSESS'
export const MENTOR_COMMENT_FAILURE = 'MENTOR_COMMENT_FAILURE'

export const MENTOR_GET_LOADING = 'MENTOR_GET_LOADING'
export const MENTOR_GET_SUCCSESS = 'MENTOR_GET_SUCCSESS'
export const MENTOR_GET_FAILURE = 'MENTOR_GET_FAILURE'

export const TOPICS_GET_LOADING = 'TOPICS_GET_LOADING'
export const TOPICS_GET_SUCCSESS = 'TOPICS_GET_SUCCSESS'
export const TOPICS_GET_FAILURE = 'TOPICS_GET_FAILURE'

// Chat
export const SET_CHAT_STATE = 'SET_CHAT_STATE'

export const GET_USER_LIST = 'GET_USER_LIST'
export const GET_USER_LIST_START = 'GET_USER_LIST_START'
export const GET_USER_LIST_SUCCESS = 'GET_USER_LIST_SUCCSESS'
export const GET_USER_LIST_FAILED = 'GET_USER_LIST_FAILED'

export const DO_RESET_USER_LIST = 'DO_RESET_USER_LIST'

export const DO_SET_COUNT_CHAT = 'DO_SET_COUNT_CHAT'

export const GET_ROOM_DETAIL = 'GET_ROOM_DETAIL'
export const GET_ROOM_DETAIL_START = 'GET_ROOM_DETAIL_START'
export const GET_ROOM_DETAIL_SUCCESS = 'GET_ROOM_DETAIL_SUCCESS'
export const GET_ROOM_DETAIL_FAILED = 'GET_ROOM_DETAIL_FAILED'

export const DO_RESET_PROFILE = 'DO_RESET_PROFILE'

export const DO_RESET_ROOM_DETAIL = 'DO_RESET_ROOM_DETAIL'

export const CREATE_GROUP = 'CREATE_GROUP'
export const CREATE_GROUP_START = 'CREATE_GROUP_START'
export const CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS'
export const CREATE_GROUP_FAILED = 'CREATE_GROUP_FAILED'

export const EDIT_DESC = 'EDIT_DESC'
export const EDIT_DESC_START = 'EDIT_DESC_START'
export const EDIT_DESC_SUCCESS = 'EDIT_DESC_SUCCESS'
export const EDIT_DESC_FAILED = 'EDIT_DESC_FAILED'

export const ADD_PARTICIPANTS = 'ADD_PARTICIPANTS'
export const ADD_PARTICIPANTS_START = 'ADD_PARTICIPANTS_START'
export const ADD_PARTICIPANTS_SUCCESS = 'ADD_PARTICIPANTS_SUCCESS'
export const ADD_PARTICIPANTS_FAILED = 'ADD_PARTICIPANTS_FAILED'

export const UPDATE_AVATAR_GROUP = 'UPDATE_AVATAR_GROUP'
export const UPDATE_AVATAR_GROUP_START = 'UPDATE_AVATAR_GROUP_START'
export const UPDATE_AVATAR_GROUP_SUCCESS = 'UPDATE_AVATAR_GROUP_SUCCESS'
export const UPDATE_AVATAR_GROUP_FAILED = 'UPDATE_AVATAR_GROUP_FAILED'

export const DO_MAKE_ADMIN_GROUP = 'DO_MAKE_ADMIN_GROUP'
export const DO_MAKE_ADMIN_GROUP_START = 'DO_MAKE_ADMIN_GROUP_START'
export const DO_MAKE_ADMIN_GROUP_SUCCESS = 'DO_MAKE_ADMIN_GROUP_SUCCESS'
export const DO_MAKE_ADMIN_GROUP_FAILED = 'DO_MAKE_ADMIN_GROUP_FAILED'

export const REMOVE_PARTICIPANTS = 'REMOVE_PARTICIPANTS'
export const REMOVE_PARTICIPANTS_START = 'REMOVE_PARTICIPANTS_START'
export const REMOVE_PARTICIPANTS_SUCCESS = 'REMOVE_PARTICIPANTS_SUCCESS'
export const REMOVE_PARTICIPANTS_FAILED = 'REMOVE_PARTICIPANTS_FAILED'

export const GET_LIST_CHAT_GROUP = 'GET_LIST_CHAT_GROUP'
export const GET_LIST_CHAT_GROUP_START = 'GET_LIST_CHAT_GROUP_START'
export const GET_LIST_CHAT_GROUP_SUCCESS = 'GET_LIST_CHAT_GROUP_SUCCESS'
export const GET_LIST_CHAT_GROUP_FAILED = 'GET_LIST_CHAT_GROUP_FAILED'

export const UPDATE_GROUP_NAME = 'UPDATE_GROUP_NAME'
export const UPDATE_GROUP_NAME_START = 'UPDATE_GROUP_NAME_START'
export const UPDATE_GROUP_NAME_SUCCESS = 'UPDATE_GROUP_NAME_SUCCESS'
export const UPDATE_GROUP_NAME_FAILED = 'UPDATE_GROUP_NAME_FAILED'

// Trivia
export const GET_TRIVIA = 'GET_TRIVIA'
export const GET_TRIVIA_START = 'GET_TRIVIA_START'
export const GET_TRIVIA_SUCCESS = 'GET_TRIVIA_SUCCSESS'
export const GET_TRIVIA_FAILED = 'GET_TRIVIA_FAILED'

export const GET_QA = 'GET_QA'
export const GET_QA_START = 'GET_QA_START'
export const GET_QA_SUCCESS = 'GET_QA_SUCCSESS'
export const GET_QA_FAILED = 'GET_QA_FAILED'

export const GET_RESULT = 'GET_RESULT'
export const GET_RESULT_START = 'GET_RESULT_START'
export const GET_RESULT_SUCCESS = 'GET_RESULT_SUCCSESS'
export const GET_RESULT_FAILED = 'GET_RESULT_FAILED'

export const GET_LEADERBOARD = 'GET_LEADERBOARD'
export const GET_LEADERBOARD_START = 'GET_LEADERBOARD_START'
export const GET_LEADERBOARD_SUCCESS = 'GET_LEADERBOARD_SUCCSESS'
export const GET_LEADERBOARD_FAILED = 'GET_LEADERBOARD_FAILED'

export const DO_RESET_TRIVIA = 'DO_RESET_TRIVIA'

export const DO_SET_USER_STATUS = 'DO_SET_USER_STATUS'

// point
// export const ADD_PONT = "ADD POINT"
export const ADD_PONT_START = "ADD POINT START"
export const ADD_PONT_SUCCESS = "ADD POINT SUCCESS"
export const ADD_PONT_FAILED = "ADD POINT FAILED"

// BikinEvent
export const GET_W_EVENT = 'GET_W_EVENT'
export const GET_W_EVENT_START = 'GET_W_EVENT_START'
export const GET_W_EVENT_SUCCESS = 'GET_W_EVENT_SUCCESS'
export const GET_W_EVENT_FAILED = 'GET_W_EVENT_FAILED'

export const ADD_EVENT = 'ADD_EVENT'
export const ADD_EVENT_START = 'ADD_EVENT_START'
export const ADD_EVENT_SUCCESS = 'ADD_EVENT_SUCCESS'
export const ADD_EVENT_FAILED = 'ADD_EVENT_FAILED'

export const UPDATE_EVENT = 'UPDATE_EVENT'
export const UPDATE_EVENT_START = 'UPDATE_EVENT_START'
export const UPDATE_EVENT_SUCCESS = 'UPDATE_EVENT_SUCCESS'
export const UPDATE_EVENT_FAILED = 'UPDATE_EVENT_FAILED'

export const REMOVE_EVENT = 'REMOVE_EVENT'
export const REMOVE_EVENT_START = 'REMOVE_EVENT_START'
export const REMOVE_EVENT_SUCCESS = 'REMOVE_EVENT_SUCCESS'
export const REMOVE_EVENT_FAILED = 'REMOVE_EVENT_FAILED'

export const GET_LIST_RSVP = 'GET_LIST_RSVP'
export const GET_LIST_RSVP_START = 'GET_LIST_RSVP_START'
export const GET_LIST_RSVP_SUCCESS = 'GET_LIST_RSVP_SUCCESS'
export const GET_LIST_RSVP_FAILED = 'GET_LIST_RSVP_FAILED'

export const DO_APPROVE_RSVP = 'DO_APPROVE_RSVP'
export const DO_APPROVE_RSVP_START = 'DO_APPROVE_RSVP_START'
export const DO_APPROVE_RSVP_SUCCESS = 'DO_APPROVE_RSVP_SUCCESS'
export const DO_APPROVE_RSVP_FAILED = 'DO_APPROVE_RSVP_FAILED'

export const RESET_BIKIN_EVENT = 'RESET_BIKIN_EVENT'
export const SET_GCAL = 'SET_GCAL'