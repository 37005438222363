import React, { useState, useEffect } from 'react'
import * as a from '../../redux/actions'
import { Base, FlatList, Spinner } from '../../components'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { Mentors } from '../../routers/const'
import * as c from '../../redux/const.config'
import Toast from '../../utils/toast'

function AskMentorDetail() {
    const [text, setText] = useState("")
    const dispatch = useDispatch()
    const { user } = useSelector(state => state.user)
    const { loading, answers, question, listMentor, action } = useSelector(state => state.mentor)
    const params = useParams()
    const history = useHistory()
    const name = Object.keys(question).length ? question.post_author == user.ID ? 'You' : `${question?.author[0]?.first_name} ${question?.author[0]?.last_name}` : ""
    const body = { id: params?.id, token: user.access_token_data.token }

    const fetchComment = () => {
        dispatch(a.getCommentAnswer(body))
    }

    const submitAnswerHandle = () => {
        const data = {
            post_id: question?.ID,
            content: text,
            token: user.access_token_data.token
        }
        dispatch(a.submitAnswer(data))
        setText('')
    }

    useEffect(() => {
        dispatch(a.getQuestionDetail(body))
        fetchComment()
    }, [])

    React.useEffect(() => {
        if (action == c.MENTOR_SUBMIT_ANSWER_SUCCSESS) {
            Toast.info("Successful submit answer")
            fetchComment()
        }
    }, [action])

    const renderMentor = ({ item }) => {
        return (
            <div className="flex items-center border py-3 px-3 rounded-md">
                <img src={item.avatar} className="h-12 w-12 object-cover rounded-full border" />
                <div className="ml-3">
                    <p className="text-base font-semibold line-clamp-1">{item.display_name}</p>
                    <p className="text-sm font-light line-clamp-1">{item.pekerjaan}</p>
                </div>
            </div>
        )
    }

    const renderItem = ({ item }) => {
        return (
            <div className="py-3 w-full border-b">
                <div className="mr-1 flex items-center w-full mb-3">
                    <img src={item?.author[0]?.avatar} className="h-12 w-12 object-cover rounded-full border" />
                    <div className="w-full ml-3 text-left">
                        <p className="text-base font-semibold">{item.author[0].display_name}</p>
                        <p className="font-light text-sm"> {item.comment_date}</p>
                    </div>
                </div>
                <p className="text-sm font-light">{item?.comment_content?.replace(/<[^>]+>/g, '')}</p>
            </div>
        )
    }

    return (
        <Base
            title="Ask Mentor"
            main={
                <div class="bg-white px-4">
                    {loading ?
                        <Spinner size={12} top={10} /> :
                        <>
                            <div className="pb-3 w-full border-b mb-2">
                                <div className="mr-1 flex items-center w-full mb-3">
                                    <div className="w-16">
                                        <img src={Object.keys(question).length && question?.author[0]?.avatar} className="h-12 w-12 object-cover rounded-full border" />
                                    </div>
                                    <div className="w-full ml-1 text-left">
                                        <p className="text-base font-semibold">{name}</p>
                                        <p className="font-light text-sm"> {question.post_date}</p>
                                    </div>
                                </div>
                                <p className="text-sm font-light">{question.post_content?.replace(/<[^>]+>/g, '')}</p>
                            </div >
                            <p className="text-base font-semibold">Jawaban ({answers.length})</p>
                            <FlatList
                                data={answers}
                                renderItem={renderItem}
                                style="mt-1"
                                ListEmptyComponent={() => null}
                            />
                            {user?.mentor == "true" &&
                                <div className="flex items-center w-full justify-between mt-5 mb-20 lg:mb-5">
                                    <textarea
                                        value={text}
                                        className="resize-y border rounded-md w-full mr-3 outline-none h-20 text-sm font-light p-2"
                                        placeholder="Write a comment"
                                        onChange={(e) => setText(e.target.value)}
                                    />
                                    <button onClick={submitAnswerHandle} className="text-sm font-semibold text-red-500">Post</button>
                                </div>
                            }
                        </>
                    }
                </div>
            }
            right={
                <div>
                    <div className="w-full flex justify-between mb-2">
                        <p className="text-lg font-semibold">Mentor</p>
                    </div>
                    <FlatList
                        data={listMentor.slice(0, 5)}
                        renderItem={renderMentor}
                        containerStyle="flex grid grid-rows-3 gap-1"
                    />
                    <button onClick={() => history.push(Mentors)} className="text-red-500 text-sm text-right lg:mt-4 lg:text-center lg:w-full">Lihat lebih banyak</button>
                </div>
            }
        />
    )
}

export { AskMentorDetail }
