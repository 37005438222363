import React, { useState, useEffect } from 'react'
import { Base, FlatList, Spinner } from '../../components'
import { useDispatch, useSelector } from 'react-redux'
import * as a from '../../redux/actions'
import { useHistory } from 'react-router-dom'
import * as s from '../../config/api'
import Icon from 'react-web-vector-icons'
import { Modal, Button } from 'antd'
import Toast from '../../utils/toast'
import FileDownload from 'js-file-download'
import gcalendar from '../../utils/gcalendar'
import moment from 'moment'

let id = null
function ListRsvp(props) {
    const history = useHistory()
    const [page, setPage] = useState(1)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [rsvp, setRsvp] = useState({})
    const [guestList, setGuestList] = useState([])
    const { listRsvp, isLoading, pages, isLoadingAcc, isSuccessAcc, isFailedAcc, gcal } = useSelector(state => state.bikinEvent)
    const dispatch = useDispatch()
    const data = props.location.state?.data || {}
    const free = !data?.ticket_price || data?.ticket_price === "undefined"

    const fetchData = (value) => {
        dispatch(a.getListRsvp({ event_id: data?.id || props.location.pathname.split('/')[2] }))
    }

    const loadMore = () => {
        setTimeout(() => {
            if (listRsvp.length % 15 == 0 && !isLoading && page + 1 <= pages) {
                setPage(page + 1)
                fetchData(page + 1)
            }
        }, 500)
    }

    useEffect(() => {
        fetchData()
        const temp = JSON.parse(localStorage.getItem('guest')) || []
        let guest = data?.guest || []
        if (guest.length) {
            guest = JSON.parse(data?.guest)
            guest = guest.map(x => x.email)
        }
        setGuestList(guest.length > temp.length ? guest : temp)
    }, [])

    const handleOk = (item) => {
        dispatch(a.doApproveRsvp({ event_id: data?.id, member_id: rsvp?.ID || item?.ID }))
    }

    const handleCancel = () => {
        setIsModalVisible(false)
    }

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    const download = async () => {
        const res = await s.getDataFromApi({ event_id: data?.id }, s.doExportRsvpApi, 'base')
        FileDownload(res.data, "rsvp.csv")
    }

    const convert = (str) => {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }

    const onUpdate = (e) => {
        const body = new FormData()
        body.append("subject", data?.post_title)
        body.append("content", data?.post_content)
        body.append("tanggal", convert(data?.tanggal_event))
        body.append("lokasi", data?.lokasi)
        body.append("waktu", data?.waktu_mulai)
        body.append("ticket_price", free ? '' : data?.ticket_price)
        body.append("payment_info", free ? '' : data?.payment_info)
        body.append("id", data?.id)
        body.append("calendar_id", data?.calendar_id)
        body.append("guest", JSON.stringify(e.guest))
        body.append("cal_ios", data?.cal_ios || '')
        body.append("cal_android", data?.cal_android || '')
        dispatch(a.updateEvent({ body, gcal: true }))

        const guest = e.guest.map(x => x.email)
        localStorage.setItem('guest', JSON.stringify(guest))
        setGuestList(guest)
    }

    const inviteAll = () => {
        const guest = listRsvp.map(x => ({ email: x.email }))
        gcalendar({
            type: 'update',
            data: { ...data, guest },
            handleEvent: (e) => onUpdate(e),
            all: true
        })
    }

    useEffect(() => {
        if (isSuccessAcc) {
            setIsModalVisible(false)
            Toast.info(`Konfirmasi berhasil.`)
            fetchData()
        }
        if (isFailedAcc) Toast.error(`Konfirmasi gagal`)
        if (gcal) {
            Toast.info(`Undang semua ke Google Calendar berhasil`)
            dispatch(a.doSetGcal(false))
        }
    }, [isSuccessAcc, isFailedAcc, gcal])


    const renderItem = ({ item, index }) => {
        const checkCal = guestList.includes(item?.email)
        return (
            <button
                key={item.id}
                onClick={() => null}
                className={`bg-white flex flex-row items-center justify-between w-full ${index == listRsvp.length - 1 ? 'mb-20 sm:mb-5' : 'mb-2'}`}>
                <div className="flex flex-row items-center">
                    <div className="border rounded-full border-gray-100">
                        <img src={"https://sman2batu.sch.id/wp-content/uploads/2021/07/default-user-imge.jpeg"} className="h-11 w-11 object-cover rounded-full bg-gray-50" />
                    </div>
                    <div className="px-3 py-1 flex flex-col items-start">
                        <p className="text-sm sm:text-md font-bold break-words my-0.5 text-left">{item?.nama}</p>
                        <p className="text-xs sm:text-sm text-gray-500">{item?.email}</p>
                    </div>
                </div>
                <div className="flex items-center">
                    {checkCal &&
                        <button title="Google Calendar" className="mr-2">
                            <Icon
                                name='calendar'
                                font='AntDesign'
                                color='#60BD4E'
                                size={28}
                            />
                        </button>
                    }
                    {
                        isLoadingAcc && id === item.ID ?
                            <Spinner size={8} />
                            :
                            item?.is_approved ?
                                <Icon
                                    name='checksquareo'
                                    font='AntDesign'
                                    color='#60BD4E'
                                    size={28}
                                />
                                :
                                <button
                                    title="Approve"
                                    onClick={() => {
                                        if (free) {
                                            handleOk(item)
                                            setRsvp(item)
                                            id = item.ID
                                        }
                                        else {
                                            setIsModalVisible(true)
                                            setRsvp(item)
                                        }
                                    }}>
                                    <Icon
                                        name='checksquareo'
                                        font='AntDesign'
                                        color='#DE340C'
                                        size={28}
                                    />
                                </button>
                    }
                </div>
            </button>
        )
    }

    return (
        <Base
            primary
            rightIcon={() => null}
            main={
                <div class="px-3">
                    <div className="">
                        <button className="flex items-center mb-3" onClick={() => history.goBack()}>
                            <Icon
                                name='left'
                                font='AntDesign'
                                color='#4B5563'
                                size={18}
                            />
                            <p className="ml-3 text-lg font-semibold">RSVP</p>
                        </button>
                        <div className="border-t border-b py-2 mb-2 flex items-center justify-between">
                            <div>
                                <p className="text-sm text-gray-500">Total {listRsvp && listRsvp.length || 0} RSVP</p>
                                <p className="text-sm text-gray-500">{guestList.length ? `${guestList.length} Orang Telah Diundang` : 'Belum ada yang diundang'} </p>
                            </div>
                            {listRsvp && listRsvp.length > 0 &&
                                <div>
                                    <button className="flex items-center" onClick={download}>
                                        <Icon
                                            name='download'
                                            font='AntDesign'
                                            color='#4B5563'
                                            size={17}
                                        />
                                        <p className="text-sm text-gray-500 ml-1">Unduh RSVP</p>
                                    </button>
                                    <button className="flex items-center" onClick={inviteAll}>
                                        <Icon
                                            name='calendar'
                                            font='AntDesign'
                                            color='#4B5563'
                                            size={17}
                                        />
                                        <p className="text-sm text-gray-500 ml-1">Undang Semua</p>
                                    </button>
                                </div>
                            }
                        </div>
                    </div>
                    <FlatList
                        data={listRsvp || []}
                        renderItem={renderItem}
                        loading={isLoading}
                    // onEndReached={loadMore}
                    // hasMore={page + 1 <= pages}
                    // page={page}
                    />
                    <Modal
                        title={`Konfirmasi Pembayaran ${rsvp?.nama}`}
                        visible={isModalVisible}
                        onCancel={handleCancel}
                        footer={[
                            <Button disabled={isLoadingAcc} loading={isLoadingAcc} type="primary" onClick={handleOk} >
                                Setujui
                            </Button>
                        ]}>
                        <button onClick={() => openInNewTab(rsvp?.image)}>
                            <img src={rsvp?.image} className="w-full object-cover self-center" />
                        </button>
                    </Modal >
                </div >
            }
            right={< div />}
        />
    )
}

export { ListRsvp }
