function CardFeed(props) {
    return (
        <div className=" w-full border lg:rounded-lg lg:mt-3 rounded-none">
            <div className={`bg-white lg:rounded-lg rounded-none py-5 px-5 ${props.style}`}>
                {props.children}
            </div>
        </div>
    )
}

export { CardFeed }
