import React, { useState, useEffect } from 'react'
import { Spinner, TabCategory, Base, FlatList } from '../../components'
import * as a from '../../redux/actions'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { tabEvent } from '../../Dao/Community'
import { EventDetail } from '../../routers/const'

function Event(props) {
    const [activeTab, setActiveTab] = useState(0)
    const [page, setPage] = useState(1)
    const [keyword, setKeyword] = useState("")
    const dispatch = useDispatch()
    const history = useHistory()
    const params = useParams()
    const type = activeTab == 0 ? "upcoming" : "past"
    const { listEvent, isLoading, pages } = useSelector(state => state.eventCommunity)
    const title = props.location.state?.title || ""

    const fetchData = (value, reset, resetPage) => {
        dispatch(a.getEventCommunity({
            id: params?.id,
            idEvent: null,
            type: null,
            keyword,
            page: value ? value : 1,
            reset,
            typeEvent: type
        }))
        resetPage && setPage(1)
    }

    const handleClickCategory = async (item) => {
        setActiveTab(item)
        setPage(1)
    }

    const handleClickEvent = (id) => {
        const pathname = EventDetail.replace(':id', params?.id).replace(':idContent', id)
        history.push({ pathname, state: { title } })
    }

    const loadMore = () => {
        setTimeout(() => {
            if (listEvent.length % 10 == 0 && !isLoading && page + 1 <= pages) {
                setPage(page + 1)
                fetchData(page + 1, false)
            }
        }, 500)
    }

    useEffect(() => {
        fetchData(null, true)
    }, [activeTab])

    useEffect(() => { fetchData() }, [keyword])

    const renderItem = ({ item, index }) => {
        return (
            <button
                key={item.id}
                onClick={() => handleClickEvent(item.id)}
                className={`bg-white mx-5 flex flex-row mt-3 items-center ${index == listEvent.length - 1 && 'mb-20 sm:mb-5'}`}>
                <img src={item?.featuredthumb[0]?.image} className="h-24 w-24 object-cover" />
                <div className="px-3 py-1 flex flex-col items-start">
                    <p className="text-xs sm:text-sm text-red-500">{item?.tanggal_event}</p>
                    <p className="text-sm sm:text-md font-bold break-words my-0.5 text-left">{item?.post_title}</p>
                    <p className="text-xs sm:text-sm text-gray-500">{item?.lokasi}</p>
                </div>
            </button>
        )
    }

    return (
        <Base
            title={`Event ${title}`}
            onSearch={setKeyword}
            main={
                <>
                    <TabCategory
                        data={tabEvent}
                        active={activeTab}
                        onPressItem={handleClickCategory}
                        id="id"
                        class=""
                    />
                    <FlatList
                        data={listEvent}
                        renderItem={renderItem}
                        loading={isLoading}
                        onEndReached={loadMore}
                        hasMore={page + 1 <= pages}
                        page={page}
                    />
                </>
            }
            right={<div />}
        />
    )
}

export { Event }
