import React, { useState, useEffect } from 'react'
import * as a from '../../redux/actions'
import { Base, FlatList, TabCategory, Spinner, Alert } from '../../components'
import { useDispatch, useSelector } from 'react-redux'
import Icon from 'react-web-vector-icons'
import { useParams, useHistory } from 'react-router-dom'
import { AskMentorDetail, Mentors } from '../../routers/const'
import { BsClock } from 'react-icons/bs'
import { disableFeature, link } from '../../Dao'

function FeedAskMentor() {
    const [visible, setVisible] = useState(false)
    const [modalMentor, setModalMentor] = useState(false)
    const [modalTopic, setModalTopic] = useState(false)
    const [showAlert, setShowAlert] = React.useState(false)
    const [activeTab, setActiveTab] = useState(0)
    const [mobile, setMobile] = useState(false)
    const [filter, setFilter] = useState({
        topic: {},
        mentor: {}
    })
    const [page, setPage] = useState(1)
    const dispatch = useDispatch()
    const { user } = useSelector(state => state.user)
    const theme = useSelector(state => state.profile.theme)
    const { listQuestion, myQestion, isLoading, questionLoading, listMentor, pages } = useSelector(state => state.mentor)
    const params = useParams()
    const history = useHistory()
    const listCtg = [
        { id: 0, name: 'Semua' },
        { id: 1, name: 'Pertanyaan ku' },
    ]
    const data = activeTab == 0 ? listQuestion : myQestion

    const fetchData = (value, reset, resetPage, type) => {
        const body = { token: user.access_token_data.token, id: user.ID }
        if (type == 'feed' || !type) {
            dispatch(a.getQuestions({
                page: value ? value : 1,
                topic: typeof (filter.topic.id_topik) != 'undefined' ? filter.topic.id_topik : '',
                mentor: typeof (filter.mentor.id) != 'undefined' ? filter.mentor.id : '',
                reset,
            }))
            resetPage && setPage(1)
        } else {
            dispatch(a.getMyQuestions(body))
        }
        dispatch(a.getListMentor(body))
    }

    const tabFilter = (item) => {
        if (item !== activeTab) {
            setActiveTab(item)
            fetchData(null, true, false, item == 0 ? 'feed' : 'my')
        }
    }

    const loadMore = () => {
        setTimeout(() => {
            if (data.length % 10 == 0 && !isLoading && page + 1 <= pages) {
                setPage(page + 1)
                fetchData(page + 1, false)
            }
        }, 500)
    }

    useEffect(() => {
        fetchData()
        dispatch(a.doCheckToken())
    }, [])

    useEffect(() => {
        if (typeof window !== 'undefined') {
            function handleResize() {
                setMobile(window.innerWidth <= 1280)
            }
            window.addEventListener("resize", handleResize)
            handleResize()
            return () => window.removeEventListener("resize", handleResize)
        }
    }, [])

    const renderMentor = ({ item }) => {
        return (
            <div className="flex items-center border py-3 px-3 rounded-md">
                <img src={item.avatar} className="h-12 w-12 object-cover rounded-full border" />
                <div className="ml-3">
                    <p className="text-base font-semibold line-clamp-1">{item.display_name}</p>
                    <p className="text-sm font-light line-clamp-1">{item.pekerjaan}</p>
                </div>
            </div>
        )
    }

    const renderItem = ({ item }) => {
        const full_name = item.author ? item.author[0].first_name + ' ' + item.author[0].last_name : ''
        const display_name = full_name
        const me = activeTab == 1
        return (
            <button onClick={() => history.push(AskMentorDetail.replace(':id', item.ID))} className="border px-4 py-3 rounded-md mb-4 text-left w-full">
                <div className="flex items-center justify-between mb-3">
                    <div className="">
                        <p className="text-lg font-semibold">{item?.taxonomies?.cma_category[0]?.name}</p>
                        {!me && <p className="text-sm font-light">oleh: {display_name}</p>}
                    </div>
                    {!me && <img src={item?.author && item?.author[0]?.avatar} className="h-12 w-12 object-cover rounded-full border" />}
                </div>
                <div className="">
                    <div dangerouslySetInnerHTML={{ __html: item.post_content }} className="text-sm font-light" />
                    <div className="flex items-center my-2">
                        <BsClock className="text-sm" />
                        <p className="font-semibold text-sm ml-2"> {item.post_date}</p>
                    </div>
                    <div className="flex items-center">
                        {me ?
                            <>
                                <img src={item?.author && item?.author[0]?.avatar} className="h-8 w-8 object-cover rounded-full border" />
                                <div className="bg-white h-8 w-8 -ml-2 flex items-center justify-center rounded-full">
                                    <div className={`h-7 w-7 flex items-center justify-center rounded-full bg-red-500 text-white text-sm`}>
                                        {item.comment_count}
                                    </div>
                                </div>
                            </>
                            :
                            <div className={`h-7 w-7 flex items-center justify-center rounded-full bg-red-500 text-white text-sm`}>
                                {item.comment_count}
                            </div>
                        }
                        <p className="text-sm font-light ml-2">Tanggapan</p>
                    </div>
                </div>
            </button >
        )
    }

    return (
        <Base
            alert={false}
            title="Ask Mentor"
            rightIcon={() => null}
            main={
                <div>
                    <Alert
                        title={disableFeature.title}
                        subTitle={disableFeature.subTitle}
                        visible={showAlert}
                        onClick={() => window.open(link.playstore, "_blank")}
                        yes={disableFeature.yes}
                        cancel={disableFeature.cancel}
                        onClose={() => setShowAlert(false)}
                    />

                    <div class="bg-white px-4">
                        {mobile &&
                            <>
                                <div className="w-full flex justify-between mb-2">
                                    <p className="text-lg font-semibold">Mentor</p>
                                    <button className="text-red-500 text-sm text-right">Lihat lebih banyak</button>
                                </div>
                                <FlatList
                                    data={listMentor.slice(0, 1)}
                                    renderItem={renderMentor}
                                    style="mb-2"
                                />
                            </>
                        }
                        <TabCategory
                            data={listCtg}
                            active={activeTab}
                            id="id"
                            class="shadow-md border-0"
                            onPressItem={tabFilter}
                        />
                        <FlatList
                            data={data}
                            renderItem={renderItem}
                            style="mt-5 mb-20 lg:mb-0"
                            loading={isLoading || questionLoading}
                            onEndReached={loadMore}
                            hasMore={page + 1 <= pages}
                            page={page}
                        />
                    </div>
                    {(isLoading || questionLoading) ? null :
                        <div className="bottom-20 lg:bottom-10 w-full sticky z-50 flex items-center justify-center lg:justify-end">
                            <button
                                onClick={() => setShowAlert(true)}
                                class=" flex flex-row items-center justify-center text-white px-4 w-auto h-10 lg:h-16 lg:w-16 shadow-lg bg-red-600 rounded-full hover:bg-red-700 active:shadow-lg mouse transition ease-in duration-200 focus:outline-none"
                            >
                                <Icon
                                    name={"form"}
                                    font='AntDesign'
                                    color='white'
                                    size={26}
                                />
                                <div className="block lg:hidden ml-1">Ask mentor</div>
                            </button>
                        </div>
                    }
                </div>
            }
            right={
                <div>
                    <div className="w-full flex justify-between mb-2">
                        <p className="text-lg font-semibold">Mentor</p>
                    </div>
                    <FlatList
                        data={listMentor.slice(0, 5)}
                        renderItem={renderMentor}
                        containerStyle="flex grid grid-rows-3 gap-1"
                    />
                    {listMentor && listMentor.length > 0 &&
                        <button onClick={() => history.push(Mentors)} className="text-red-500 text-sm text-right lg:mt-4 lg:text-center lg:w-full">Lihat lebih banyak</button>
                    }
                </div>
            }
        />
    )
}

export { FeedAskMentor }
