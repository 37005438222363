import React from 'react'
import { useHistory } from 'react-router-dom'
import Icon from 'react-web-vector-icons'
import track from '../../pages/Podcasts/track'
import { AudioPlayer, MediaAudio } from '../Media'
import { Bottom, Left } from '../NavPosition'
import { Alert } from '../Alert'
import { ToastContainer } from 'react-toastify'
import { link } from '../../Dao'

function Base({ primary, title, main, right,
    hideSearch = false, audio, onSearch = () => null, hideIconSearch = false,
    rightIcon = null, hideBottom, alert = true }) {
    const [search, setSearch] = React.useState(true)
    const history = useHistory()
    const [showAlert, setShowAlert] = React.useState(true)
    return (
        <div>
            {alert ?
                <Alert
                    style="md:hidden lg:hidden"
                    title="Halaman ini terlihat lebih baik di aplikasi"
                    subTitle=""
                    visible={showAlert}
                    onClick={() => window.open(link.playstore, "_blank")}
                    yes="DAPATKAN APLIKASINYA"
                    cancel="Lanjutkan di web"
                    onClose={() => setShowAlert(false)}
                /> : null}
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="w-full container mx-auto">
                <div className="lg:flex min-h-screen">
                    <div className="fixed z-40 inset-0 flex-none h-full  bg-opacity-25 w-full lg:static lg:h-auto lg:overflow-y-visible lg:pt-0 sm:w-1/4 lg:block hidden">
                        <Left />
                    </div>
                    <div id="content-wrapper" className="min-w-0 w-full flex-auto lg:static lg:max-h-full lg:overflow-visible">
                        <div className="w-full flex">
                            <div className="min-w-0 flex-auto">
                                <div className="sticky top-0 bg-white flex lg:flex-col lg:relative items-center lg:items-start">
                                    {!primary ?
                                        <button
                                            onClick={() => history.goBack()}
                                            className="p-3 lg:hidden">
                                            <Icon
                                                name='chevron-left'
                                                font='Feather'
                                                color='black'
                                                size={20}
                                            />
                                        </button> : null}
                                    {!hideSearch ? <div className="flex flex-row justify-between items-center py-4 w-full px-3">
                                        {search ?
                                            <div className="">
                                                <div className="flex items-center justify-between">
                                                    <span className="font-bold text-gray-800 text-md sm:text-lg">{title}</span>
                                                </div>
                                            </div> :
                                            <form
                                                className="w-full flex flex-row items-center"
                                                onSubmit={(e) => {
                                                    e.preventDefault()
                                                    onSearch(e.target.elements.key.value)
                                                }}>
                                                <input name="key" className="focus:ring-1 focus:ring-red-500 rounded-md focus:outline-none bg-gray-100 w-full flex flex-row items-center py-1 px-3 lg:mr-0 mr-2" placeholder={`Cari ${title.toLowerCase()}`} />
                                            </form>
                                        }
                                        {!hideIconSearch && rightIcon ?
                                            rightIcon()
                                            :
                                            <button
                                                onClick={() => {
                                                    setSearch(!search)
                                                    onSearch("")
                                                }}
                                                className="flex px-2">
                                                <Icon
                                                    name={search ? "search1" : "close"}
                                                    font='AntDesign'
                                                    color='black'
                                                    size={20}
                                                />
                                            </button>
                                        }
                                    </div> : null}
                                </div>
                                <div className="">
                                    {main}
                                </div>
                            </div>
                            {right ?
                                <div className="hidden xl:text-sm xl:block flex-none w-96 pl-8 mr-8">
                                    <div className="flex flex-col justify-between overflow-y-auto sticky max-h-(screen-18) pb-6 bg-white p-3 rounded-lg">
                                        {right ? right : null}
                                    </div>
                                </div>
                                : null
                            }
                        </div>
                    </div>
                </div>
                {!hideBottom && <Bottom />}
            </div>
            {audio ?
                <div class="bottom-16 lg:bottom-0 w-full sticky z-50">
                    {audio}
                </div>
                :
                null}
        </div>
    )
}

export { Base }
