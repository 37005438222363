import React, { useState, useEffect } from 'react'
import { Base, FlatList } from '../../components'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { IoMdClose } from 'react-icons/io'

function Mentors() {
    const [showDetail, setShowDetail] = useState(false)
    const [mobile, setMobile] = useState(false)
    const [detail, setDetail] = useState({})
    const history = useHistory()
    const { listMentor } = useSelector(state => state.mentor)

    const handleClickMentor = (item) => {
        const set = item !== 'close'
        setShowDetail(set)
        setDetail(set ? item : {})
    }

    useEffect(() => {
        if (typeof window !== 'undefined') {
            function handleResize() {
                setMobile(window.innerWidth <= 640)
            }
            window.addEventListener("resize", handleResize)
            handleResize()
            return () => window.removeEventListener("resize", handleResize)
        }
    }, [])

    const renderMentor = ({ item }) => {
        return (
            <button onClick={() => handleClickMentor(item)} className="flex items-center border py-3 px-3 rounded-md text-left">
                <img src={item.avatar} className="h-12 w-12 object-cover rounded-full border" />
                <div className="ml-3">
                    <p className="text-base font-semibold line-clamp-1">{item.display_name}</p>
                    <p className="text-sm font-light line-clamp-1">{item.pekerjaan}</p>
                </div>
            </button>
        )
    }

    return (
        <Base
            hideSearch
            main={
                <div className="">
                    <p className="font-semibold text-lg px-3 sm:mt-3 mb-3">Mentors</p>
                    <div className="flex items-start px-3">
                        {<FlatList
                            data={listMentor}
                            renderItem={renderMentor}
                            containerStyle={`
                            flex grid grid-cols-1 mr-3 gap-1 mb-24 lg:mb-5
                            ${showDetail ? 'md:grid-cols-1' : 'md:grid-cols-2 lg:grid-cols-3'} 
                            ${showDetail && 'w-1/2'} 
                            ${showDetail && mobile && 'hidden'}
                        `}
                        />}
                        {showDetail &&
                            <div className={`${!mobile && 'w-1/2'}  px-3 border rounded-md pt-3 pb-5 mb-24 lg:mb-5`}>
                                <IoMdClose className="text-2xl mr-2 absolute" onClick={() => handleClickMentor('close')} />
                                <div className="py-3 px-3 rounded-md text-center flex flex-col items-center">
                                    <img src={detail.avatar} className="h-44 w-44 object-cover rounded-full border" />
                                    <div className="mt-3">
                                        <p className="text-base font-semibold">{detail.display_name}</p>
                                        <p className="text-sm my-1">{detail.pekerjaan}</p>
                                        {detail?.topik?.length && <p className="text-sm font-light">{detail?.topik.map(item => `${item.topik} `)}</p>}
                                    </div>
                                </div>
                                <p className="text-base font-semibold mb-1 mt-2">Biografi</p>
                                <p className="text-sm font-light">{detail.biografi}</p>
                                <p className="text-base font-semibold mb-1 mt-2">Profile</p>
                                <p className="text-sm font-light">{detail.profil}</p>
                            </div>
                        }
                    </div>
                </div>
            }
        />
    )
}

export { Mentors }
