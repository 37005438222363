import { all } from 'redux-saga/effects'
import { Auth } from './auth'
import * as c from './community'
import { Profile } from './profile'
import * as k from './content'
import { AskMentor } from './askmentor'
import { Chat } from './chat'
import { Trivia } from './trivia'
import { MessageGlobal } from './message'
import { Points } from './point'
import { BikinEvent } from './bikinEvent'

const watchers = [
    ...Auth,
    ...MessageGlobal,
    ...k.BannerHome,
    ...k.Articles,
    ...k.Bookmarks,
    ...c.FeedCommunity,
    ...c.ArticleCommunity,
    ...c.EventCommunity,
    ...c.PhotoCommunity,
    ...c.MemberCommunity,
    ...c.PodcastCommunity,
    ...Profile,
    ...AskMentor,
    ...Chat,
    ...Trivia,
    ...Points,
    ...BikinEvent,
]

export default function* rootSaga() {
    yield all(watchers)
}