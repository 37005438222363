import { takeEvery, put } from 'redux-saga/effects'
import * as t from '../../const.config'
import * as a from '../../../config/api'

export const PhotoCommunity = [
    takeEvery(t.GET_PHOTO, workerGetPhoto),
]

function* workerGetPhoto(action) {
    const { id, idPhoto, type, page, reset = true, loading } = action.payload
    yield put({ type: t.GET_PHOTO_START, reset: type === "byId" ? false : reset, loading: loading !== undefined ? loading : true })
    try {
        const body = {
            perpage: 18,
            callpage: page,
            community_id: id,
            image_id: type === "byId" ? idPhoto : null
        }
        const data = yield a.getDataFromApi(body, a.getPhotoApi, 'page')
        const state = type === "byId" ? "detailPhoto" : "listPhoto"
        const newData = type === "byId" ? data.data[0] : data.data
        const pages = data.page
        const isLoadMore = page > 1

        yield put({ type: t.GET_PHOTO_SUCCESS, data: newData, state, isLoadMore, pages })
    } catch (error) {
        yield put({ type: t.GET_PHOTO_FAILED })
    }
}