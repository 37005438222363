import React, { useState, useEffect, useCallback } from 'react'
import Icon from 'react-web-vector-icons'
import qiscus from '../../utils/qiscusSDK'
import moment from 'moment'
import { IoSendSharp, IoCloseCircle } from 'react-icons/io5'
import { IoIosArrowDown, IoMdClose } from 'react-icons/io'
import { BiArrowBack } from 'react-icons/bi'
import { HiUserGroup } from 'react-icons/hi'
import { ImAttachment } from 'react-icons/im'
import * as BS from 'react-icons/bs'
import { FlatList, Alert, SimpleModal } from '../../components'
import Toast from '../../utils/toast'
import { useSelector } from "react-redux"
import Linkify from 'react-linkify'
import { langId } from '../../Dao'

let tempReply = {}
function ChatRoom(props) {
    const { roomDetail } = useSelector(state => state.chat)
    const { user } = useSelector(state => state.user)
    const { visible, data } = props
    const { room, showRoom, mobile, onClickBack, dataRoom, typeList, onClickHeader, showDetail, personal, listMember } = data
    const [text, setText] = useState('')
    const [alertDelete, setAlertDelete] = useState(false)
    const [showOption, setShowOption] = useState(false)
    const [message, setMessage] = useState({})
    const [isReply, setIsReply] = useState(false)
    const [replyData, setReplyData] = useState({})
    const [fileData, setFileData] = useState({ status: '' })
    const [showMention, setShowMention] = useState(false)

    const switchFileType = (fileType) => {
        switch (fileType) {
            case 'image/jpeg':
            case 'image/jpg':
            case 'image/png':
            case 'image':
                return 'image';
            case 'video/mp4':
            case 'video/avi':
            case 'video/3gp':
            case 'video/mkv':
                return 'video';
            case 'video':
                return 'video';
            case 'audio/mpeg':
                return 'music';
            case 'application/pdf':
                return 'file-pdf';
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                return 'file-document';
            default:
                return 'file';
        }
    }

    const handleClickSend = (type) => {
        const checkText = text && !hasWhiteSpace(text)
        if (type == 'text' && checkText) {
            setText("")
            doSendComment(dataRoom?.id, text)
        } else if (type == 'file' || type == 'image') {
            setText("")
            const { uniqueId, type, comment, size, file } = fileData
            const msg = checkText ? text : type == "image" ? "Photo uploaded" : "File uploaded"
            const obj = {
                uri: comment.url,
                type: comment.type,
                name: comment.name
            }
            qiscus.upload(file, (error, progress, fileURL) => {
                if (error) Toast.error('Upload failed')
                if (progress) Toast.info(`Uploading...`)
                if (fileURL && fileURL !== null) {
                    const payload = JSON.stringify({
                        type: obj.type,
                        content: {
                            ext: extFile(obj.name).replace('.', '').toUpperCase(),
                            size: `${size.toString().split('.')[0]} kB`,
                            url: fileURL,
                            file_name: obj.name,
                            caption: msg
                        }
                    })
                    doSendComment(dataRoom?.id, msg, uniqueId, type, payload)
                    setFileData({ status: '' })
                }
            })
        } else if (type == 'reply' && checkText) {
            setText("")
            const payload = JSON.stringify(replyData)
            doSendComment(dataRoom?.id, text, new Date().getTime(), 'custom', payload)
            setIsReply(false)
            setReplyData({})
        }
    }

    const doSendComment = (id, text, uniqueId, type, payload) => {
        qiscus.sendComment(id, text, uniqueId, type, payload)
    }

    const hasWhiteSpace = (text) => {
        return /^\s/.test(text)
    }

    const construcComment = (item) => {
        const date = new Date()
        return {
            id: date.getTime(),
            uniqueId: "" + date.getTime(),
            unique_temp_id: "" + date.getTime(),
            timestamp: date.getTime(),
            type: "text",
            status: "sending",
            message: text,
            email: "",
            username: "",
            isSent: false,
            isRead: false,
            isDelivered: true,
            ...item
        }
    }

    const getStatus = (item) => {
        if (item.isRead && item.isSent || item.status == 'read') {
            return { icon: BS.BsCheckAll, color: 'text-red-500' }
        }
        if (item.isSent && !item.isRead || item.status == 'sent') {
            return { icon: typeList === 'forum' ? BS.BsCheck : BS.BsCheckAll, color: 'text-gray-400' }
        }
        if (!item.isDelivered) {
            return { icon: BS.BsCheck, color: 'text-gray-400' }
        } else if (item.status == "sending") {
            return { name: BS.BsClock, color: 'text-gray-400' }
        }
    }

    const extFile = (file) => {
        let split = file.split(".")
        let ext = split[split.length - 1]
        return `.${ext}`
    }

    const constructFileData = (e, file, url, url2) => {
        const name = file.name
        const fileSize = (file.size / 1024) / 1024
        const comment = construcComment({
            message: text,
            type: "upload",
            fileURI: url,
            type: file.type,
            name,
            url: url2
        })
        if (fileSize.toFixed(2) > 10) {
            Toast.error('Your file more than 10 MB')
        } else {
            const size = file.size / 1024
            setFileData({
                file,
                message: comment.message,
                uniqueId: comment.uniqueId,
                type: 'custom',
                status: 'ready',
                comment,
                name,
                size
            })
            e.target.value = ''
        }
    }

    const handlePickFile = (e) => {
        e.preventDefault()
        const file = e.target.files[0]
        const url = URL.createObjectURL(file)
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => constructFileData(e, file, url, reader.result)
    }

    useEffect(() => {
        if (!personal && typeList !== 'forum') {
            const indexAt = text.indexOf('@')
            if (text.includes('@') && !text[indexAt + 1] && !showMention) setShowMention(true)
            else showMention && setShowMention(false)
        }
    }, [text])

    useEffect(() => {
        setText("")
        setIsReply(false)
        setReplyData({})
        tempReply = {}
        setShowOption(false)
        setMessage({})
        setFileData({ status: '' })
    }, [dataRoom])

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    const renderMention = ({ item }) => {
        const name = item?.user_id == user.ID ? 'You' : item?.username
        return (
            <button onClick={() => {
                setShowMention(false)
                setText(`${text}${name}`)
            }} className="flex items-center justify-between mb-2 w-full px-3">
                <div className="flex items-center" >
                    <img src={item?.avatar_url} className="h-8 w-8 object-cover rounded-full mr-2" />
                    <p className="text-xs sm:text-sm text-gray-700">{name}</p>
                </div>
            </button>
        )
    }

    const renderOption = () => {
        const option = [
            {
                id: 0,
                text: 'Reply',
                onPress: () => {
                    setIsReply(true)
                    setReplyData(tempReply)
                }
            },
            ...(message.me ? [{
                id: 1,
                text: 'Delete message',
                onPress: () => setAlertDelete(true)
            }] : [])
        ]
        return (
            <>
                <SimpleModal
                    visible={showOption}
                    onClose={() => setShowOption(false)}
                    data={option}
                    style="sm:w-1/3"
                />
                <Alert
                    title="Delete message"
                    subTitle="Are you sure you want to delete this message?"
                    visible={alertDelete}
                    onClick={() => {
                        qiscus.deleteComment(dataRoom?.id, [message.id])
                        setAlertDelete(false)
                    }}
                    onClose={() => setAlertDelete(false)}
                />
            </>
        )
    }

    const renderBubbleItem = (item, isMe) => {
        const fileType = item.hasOwnProperty('subtype') ? item.subtype : (item.hasOwnProperty('payload') ? item.payload.type : '')
        const style = {
            message: `text-xs sm:text-sm font-light ${isMe && 'text-white font-normal'} mx-1.5`,
            subMsg: `text-xs font-light ${isMe && 'text-white font-normal'} mx-1.5 mt-0.5`,
            hide: `text-xs font-light ${isMe && 'text-transparent font-normal'} mx-1.5 mt-0.5 absolute`,
            caption: `text-xs sm:text-sm font-light ${isMe && 'text-white font-normal'} mx-1.5 mt-1 border-t border-white pt-1.5`,
            container: `flex items-center w-full`,
        }
        if (item.type == 'custom') {
            if (item?.payload?.type == 'reply') {
                const sender = item?.payload?.content.sender == qiscus.userData.username ? 'You' : item?.payload?.content.sender
                return (
                    <div>
                        <div className={`${isMe ? 'bg-red-600' : 'bg-gray-300'} rounded-lg px-0.5 py-1 mb-0.5 pb-1.5`}>
                            <p className={`${style.subMsg} font-bold mb-0.5`}>{sender}</p>
                            {item?.payload?.content.file_type == 'image' ?
                                <div className={`${style.container} px-2`}>
                                    <img src={item?.payload?.content.url} className="h-10 w-10 rounded-md object-cover mb-1" />
                                    <p className={`${style.subMsg} w-11/12`}>{item?.payload?.content.message !== 'File uploaded' ? item?.payload?.content.message : 'Photo'}</p>
                                </div>
                                :
                                <>
                                    {item?.payload?.content.type !== 'text' && item?.payload?.content.file_type !== 'text' &&
                                        <div className={`${style.container} px-2`}>
                                            <Icon
                                                name={item?.payload?.content.file_type}
                                                font="MaterialCommunityIcons"
                                                color={isMe ? 'white' : 'gray'}
                                                size={20}
                                            />
                                            <p className={`${style.subMsg}`}>{item?.payload?.content.file_name}</p>
                                        </div>
                                    }
                                    {item?.payload?.content.message !== "File uploaded" && <p className={`${style.subMsg}`}>{item?.payload?.content.message}</p>}
                                    {/* {urlify(item?.message) &&
                                        item?.message.split(' ').length > 1 ?
                                        <p className={`${style.hide}`}>{item?.message}</p>
                                        : null}
                                    <p className={`${style.hide}`}>{item?.message}</p>
                                    <p className={`${style.hide}`}>{item?.username}</p> */}
                                </>
                            }
                        </div>
                        {item?.message && item?.message !== "File uploaded" &&
                            <Linkify properties={{ style: { color: !isMe ? '#2196f3' : '#3dabf5' } }}>
                                <p className={`${style.message}`}>{item?.message}</p>
                            </Linkify>
                        }
                    </div>
                )
            }
            if (switchFileType(fileType) == 'image') {
                const uri = item.payload.content.url
                const checkMsg = item?.message && item?.message !== "Photo uploaded" && item?.message !== "File uploaded"
                return (
                    <div className="w-56 sm:w-60">
                        <button onClick={() => openInNewTab(uri)}>
                            <img src={uri} className={`object-cover w-56 h-56 sm:w-60 sm:h-60 mb-1 ${isMe ? 'rounded-tl-md' : 'rounded-tr-md'}`} />
                        </button>
                        {checkMsg &&
                            <Linkify properties={{ style: { color: !isMe ? '#2196f3' : '#3dabf5' } }}>
                                <p className={style.message}>{item?.message}</p>
                            </Linkify>
                        }
                    </div>
                )
            }
            else {
                return (
                    <div>
                        <button onClick={() => openInNewTab(item?.payload?.content.url)} className={`${style.container}`}>
                            <Icon
                                name={switchFileType(fileType)}
                                font="MaterialCommunityIcons"
                                color={isMe ? 'white' : 'gray'}
                                size={20}
                            />
                            <p className={style.message}>{item?.payload?.content.file_name}</p>
                        </button>
                        {item?.payload?.content.ext && <p className={style.subMsg}>{`${item?.payload?.content.ext} • ${item?.payload?.content.size}  `}</p>}
                        {item?.message && item?.message !== "File uploaded" &&
                            <Linkify properties={{ style: { color: !isMe ? '#2196f3' : '#3dabf5' } }}>
                                <p className={style.caption}>{item?.message}</p>
                            </Linkify>
                        }
                    </div>
                )
            }
        } else if (item.type == "upload") {
            return null
        } else if (item.type == 'text') {
            return (
                <Linkify properties={{ style: { color: !isMe ? '#2196f3' : '#3dabf5' } }}>
                    <p className={style.message}>{item?.message}</p>
                </Linkify>
            )
        }
    }

    const renderChat = useCallback(({ item }) => {
        const isMe = item?.email === dataRoom?.myId
        const IconStatus = getStatus(item).icon
        const fileType = item.hasOwnProperty('subtype') ? item.subtype : (item.hasOwnProperty('payload') ? item.payload.type : '')
        const replyFormat = {
            id: item?.id,
            email: item?.email,
            type: 'reply',
            content: {
                message: item?.message,
                file_name: item?.payload?.content?.file_name,
                type: item?.payload?.type == 'reply' ? 'text' : 'content',
                file_type: item.type == 'custom' && item?.payload?.content?.file_type !== 'text' ? switchFileType(fileType) : 'text',
                sender: item?.username,
                url: item?.payload?.content?.url
            }
        }
        return (
            <div className={`flex flex-col ${isMe ? 'items-end' : 'items-start'} mx-3 my-1 ws`}>
                <div className={`flex items-start ${isMe && 'justify-end'} p-0 w-full`}>
                    {!isMe && <img src={item?.user_avatar_url} className="h-9 w-9 object-cover rounded-full mr-2 border" />}
                    <div className={`${isMe ? 'bg-red-500 rounded-tr-none' : 'bg-gray-200 rounded-tl-none'} px-1.5 py-1.5 mt-1 rounded-xl break-words show relative`} style={{ maxWidth: '80%', minWidth: item?.payload?.type == 'reply' && '15%' }}>
                        {!isMe && typeList !== 'single' && <p className="font-semibold text-xs sm:text-sm mx-1.5 mb-0.5">{item?.username}</p>}
                        {renderBubbleItem(item, isMe)}
                        <div className={`${isMe ? 'bg-red-500' : 'bg-gray-200'} w-7 h-7 absolute top-0.5 right-0.5 hide justify-center items-center rounded-md`}>
                            <IoIosArrowDown
                                className={`text-lg ${isMe ? 'text-white' : 'text-gray-500'}`}
                                onClick={() => {
                                    setShowOption(true)
                                    setMessage({ id: item?.unique_temp_id, me: isMe })
                                    tempReply = replyFormat
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex items-center">
                    <p className="text-xs font-light text-gray-500 my-1 ml-10 mr-1">{moment(item?.timestamp).format("DD/MM HH:mm")}</p>
                    {isMe && <IconStatus className={`${getStatus(item).color}`} />}
                </div>
            </div>
        )
    })

    return (
        <>
            {visible ?
                <div className={`flex w-full md:w-3/5 h-full flex-col justify-between border-r border-l md:border-l-0 ${!showRoom && mobile && 'hidden'} relative`}>
                    <div className="flex items-center w-full border-b px-3 h-16">
                        <BiArrowBack className="text-2xl mr-2 md:hidden" onClick={onClickBack} />
                        <button onClick={onClickHeader} className="flex items-center w-full">
                            <div className="w-11 h-12 mr-1 lg:mr-2 flex items-center">
                                {roomDetail?.room_avatar_url || dataRoom?.avatar ?
                                    <img src={personal ? dataRoom?.avatar : roomDetail?.room_avatar_url || dataRoom?.avatar} className="w-8 h-8 md:w-10 md:h-10 object-cover rounded-full bg-gray-300" /> :
                                    <div className="w-8 h-8 md:w-10 md:h-10 object-cover rounded-full bg-gray-100 flex items-center justify-center">
                                        <HiUserGroup className="text-gray-500" />
                                    </div>
                                }
                            </div>
                            <div className="flex flex-col justify-center w-full ">
                                <p className={`text-sm font-semibold line-clamp-1 text-left`}>{personal ? dataRoom?.name : roomDetail?.room_name || dataRoom?.name}</p>
                            </div>
                        </button>
                    </div>
                    {fileData.status == 'ready' && switchFileType(fileData.file.type) == 'image' ?
                        <div className="bg-white w-full  z-10 -mt-0.5 left-0 h-full">
                            <div className="flex items-center bg-gray-100 py-3 px-2">
                                <IoMdClose className="text-2xl mr-2" onClick={() => setFileData({ status: '' })} />
                                <p className="text-base font-semibold">Pratinjau</p>
                            </div>
                            <div className="flex items-center justify-center h-full-90 px-2" >
                                <img src={fileData?.comment?.fileURI} className="max-h-screen-70 object-contain" />
                            </div>
                        </div> :
                        <FlatList
                            data={room}
                            renderItem={renderChat}
                            style="overflow-y-auto py-2 h-screen"
                            inverse
                            ListEmptyComponent={() => (
                                <div className="h-screen flex items-center justify-center">
                                    <p className="text-base font-light">No message</p>
                                </div>
                            )}
                        // onEndReached={loadMore}
                        // hasMore={true}
                        // loading={loading}
                        />
                    }
                    {showMention &&
                        <FlatList
                            data={listMember}
                            renderItem={renderMention}
                            style="overflow-y-auto max-h-screen-30 pt-2 mt-1 bg-gray-50"
                        />
                    }
                    <div className="bg-white w-full flex items-center pl-2 justify-between mt-1 mb-1">
                        <div className="w-10/12 sm:w-11/12 bg-gray-100 py-1.5 px-1.5 rounded-2xl">
                            {fileData.status == 'ready' && switchFileType(fileData?.file.type) !== 'image' &&
                                <div className="flex items-center relative w-full bg-gray-200 py-1 rounded-xl px-2 mb-0.5 pb-1.5">
                                    <Icon
                                        name={switchFileType(fileData?.file.type)}
                                        font="MaterialCommunityIcons"
                                        color={'gray'}
                                        size={20}
                                    />
                                    <p className="text-xs font-light ml-1">{fileData?.name}</p>
                                    <IoCloseCircle
                                        className="text-red-500 absolute top-1 right-1 text-xl"
                                        onClick={() => setFileData({ status: '' })}
                                    />
                                </div>
                            }
                            {isReply &&
                                <div className="w-full bg-gray-200 relative py-1 rounded-xl px-2 mb-0.5 pb-1.5">
                                    <IoCloseCircle
                                        className="text-red-500 absolute top-1 right-1 text-xl"
                                        onClick={() => {
                                            setIsReply(false)
                                            setReplyData({})
                                        }}
                                    />
                                    <p className="text-xs font-semibold mb-0.5">{replyData?.email == qiscus.userData.email ? 'You' : replyData?.content?.sender}</p>
                                    {replyData?.content?.file_type == 'image' ?
                                        <div className="flex items-center">
                                            <img src={replyData?.content?.url} className="w-12 h-12 object-cover rounded-lg border" />
                                            <p className="text-sm font-light ml-2 w-11/12">{replyData?.content?.message !== 'File uploaded' ? replyData?.content?.message : 'Photo'}</p>
                                        </div> :
                                        <>
                                            {replyData?.content?.type !== 'text' && replyData?.content?.file_type !== 'text' ?
                                                <div className="flex items-center">
                                                    <Icon
                                                        name={replyData?.content?.file_type}
                                                        font="MaterialCommunityIcons"
                                                        color={'gray'}
                                                        size={20}
                                                    />
                                                    <p className="text-xs font-light ml-1">{replyData?.content?.file_name}</p>
                                                </div> : null}
                                            <p className="text-xs font-light">{replyData?.content?.message}</p>
                                        </>
                                    }
                                </div>
                            }
                            <div className="py-1 px-2.5">
                                <div className="flex items-center">
                                    <form className="w-full" onSubmit={e => {
                                        e.preventDefault()
                                        handleClickSend(!isReply ? fileData.status == '' ? 'text' :
                                            switchFileType(fileData.file.type) == 'image' ? 'image' : 'file' : 'reply')
                                    }}>
                                        <input
                                            value={text}
                                            placeholder="Tulis pesan"
                                            className="w-full bg-gray-100 outline-none text-sm"
                                            onChange={e => setText(e.target.value)}
                                            autoFocus={!mobile}
                                        />
                                    </form>
                                    <input type="file" onChange={handlePickFile} id="upload" hidden />
                                    <label for="upload">
                                        <ImAttachment className="text-lg text-gray-400 ml-2" />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <button
                            onClick={() => handleClickSend(!isReply ? fileData.status == '' ? 'text' :
                                switchFileType(fileData.file.type) == 'image' ? 'image' : 'file' : 'reply')}
                            className="bg-red-500 h-10 w-10 rounded-full flex items-center justify-center ml-2 mr-1 sm:ml-3">
                            <IoSendSharp className="text-xl text-white" />
                        </button>
                    </div>
                    {renderOption()}
                </div>
                :
                !showDetail && !mobile &&
                <div className={`h-full w-full md:w-3/5 flex flex-col items-center border-r justify-center ${!showRoom && mobile && 'hidden'}`}>
                    <p className="text-lg font-bold">{langId.chat.emptyRoom}</p>
                    <p className="text-sm font-light">{langId.chat.emptyRoom2}</p>
                    {/* <button className="text-sm bg-red-500 text-white px-4 py-1.5 rounded-3xl mt-3">
                        New message
                    </button> */}
                </div>
            }
        </>
    )
}

export default ChatRoom