import React, { useState, useEffect } from 'react'
import { Base, FlatList, Spinner } from '../../components'
import { useDispatch, useSelector } from 'react-redux'
import * as a from '../../redux/actions'
import { useHistory } from 'react-router-dom'
import { BikinEvent, ListRsvp } from '../../routers/const'
import Icon from 'react-web-vector-icons'
import { Modal } from 'antd'
import Toast from '../../utils/toast'
import gcalendar from '../../utils/gcalendar'

function RsvpEvent(props) {
    const history = useHistory()
    const [img, setImg] = useState({ url: "" })
    const [register, setRegister] = useState(false)
    const { user } = useSelector(state => state.user)
    const { detailEvent, isLoading, isLoadingReg, isSuccessReg, isFailedReg } = useSelector(state => state.eventCommunity)
    const { isSuccessRemove, isFailedRemove } = useSelector(state => state.bikinEvent)
    const dispatch = useDispatch()
    const data = detailEvent
    const param = props.location.state
    const detail = [
        { id: 0, detail: `${data?.attender || 0} orang bergabung`, icon: 'user' },
        { id: 1, detail: `${data?.waktu_mulai} WIB, ${data?.tanggal_event}`, icon: 'clockcircleo' },
        { id: 2, detail: data?.lokasi || "-", icon: 'enviromento' },
        { id: 3, detail: `${data?.ticket_price || "Free"}`, icon: 'tago' }
    ]
    const style = {
        label: 'text-xs sm:text-sm text-gray-700 w-1/4',
        value: 'text-xs sm:text-sm text-gray-700',
    }

    const handlePickImage = (e) => {
        e.preventDefault()
        const file = e.target.files[0]
        setImg({ url: URL.createObjectURL(file), file })
    }

    const fetchData = () => {
        dispatch(a.getEventCommunity({
            idEvent: param?.id || props.location.pathname.replace('/rsvp/', ''),
            type: 'byId'
        }))
        dispatch(a.doResetBikinEvent())
    }

    const showDeleteConfirm = (id) => {
        Modal.confirm({
            title: 'Are you sure delete this event?',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                dispatch(a.removeEvent({ post_id: param.id }))
            },
            onCancel() {
                console.log('Cancel');
            },
        })
    }

    const renderStatus = () => {
        let status = '', disabled = true
        if (!data?.is_past) {
            if (!data?.is_approved) {
                switch (data?.is_attended) {
                    case "false":
                        status = 'Daftar Event'
                        disabled = false
                        break;
                    case "true":
                        status = 'Menunggu Persetujuan'
                        break;
                    default:
                        status = ''
                        break;
                }
            } else status = 'Terdaftar'
        } else status = 'Event Berakhir'
        return { status, disabled }
    }

    const handleRegister = () => {
        const body = new FormData()
        body.append('event_id', param?.id)
        if (data?.ticket_price && data?.ticket_price !== "undefined") {
            if (img?.url) {
                body.append('image', img.file)
                dispatch(a.doRegisterEventCommunity(body))
            } else Toast.error(`Harap sertakan bukti pembayaran`)
        } else dispatch(a.doRegisterEventCommunity(body))
    }

    useEffect(() => {
        if (isSuccessRemove || isSuccessReg) {
            Toast.info(`Berhasil ${register ? 'daftar' : 'hapus'} event${!register ? '. Menghapus event di Google Calendar...' : ''}`)
            if (register) {
                setTimeout(() => {
                    setRegister(false)
                    fetchData()
                }, 1000)
            } else {
                setTimeout(() => {
                    gcalendar({
                        type: 'delete',
                        data: { calendar_id: data.calendar_id },
                        handleEvent: () => history.goBack()
                    })
                }, 2000)
            }
        }
        if (isFailedRemove || isFailedReg) Toast.error(`Gagal ${register ? 'daftar' : 'hapus'} event`)
    }, [isSuccessRemove, isFailedRemove, isSuccessReg, isFailedReg])

    useEffect(() => {
        fetchData()
        localStorage.removeItem('guest')
    }, [])

    return (
        <Base
            primary
            rightIcon={() => null}
            main={
                <>
                    <div className="flex items-center mb-5 justify-between px-3 md:px-0">
                        <button className="flex items-center mr-3" onClick={() => register ? setRegister(false) : history.goBack()}>
                            <Icon
                                name='left'
                                font='AntDesign'
                                color='#4B5563'
                                size={18}
                            />
                            <p className="ml-3 text-lg font-semibold line-clamp-1">{register ? 'Daftar' : 'Info'} Event</p>
                        </button>
                        {data.display_name == user.display_name ? <div class="flex items-center">
                            <button title="RSVP" onClick={() => history.push({ pathname: ListRsvp.replace(':id', data.id), state: { data } })} class="mr-3">
                                <Icon
                                    name='profile'
                                    font='AntDesign'
                                    color='#4B5563'
                                    size={18}
                                />
                            </button>
                            <button title="Update event" onClick={() => history.push({ pathname: BikinEvent, state: { type: 'update', data } })} class="mr-2">
                                <Icon
                                    name='form'
                                    font='AntDesign'
                                    color='#4B5563'
                                    size={18}
                                />
                            </button>
                            <button title="Hapus event" onClick={showDeleteConfirm} >
                                <Icon
                                    name='delete'
                                    font='AntDesign'
                                    color='#4B5563'
                                    size={18}
                                />
                            </button>
                        </div> : null
                        }
                    </div>
                    {isLoading ?
                        <Spinner size={12} top={12} />
                        :
                        <div className="w-full px-3">
                            {!register ?
                                <>
                                    <div className="w-full flex flex-col items-center">
                                        <img src={data?.featuredimage && data?.featuredimage[0]?.image || "https://heduparts.com/uploads/placeholder.png"} className="h-96 w-96 object-cover" />
                                        <p className="text-xs sm:text-sm text-red-500 mt-5">{data?.tanggal_event}</p>
                                        <p className="text-sm sm:text-base font-semibold mx-4 text-center mt-1 sm:mt-0">{data?.post_title}</p>
                                        {!register &&
                                            <button
                                                disabled={renderStatus().disabled}
                                                onClick={() => setRegister(true)}
                                                className={`${renderStatus().disabled ? 'bg-gray-300' : 'bg-red-500'}  px-5 py-2 rounded-md text-white font-bold mt-5`}>
                                                {renderStatus().status}
                                            </button>
                                        }
                                    </div>
                                    <div className="w-full flex flex-col mt-5 px-4 mb-24">
                                        <p className="text-sm sm:text-base font-semibold mb-2">Detail</p>
                                        <FlatList
                                            data={detail}
                                            renderItem={({ item }) => (
                                                <div className="mb-1 flex items-center">
                                                    <Icon
                                                        name={item.icon}
                                                        font={'AntDesign'}
                                                        color='#4B5563'
                                                        size={15}
                                                    />
                                                    <p className="text-xs sm:text-sm ml-2" >{item.detail}</p>
                                                </div>
                                            )}
                                        />
                                        <pre className="text-xs sm:text-sm my-3" style={{ fontFamily: 'inherit' }}>{data?.post_content}</pre>
                                    </div>
                                </>
                                :
                                <>
                                    <div className="w-full flex items-center">
                                        <img src={data?.featuredimage && data?.featuredimage[0]?.image || "https://heduparts.com/uploads/placeholder.png"} className="h-20 w-20 object-cover" />
                                        <div className="mx-4 flex flex-col justify-center h-24">
                                            <p className="text-xs sm:text-sm text-red-500">{data?.tanggal_event}</p>
                                            <p className="text-sm sm:text-base font-semibold mt-1 sm:mt-0">{data?.post_title}</p>
                                            <p className="text-sm mt-1 sm:mt-0">{data?.lokasi}</p>
                                        </div>
                                    </div>
                                    <div className="w-full flex flex-col mt-3 border-t">
                                        <div className="border rounded-md px-4 py-2.5 my-3 mt-5">
                                            <div className="flex items-center mb-1">
                                                <p className={style.label}>Nama</p>
                                                <p className={style.value}>{`${user.first_name} ${user.last_name}`}</p>
                                            </div>
                                            <div className="flex items-center mb-1">
                                                <p className={style.label}>Email</p>
                                                <p className={style.value}>{user.user_email}</p>
                                            </div>
                                            <div className="flex items-center mb-1">
                                                <p className={style.label}>No HP</p>
                                                <p className={style.value}>{user.authormeta.phone_number}</p>
                                            </div>
                                            {data?.ticket_price && data?.ticket_price !== "undefined" &&
                                                <>
                                                    <div className="flex items-center mb-1">
                                                        <p className={style.label}>Harga</p>
                                                        <p className={style.value}>{data.ticket_price}</p>
                                                    </div>
                                                    <div className="flex items-center">
                                                        <p className={style.label}>No Rek Tujuan</p>
                                                        <p className={style.value}>{data.payment_info}</p>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                        {data?.ticket_price && data?.ticket_price !== "undefined" &&
                                            <>
                                                <p className="mt-3 mb-1 self-center">Upload bukti bayar</p>
                                                <input type="file" id="upload" hidden accept="image/*" onChange={handlePickImage} />
                                                <div class="w-full flex flex-col items-center relative mt-1">
                                                    <img src={img?.url || "https://heduparts.com/uploads/placeholder.png"} alt="" class="bg-gray-200 w-2/3" />
                                                    <div className="w-2/3 flex items-center justify-between absolute bottom-0">
                                                        {img?.url ?
                                                            <button
                                                                onClick={e => {
                                                                    e.preventDefault()
                                                                    setImg({ url: "" })
                                                                }}
                                                                class="bg-white text-white h-8 w-8 font-bold border rounded-tr-md">
                                                                <Icon
                                                                    name='delete'
                                                                    font='AntDesign'
                                                                    color='#EF4444'
                                                                    size={16}
                                                                />
                                                            </button>
                                                            :
                                                            <div className="w-8" />
                                                        }
                                                        <label
                                                            for="upload"
                                                            class="bg-white text-white h-8 w-8 font-bold border ml-2 rounded-tl-md flex items-center justify-center">
                                                            <Icon
                                                                name='upload'
                                                                font='AntDesign'
                                                                color='#EF4444'
                                                                size={16}
                                                            />
                                                        </label>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        <div className="flex justify-center">
                                            <button onClick={handleRegister} className="bg-red-500 px-8 py-2.5 rounded-md text-white font-bold mt-7 mb-10">
                                                {isLoadingReg ?
                                                    <Spinner size={6} top={0} /> :
                                                    "Daftar Sekarang"
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    }
                </>
            }
            right={<div />}
        />
    )
}

export { RsvpEvent }
