import { takeEvery, put } from 'redux-saga/effects'
import * as t from '../const.config'
import * as a from '../../config/api'

export const Trivia = [
    takeEvery(t.GET_TRIVIA, workerGetTrivia),
    takeEvery(t.GET_QA, workerGetQa),
    takeEvery(t.GET_RESULT, workerGetResult),
    takeEvery(t.GET_LEADERBOARD, workerGetLeaderboard),
]

function* workerGetTrivia() {
    yield put({ type: t.GET_TRIVIA_START })
    try {
        const data = yield a.getDataFromApi({}, a.getTriviaApi)
        const newData = data.length ? data[0] : {}

        yield put({ type: t.GET_TRIVIA_SUCCESS, data: newData })
    } catch (error) {
        yield put({ type: t.GET_TRIVIA_FAILED })
    }
}

function* workerGetQa(action) {
    const { id } = action.payload
    yield put({ type: t.GET_QA_START })
    try {
        const api = `${a.getQaApi}&id_trivia=${id}`
        const data = yield a.getDataFromApi({}, api)

        yield put({ type: t.GET_QA_SUCCESS, data })
    } catch (error) {
        yield put({ type: t.GET_QA_FAILED })
    }
}

function* workerGetResult(action) {
    const { id_trivia, pertanyaan, jawaban, start_time } = action.payload
    yield put({ type: t.GET_RESULT_START })
    try {
        const api = `${a.getResultApi}&id_trivia=${id_trivia}&pertanyaan=[${pertanyaan}]&jawaban=[${jawaban}]&start_time=${start_time}`
        const data = yield a.getDataFromApi({}, api)
        const newData = data.length ? data[0] : {}

        yield put({ type: t.GET_RESULT_SUCCESS, data: newData, status: 'played' })
    } catch (error) {
        yield put({ type: t.GET_RESULT_FAILED })
    }
}

function* workerGetLeaderboard(action) {
    const { id } = action.payload
    yield put({ type: t.GET_LEADERBOARD_START })
    try {
        const api = `${a.getLeaderboardApi}&id_trivia=${id}`
        const data = yield a.getDataFromApi({}, api)
        const newData = data.length ? data[0].leaderboard : []

        yield put({ type: t.GET_LEADERBOARD_SUCCESS, data: newData })
    } catch (error) {
        yield put({ type: t.GET_LEADERBOARD_FAILED })
    }
}