import React from 'react'
import { Menu, Transition } from '@headlessui/react'

function classNamesMenu(...classes) {
    return classes.filter(Boolean).join(' ')
}


function DropDown({ title, bottom, renderItem, smHide, style = "right-0 w-56", defaultStyle = "relative hidden text-left" }) {
    return (
        <Menu as="div" className={`${defaultStyle} ${smHide ? 'lg:inline-block' : 'sm:inline-block'}`}>
            {({ open }) => (
                <>
                    <div>
                        <Menu.Button className={bottom ? `w-full text-gray-600 focus:text-gray-600 hover:text-red-500 justify-center inline-block text-center pt-2 pb-1 z-1` : `z-1 justify-center w-full bg-white text-sm font-medium text-gray-700`}>
                            {title}
                        </Menu.Button>
                    </div>
                    <Transition
                        show={open}
                        as={React.Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items
                            static
                            className={`${style} origin-bottom-right absolute mt-3 rounded-md shadow-lg bg-white ring-2 ring-gray-500 ring-opacity-5 focus:outline-none`}
                        >
                            <div className="py-1">
                                {renderItem}
                            </div>
                        </Menu.Items>
                    </Transition>
                </>
            )}
        </Menu>
    )
}

export { DropDown, classNamesMenu }
