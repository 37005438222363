import InfiniteScroll from 'react-infinite-scroll-component'
import { Spinner } from '../Loading'

function FlatList({ data, renderItem, loading, ListEmptyComponent, ListFooterComponent,
    style, ref, ListHeaderComponent, onEndReached, hasMore, containerStyle, page = 1, inverse, scrollableDiv
}) {
    const inverseStyle = inverse && { display: 'flex', flexDirection: 'column-reverse' }
    return (
        <>
            {data.length ?
                <>
                    {ListHeaderComponent && ListHeaderComponent()}
                    <div
                        id="scrollableDiv"
                        className={`${style}`}
                        ref={ref}
                        style={inverseStyle}>
                        <InfiniteScroll
                            dataLength={data.length}
                            next={onEndReached}
                            hasMore={hasMore}
                            loader={<Spinner size={10} top={5} bottom={5} />}
                            className={`${containerStyle}`}
                            scrollThreshold={0.95}
                            inverse={inverse}
                            scrollableTarget={scrollableDiv && "scrollableDiv"}
                            style={inverseStyle}
                        >
                            {data.map((item, index) => renderItem({ item, index }))}
                        </InfiniteScroll>
                    </div>
                </>
                :
                !loading ?
                    ListEmptyComponent ?
                        ListEmptyComponent() : (
                            <div className="w-full flex justify-center">
                                <p className="text-sm font-light mt-5">No data</p>
                            </div>
                        )
                    : null
            }
            {loading && page == 1 ?
                ListFooterComponent ?
                    ListFooterComponent() : <Spinner size={10} top={5} bottom={5} /> :
                null
            }
        </>
    )
}

export { FlatList }
