import React, { useEffect, useState } from 'react'
import { Base, CommunityPost, Spinner, Modal, FlatList, Carousel, TabCategory, Alert } from '../../components'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import * as a from '../../redux/actions'
import * as r from '../../routers/const'
import Icon from 'react-web-vector-icons'
import { RiSearchLine } from 'react-icons/ri'
import { IoMdClose } from 'react-icons/io'
import { CustomCarouselComponent } from '../../components/Carousel/CustomCarousel'
import { disableFeature, link } from '../../Dao'
import qiscus from '../../utils/qiscusSDK'
import { appId } from '../../config/qiscus'

function Feed() {
    const [activeTab, setActiveTab] = useState({ category_id: 1 })
    const [page, setPage] = useState(1)
    const [showAlert, setShowAlert] = React.useState(false)
    const [search, setSearch] = useState(false)
    const [filter, setFilter] = useState({})
    const [keyword, setKeyword] = useState("")
    const dispatch = useDispatch()
    const history = useHistory()
    const { listPost, listCategory, isLoading, listCommunity, pages } = useSelector(state => state.feedCommunity)
    const podcast = useSelector(state => state.podcastCommunity)
    const { data } = useSelector(state => state.banner)
    const { user, isTokenValid } = useSelector(state => state.user)
    const { count } = useSelector(state => state.chat)
    const listCtg = [
        { category_id: 1, name: 'Semua', type: '' },
        { category_id: 2, name: 'Articles', type: 'post' },
        { category_id: 3, name: 'Events', type: 'event' },
        { category_id: 4, name: 'Photos', type: 'album' },
        { category_id: 5, name: 'Podcast', type: 'podcast' },
    ]

    const fetchPost = async (item, reset, loading) => {
        dispatch(a.getPostCommunity({ item, reset, loading }))
    }

    const handleClickCategory = async (item) => {
        if (item.category_id == 0) {
        } else {
            setActiveTab(item)
            setFilter({
                category: item.category_id == 1 ? null : item,
                city: item.category_id == 1 ? null : filter.city
            })
            if (item.category_id == 1) {
            }
        }
    }

    const fetchWithFilter = (value, loading) => {
        const idCtg = filter?.category?.category_id
        const idCity = filter?.city?.id_city
        const reqBody = {
            category_id: idCtg,
            city_id: idCity,
            callpage: value ? value : page,
            type: activeTab?.type
        }
        fetchPost(reqBody, false, loading)
    }

    const doFollow = async (id, type) => {
        await dispatch(a.doFollowCommunity({ id, type }))
        fetchWithFilter()
    }

    const doLike = async (id, type) => {
        if (!isLoading) {
            await dispatch(a.doLikePost({ id, type }))
            fetchWithFilter()
        }
    }

    const handleSeeMore = (item, type) => {
        const push = (page, id) => history.push(
            page.replace(':id', item.author_id).replace(':idContent', id || item.post_id),
        )
        switch (type) {
            case 'Article':
                push(r.ArticleDetail)
                break;
            case 'Event':
                push(r.EventDetail)
                break;
            case 'Podcast':
                history.push(
                    r.PodcastCommunity.replace(':id', item.author_id),
                )
                break;
            case 'Photo':
                const id = item.images.length && item.images[item.images.length - 1].id
                push(r.PhotoDetail, id)
                break;
        }
    }

    const fetchCommunity = () => {
        dispatch(a.getCommunityList({ keyword }))
    }

    const loadMore = () => {
        setTimeout(() => {
            if (listPost.length % 10 == 0 && page + 1 <= pages) {
                setPage(page + 1)
                fetchWithFilter(page + 1, true)
            }
        }, 500)
    }

    const getCountChat = () => {
        qiscus && qiscus.isLogin && qiscus.getTotalUnreadCount()
            .then(function (unreadCount) {
                dispatch(a.doSetCountChat({ ...count, all: unreadCount }))
            })
    }

    useEffect(() => {
        qiscus.init({
            AppId: appId,
            options: {
                loginSuccessCallback: (data) => {
                    getCountChat()
                },
                newMessagesCallback: (messages) => {
                    getCountChat()
                },
            }
        })
        const name = `${user.first_name} ${user.last_name}`
        qiscus.setUser(user.ID, user.user_login, name, user.avatar)
        if (qiscus && qiscus.isLogin && Object.keys(qiscus).length) {
            qiscus.updateProfile({ extras: { online: false } })
        }
    }, [])

    useEffect(() => { fetchCommunity() }, [keyword])

    useEffect(() => {
        dispatch(a.getPostCommunity({}))
        window.scrollTo({ top: 0, behavior: "smooth" })
        dispatch(a.getCategoryCommunity())
        dispatch(a.getPodcastCommunity({}))
        dispatch(a.getBanner())
        dispatch(a.getProfile({ id: user?.ID }))
        dispatch(a.doCheckToken())
    }, [])

    useEffect(() => {
        const type = activeTab.type
        fetchPost({ type })
        setPage(1)
    }, [activeTab.category_id])

    const renderModalSearch = () => {
        return (
            <Modal visible={search} onClose={() => setSearch(false)} className="flex-col px-5 py-3" containerStyle="w-full">
                <div className="flex items-center mb-3">
                    <IoMdClose className="text-2xl mr-2" onClick={() => setSearch(false)} />
                    <p className="text-base font-semibold">Komunitas</p>
                </div>
                <div className="flex items-center bg-gray-100 py-2 px-3 mb-3">
                    <RiSearchLine className="text-xl mr-2 text-gray-400" />
                    <form
                        className="w-full"
                        onSubmit={(e) => {
                            e.preventDefault()
                            setKeyword(e.target.elements.key.value)
                        }}>
                        <input
                            name="key"
                            className="outline-none bg-gray-100 text-sm w-full"
                            placeholder="Cari komunitas"
                        />
                    </form>
                </div>
                <FlatList
                    data={listCommunity}
                    renderItem={({ item }) => {
                        return (
                            <button
                                onClick={() => history.push(r.CommunityHome.replace(':id', item.community_id))}
                                className="flex items-center mb-1 text-left w-full py-1">
                                <img src={item?.author_image} className="h-11 w-11 object-cover rounded-full border" />
                                <div className="ml-2 flex flex-col justify-between">
                                    <p className="text-sm font-semibold mb-0.5">{item?.name}</p>
                                    <p className="text-xs font-light">{`${item.member} ${item.member > 1 ? "Members" : "Member"}`}</p>
                                </div>
                            </button>
                        )
                    }}
                    loading={isLoading}
                    ListFooterComponent={() => <div className="h-screen-50 sm:h-screen-60"><Spinner size={10} top={3} /></div>}
                    style="h-screen-50 sm:h-screen-60 overflow-y-auto"
                    ListEmptyComponent={() => (
                        <div className="w-full h-screen-50 sm:h-screen-60 flex justify-center">
                            <p className="text-sm font-light mt-5">Not found</p>
                        </div>
                    )}
                />
            </Modal>
        )
    }

    return (
        <Base
            primary
            title="Home"
            hideBottom={search}
            rightIcon={() => (
                <button onClick={() => setSearch(true)}>
                    <Icon
                        name="search1"
                        font='AntDesign'
                        color='black'
                        size={20}
                    />
                </button>
            )}
            main={
                <div id="mainDiv">
                    <Alert
                        title={disableFeature.title}
                        subTitle={disableFeature.subTitle}
                        visible={showAlert}
                        onClick={() => window.open(link.playstore, "_blank")}
                        yes={disableFeature.yes}
                        cancel={disableFeature.cancel}
                        onClose={() => setShowAlert(false)}
                    />
                    <div className="mb-10">
                        <CustomCarouselComponent autoplay delayLength={5000}>
                            {data.map(item => (
                                <a href={item.permalink} target="_blank" class="w-full flex-shrink-0" key={item.ID}>
                                    <img src={item.image} alt={item.post_title} className="w-full object-contain rounded-lg" />
                                </a>
                            ))}
                        </CustomCarouselComponent>
                    </div>
                    <TabCategory
                        data={listCtg}
                        onPressItem={handleClickCategory}
                        active={activeTab.category_id}
                        id="category_id"
                        loading={isLoading}
                    />
                    <CommunityPost
                        data={listPost}
                        isLoading={isLoading}
                        headerPost
                        doLike={doLike}
                        onClickSeeMore={handleSeeMore}
                        hasMore={page + 1 <= pages}
                        onEndReached={loadMore}
                        doFollow={doFollow}
                        page={page}
                        onClickAuthor={(item) =>
                            history.push(r.CommunityHome.replace(':id', item.author_id))
                        }
                    />
                    {renderModalSearch()}
                </div>
            }
            right={
                <div className="bg-gray-100 p-2 rounded-lg">
                    <h3 className="text-lg font-semibold">Podcast Baru</h3>
                    {podcast.isLoading ? <Spinner size={12} top={5} /> : podcast.listPodcast.slice(0, 3).map((item, index) =>
                        <button
                            onClick={() => setShowAlert(true)}
                            className={`p-2 flex ${index != 2 ? `border-b` : null}`}
                        >
                            <img src={item.featuredthumb} className="bg-contain rounded-lg w-auto object-contain w-1/3 h-full" />
                            <div className="pl-4 text-left">
                                <p className="tex-xl font-medium">{item.post_title}</p>
                                <p>{item.speaker}</p>
                            </div>
                        </button>
                    )}
                </div>
            }
        />
    )
}

export { Feed }
