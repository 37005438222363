import React, { useState, useEffect } from 'react'
import { CardFeed, Spinner, FlatList, Base } from '../../components'
import Icon from 'react-web-vector-icons'
import * as a from '../../redux/actions'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'

function Member(props) {
    const [page, setPage] = useState(1)
    const [keyword, setKeyword] = useState("")
    const dispatch = useDispatch()
    const history = useHistory()
    const params = useParams()
    const { listMember, isLoading, pages } = useSelector(state => state.memberCommunity)
    const title = props.location.state?.title || ""

    const fetchData = (value, reset, resetPage) => {
        dispatch(a.getMemberCommunity({
            id: params?.id,
            keyword,
            page: value ? value : 1,
            reset
        }))
        resetPage && setPage(1)
    }

    const loadMore = () => {
        setTimeout(() => {
            if (listMember.length % 15 == 0 && !isLoading && page + 1 <= pages) {
                setPage(page + 1)
                fetchData(page + 1, false)
            }
        }, 500)
    }

    useEffect(() => { fetchData() }, [keyword])

    const renderItem = ({ item }) => {
        const name = item?.first_name ? `${item?.first_name} ${item?.last_name || ''}` : item?.display_name
        return (
            <div className="bg-white mx-3 rounded-md border-b flex flex-row items-center py-2 px-1">
                <img src={item?.avatar} className="h-12 w-12 rounded-full object-cover" />
                <div className="px-3 py-1 flex flex-col justify-center">
                    <p className="text-base font-bold break-words">{name}</p>
                    <p className="text-sm font-light break-words">{item.user_email}</p>
                </div>
            </div>
        )
    }

    return (
        <Base
            title={`Members ${title}`}
            onSearch={setKeyword}
            main={
                <FlatList
                    data={listMember}
                    renderItem={renderItem}
                    loading={isLoading}
                    onEndReached={loadMore}
                    hasMore={page + 1 <= pages}
                    page={page}
                />
            }
            right={<div />}
        />
    )
}

export { Member }
