import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Header, Spinner, Modal } from '../../components'
import * as a from '../../redux/actions/auth';
import * as c from '../../redux/const.config'
import Toast from '../../utils/toast'
import { ToastContainer } from 'react-toastify'
import { IoMdClose } from 'react-icons/io'
import { langId } from '../../Dao'
import { images } from '../../assets'
import { Link } from 'react-router-dom'

export function Login() {
  const [modalForgot, setModalForgot] = useState(false)
  const [modalPage, setModalPage] = useState(1)
  const dispatch = useDispatch()
  const { action, message, loading, link, isTokenValid, isLogin } = useSelector(state => state.user)
  const style = {
    label: 'text-sm mb-1 text-gray-600',
  }

  const handleFormSubmit = (e) => {
    e.preventDefault()
    const email = e.target.elements.email?.value
    const password = e.target.elements.password?.value
    if (email && !hasWhiteSpace(email) && password && !hasWhiteSpace(password)) {
      dispatch(a.signInWatcher({ email, password }))
    }
  }

  const hasWhiteSpace = (text) => {
    return /^\s/.test(text)
  }

  const handleClickNext = (e) => {
    e.preventDefault()
    const email = e.target.elements.email?.value
    if (email && !hasWhiteSpace(email)) {
      dispatch(a.forgotPassword(email))
    }
  }

  const handleSubmitCode = (e) => {
    e.preventDefault()
    const code = e.target.elements.code?.value
    dispatch(a.secretCode(code))
  }

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  useEffect(() => {
    if (action == c.SIGN_IN_FAILURE) {
      Toast.error(message)
    }
    if (action == c.FORGET_PASSWORD_SUCCSESS) {
      setModalForgot(false)
      Toast.info('E-mail sent successfully')
      setTimeout(() => {
        setModalForgot(true)
        setModalPage(2)
      }, 2000)
    }
    if (action == c.FORGET_PASSWORD_FAILURE) {
      Toast.error('Sorry, Did not find user with this entry')
    }
    if (action == c.SECRET_CODE_SUCCSESS) {
      setModalForgot(false)
      setModalPage(1)
      openInNewTab(link ? link : 'https://socialconnext.app')
    }
    if (action == c.SECRET_CODE_FAILURE) {
      Toast.error('Wrong secret code');
    }
    dispatch(a.clearAction())
  }, [action])

  useEffect(() => {
    dispatch(a.clearAction())
    if (isTokenValid || isLogin) dispatch(a.doResetLoginStatus())
  }, [])

  const renderModalForgot = () => {
    if (modalPage === 1) {
      return (
        <Modal visible={modalForgot} className="flex-col px-5 py-3" containerStyle="w-full">
          <div className="flex items-center mb-3">
            <IoMdClose className="text-2xl mr-2" onClick={() => setModalForgot(false)} />
            <p className="text-base font-semibold">{langId.auth.forgot}</p>
          </div>
          <p className="text-sm font-light mt-1">{langId.auth.forgotDesc}</p>
          <form className="w-full" onSubmit={handleClickNext}>
            <input
              className="outline-none text-sm w-full py-2 px-3 mb-3 border rounded-md mt-3"
              placeholder="Email"
              required
              type="email"
              name="email"
            />
            <button
              className={`text-white bg-red-500 w-full rounded-md py-2 font-semibold text-sm mt-2 mb-3`}>
              {loading ? <Spinner size={5} /> : 'Lanjut'}
            </button>
          </form>
        </Modal>
      )
    } else if (modalPage === 2) {
      return (
        <Modal visible={modalForgot} className="flex-col px-5 py-3" containerStyle="w-full">
          <div className="flex items-center mb-3">
            <IoMdClose className="text-2xl mr-2" onClick={() => setModalForgot(false)} />
            <p className="text-base font-semibold">{langId.auth.secret}</p>
          </div>
          <p className="text-sm font-light mt-1">{langId.auth.secretDesc}</p>
          <form className="w-full" onSubmit={handleSubmitCode}>
            <input
              className="outline-none text-sm w-full py-2 px-3 mb-3 border rounded-md mt-3"
              placeholder={langId.auth.secret}
              required
              name="code"
            />
            <button
              className={`text-white bg-red-500 w-full rounded-md py-2 font-semibold text-sm mt-2 mb-3`}>
              {loading ? <Spinner size={5} /> : 'Kirim'}
            </button>
          </form>
        </Modal>
      )
    }
  }

  return (
    <>
      <div className="w-full mx-auto bg-white flex sticky top-0 z-50 ">
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {renderModalForgot()}
      </div>
      <div className="flex items-center">
        <div className="w-7/12 h-screen relative hidden sm:flex">
          <Link to={{ pathname: '/' }} className="absolute top-10 left-20">
            <img src={images.logo.default} alt="" className="h-9 object-contain" />
          </Link>
          <p class="absolute top-28 left-20 text-3xl text-red-500 font-bold w-2/3">
            {langId.landing.heroTitle}
          </p>
          <img src={images.cover} alt="" className="w-full h-full bg-cover" />
        </div>
        <div className="w-full h-screen sm:w-1/2 flex flex-col items-center justify-center relative">
          <p className="text-center text-3xl font-semibold text-gray-800">{langId.auth.login}</p>
          <form className="mt-8 w-4/5 lg:w-3/5" onSubmit={handleFormSubmit}>
            <input type="hidden" name="remember" defaultValue="true" />
            <p className={style.label}>Email</p>
            <input
              id="email-address"
              name="email"
              type="email"
              autoComplete="email"
              required={!modalForgot}
              className="mb-3 w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-red-500 focus:border-red-500 focus:z-10 sm:text-sm"
              placeholder="Masukkan email"
            />
            <p className={style.label}>Password</p>
            <input
              id="password"
              name="password"
              type="password"
              autoComplete="current-password"
              required={!modalForgot}
              className="w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-red-500 focus:border-red-500 focus:z-10 sm:text-sm"
              placeholder="Masukkan password"
            />
            <div>

              <button
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 mt-8"
              >
                {loading ? <Spinner size={5} /> : 'Masuk'}
              </button>
            </div>
            <div className="text-sm w-full text-center mt-4">
              <button onClick={() => setModalForgot(true)} className="font-medium text-red-600 hover:text-red-500">
                Lupa password?
              </button>
            </div>
            <p className="mt-2 text-center text-sm text-gray-600">
              Belum punya akun?{' '}
              <a href="/register" className="font-medium text-red-600 hover:text-red-500">
                Daftar
              </a>
            </p>
          </form>
          <div className="absolute bottom-10 flex items-center justify-center w-full">
            <p className="text-center text-sm font-semibold text-gray-400">SocialConnext Copyright 2021</p>
          </div>
        </div>
      </div>
    </>
  )
}
