import moment from 'moment'

var gapi = window.gapi
var CLIENT_ID = "873325033753-gotau8vdr32b397kffm3doai5taac6an.apps.googleusercontent.com"
var API_KEY = "AIzaSyDfdF9at4KVFpoh9BvGXuh5zv_IksnXj78"
var DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"]
var SCOPES = "https://www.googleapis.com/auth/calendar.events"

const convert = (str) => {
    var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
}

const calendar = ({ type, data, handleEvent = () => null, all }) => {
    gapi.load && gapi.load('client:auth2', () => {
        gapi.client.init({
            apiKey: API_KEY,
            clientId: CLIENT_ID,
            discoveryDocs: DISCOVERY_DOCS,
            scope: SCOPES,
        })
        gapi.client.load('calendar', 'v3', () => null)
        const time = all ? data?.waktu_mulai : moment(data?.waktu_mulai).format("HH:mm:ss")
        const dateTime = `${convert(data?.tanggal_event)}T${time}+07:00`
        gapi.auth2.getAuthInstance().signIn()
            .then(() => {
                var event = {
                    'summary': data?.post_title,
                    'location': data?.lokasi,
                    'description': data?.post_content,
                    'start': {
                        'dateTime': dateTime,
                        'timeZone': 'Asia/Jakarta'
                    },
                    'end': {
                        'dateTime': moment(dateTime).add(1, 'hours').format(),
                        'timeZone': 'Asia/Jakarta'
                    },
                    ...type === 'add' ? {} : {
                        'attendees': data?.guest || []
                    },
                }
                let request = null
                if (!data?.calendar_id) {
                    request = gapi.client.calendar.events.insert({
                        'calendarId': 'primary',
                        'resource': event,
                    })
                } else {
                    request = type === 'update' ?
                        gapi.client.calendar.events.update({
                            'calendarId': 'primary',
                            'eventId': data?.calendar_id,
                            'resource': event,
                        }) :
                        gapi.client.calendar.events.delete({
                            'calendarId': 'primary',
                            'eventId': data?.calendar_id,
                        })
                }
                request.execute(event => {
                    handleEvent({ ...event, guest: all && data?.guest })
                    type === 'add' && window.open(event.htmlLink)
                })
            })
            .catch(e => console.log("e", e))
    })
}

export default calendar