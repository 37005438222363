import * as a from '../const.config'

const initialState = {
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    listQa: [],
    triviaData: {},
    leaderboard: [],
    result: {},
    userStatus: '',
    isLoadingLeaderboard: false
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case a.GET_TRIVIA_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
                isFailed: false,
                result: {},
                listQa: []
            }
        case a.GET_TRIVIA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                triviaData: action.data
            }
        case a.GET_TRIVIA_FAILED:
            return {
                ...state,
                isLoading: false,
                isFailed: true,
            }
        case a.GET_QA_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
                isFailed: false,
            }
        case a.GET_QA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                listQa: action.data
            }
        case a.GET_QA_FAILED:
            return {
                ...state,
                isLoading: false,
                isFailed: true,
            }
        case a.GET_RESULT_START:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
                isFailed: false,
            }
        case a.GET_RESULT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                result: action.data,
                userStatus: action.status
            }
        case a.GET_RESULT_FAILED:
            return {
                ...state,
                isLoading: false,
                isFailed: true,
            }
        case a.GET_LEADERBOARD_START:
            return {
                ...state,
                isLoadingLeaderboard: !state.leaderboard.length,
                isSuccess: false,
                isFailed: false,
            }
        case a.GET_LEADERBOARD_SUCCESS:
            return {
                ...state,
                isLoadingLeaderboard: false,
                isSuccess: true,
                leaderboard: action.data
            }
        case a.GET_LEADERBOARD_FAILED:
            return {
                ...state,
                isLoadingLeaderboard: false,
                isFailed: true,
            }
        case a.DO_RESET_TRIVIA:
            return {
                ...state,
                triviaData: {},
                result: {},
                listQa: [],
            }
        case a.DO_SET_USER_STATUS:
            return {
                ...state,
                userStatus: action.payload.status
            }
        default:
            return state;
    }
}

export default reducer