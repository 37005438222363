import React from "react"
import Icon from 'react-web-vector-icons'

const AudioControls = ({
  isPlaying,
  onPlayPauseClick,
  onPrevClick,
  onNextClick
}) => {
  const color = "#EF4444"
  return(
  <div className="lg:space-x-4 flex items-center lg:justify-center justify-end">
    <button
      type="button"
      className="lg:block hidden"
      aria-label="Previous"
      onClick={onPrevClick}
    >
      <Icon
          name='stepbackward'
          font='AntDesign'
          color={color}
          size={22}
      />
    </button>
    {isPlaying ? (
      <button
        type="button"
        className="block"
        onClick={() => onPlayPauseClick(false)}
        aria-label="Pause"
      >
        <Icon
          name='pausecircle'
          font='AntDesign'
          color={color}
          size={32}
        />
      </button>
    ) : (
      <button
        type="button"
        className="block"
        onClick={() => onPlayPauseClick(true)}
        aria-label="Play"
      >
        <Icon
          name='play'
          font='AntDesign'
          color={color}
          size={32}
        />
      </button>
    )}
    <button
      type="button"
      className="lg:block hidden"
      aria-label="s"
      onClick={onNextClick}
    >
      <Icon
          name='stepforward'
          font='AntDesign'
          color={color}
          size={22}
        />
    </button>
  </div>
);
}

export {AudioControls};
