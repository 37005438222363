import * as a from "../const.config";

export const updateProfile = (item) => ({
    type: a.UPDATE_PROFILE,
    payload: item
})

export const getProfile = (item) => ({
    type: a.GET_PROFILE,
    payload: item
})

export const updateProfilePhoto = (item) => ({
    type: a.UPDATE_PROFILE_PHOTO,
    payload: item
})

export const doResetProfile = (item) => ({
    type: a.DO_RESET_PROFILE,
    payload: item
})

export const doChangeTheme = () => ({
    type: a.DO_CHANGE_THEME
})

export const doResetData = () => ({
    type: a.DO_RESET_DATA
})