import { takeEvery, call, put } from 'redux-saga/effects'
import * as t from '../const.config'
import Axios from '../../utils/server/baseURL'
import * as a from '../../config/api'
import qs from 'qs'

export const Auth = [
    takeEvery(t.SIGN_IN_LOADING, workerSignIn),
    takeEvery(t.SIGN_OUT_LOADING, workerSignOut),
    takeEvery(t.SIGN_PHONE_LOADING, workerSendPhone),
    takeEvery(t.SIGN_OTP_LOADING, workerSignPhone),
    takeEvery(t.FORGET_PASSWORD_LOADING, workerForgetPassword),
    takeEvery(t.SECRET_CODE_LOADING, workerSecretCode),
    takeEvery(t.SIGN_UP_LOADING, workerSignUp),
    takeEvery(t.VERIFY_PHONE_LOADING, workerVerifyPhone),
    takeEvery(t.CHANGE_PASSWORD_LOADING, workerChangePassword),
    takeEvery(t.DO_SAVE_DEVICE_TOKEN, workerDoSaveDeviceToken),
    takeEvery(t.DO_CHECK_TOKEN, workerDoCheckToken),
]

const signIn = async params => {
    try {
        const data = await Axios({
            url: `/api/login/?username=${params.email}&password=${params.password}&scope=public,core,posts,publish_posts,comments,publish_comments,profiles,edit_profile,taxonomies,manage_posts,manage_comments`,
            data: qs.stringify({
                CONSUMER_KEY: a.CONSUMER_KEY,
                SECRET_KEY: a.SECRET_KEY
            })
        })
        return data
    } catch (error) {
        return error
    }
}

const signOut = async token => {
    try {
        const data = await Axios({
            url: `/api/logout`,
            data: qs.stringify({
                ACCESS_TOKEN: token
            })
        })

        return data
    } catch (error) {
        return error
    }
}

const sendOtp = async params => {
    try {
        const data = await Axios({
            url: `/api/custom_service/?service=send_otp&phone_number=${params.phone}&type=${params.type}`,
            data: qs.stringify({
                CONSUMER_KEY: a.CONSUMER_KEY,
                SECRET_KEY: a.SECRET_KEY
            })
        })
        return data
    } catch (error) {
        return error
    }
}

const signOTP = async params => {
    try {
        const data = await Axios({
            url: `/api/custom_service/?service=login_otp&code=${params.code}&phone_number=${params.phone_number}&scope=public,core,posts,publish_posts,comments,publish_comments,profiles,edit_profile,taxonomies,manage_posts,manage_comments`,
            data: qs.stringify({
                CONSUMER_KEY: a.CONSUMER_KEY,
                SECRET_KEY: a.SECRET_KEY
            })
        })
        return data
    } catch (error) {
        return error
    }
}

const forgetPassword = async params => {
    try {
        const data = await Axios({
            url: `api/lostpwd/?email=${params}&type=mobile`,
            data: qs.stringify({
                CONSUMER_KEY: a.CONSUMER_KEY,
                SECRET_KEY: a.SECRET_KEY
            })
        })
        return data
    } catch (error) {
        return error
    }
}

const secretCode = async params => {
    try {
        const data = await Axios({
            url: `/api/custom_service/?service=link_api&email=${params}`,
            data: qs.stringify({
                CONSUMER_KEY: a.CONSUMER_KEY,
                SECRET_KEY: a.SECRET_KEY
            })
        })
        return data
    } catch (error) {
        return error
    }
}

const signUp = async params => {
    try {
        const data = await Axios({
            url: `/api/signup/?username=${params.username}&password=${params.password}&email=${params.email}&first_name=${params.first_name}&last_name=${params.last_name}&phone_number=${params.phone_number}&scope=public,core,posts,publish_posts,comments,publish_comments,profiles,edit_profile,taxonomies,manage_posts,manage_comments`,
            data: qs.stringify({
                CONSUMER_KEY: a.CONSUMER_KEY,
                SECRET_KEY: a.SECRET_KEY
            })
        })
        return data
    } catch (error) {
        return error
    }
}

const verifyPhone = async params => {
    try {
        const data = await Axios({
            url: `/api/custom_service/?service=verif_phone&email=${params.email}&phone_number=${params.phone_number}`,
            data: qs.stringify({
                CONSUMER_KEY: a.CONSUMER_KEY,
                SECRET_KEY: a.SECRET_KEY
            })
        })
        return data
    } catch (error) {
        return error
    }
}

const changePassword = async params => {
    try {
        const data = await Axios({
            url: `/api/changepwd/?oldpassword=${params.oldpassword}&password=${params.password}`,
            data: qs.stringify({
                ACCESS_TOKEN: params.token,
            })
        })
        return data
    } catch (error) {
        return error
    }
}

function* workerSignIn(action) {
    try {
        const response = yield call(signIn, action.payload)
        if (response.data.respond === 1) {
            yield put({
                type: t.SIGN_IN_SUCCSESS,
                data: response.data.result[0]
            })
        } else {
            throw response
        }
    } catch (error) {
        yield put({
            type: t.SIGN_IN_FAILURE,
            message: error?.data?.message
        })
    }
}

function* workerSignOut(action) {
    try {
        const response = yield call(signOut, action.payload)
        if (response.data.respond === 1) {
            yield put({
                type: t.SIGN_OUT_SUCCSESS,
                message: response.data.message
            })
        } else {
            throw response
        }
    } catch (error) {
        yield put({
            type: t.SIGN_OUT_FAILURE
        })
    }
}

function* workerSendPhone(action) {
    try {
        const response = yield call(sendOtp, action.payload)
        if (response.data.respond === 1) {
            yield put({
                type: t.SIGN_PHONE_SUCCSESS,
                data: {
                    otp: response.data.message.data.otp,
                    phone: action.payload
                }
            })
        } else {
            throw response
        }
    } catch (error) {
        yield put({
            type: t.SIGN_PHONE_FAILURE,
            message: error.data.message
        })
    }
}

function* workerSignPhone(action) {
    try {
        const response = yield call(signOTP, action.payload)
        if (response.data.respond === 1) {
            yield put({
                type: t.SIGN_OTP_SUCCSESS,
                data: response.data.result[0]
            })
        } else {
            throw response
        }
    } catch (error) {
        yield put({
            type: t.SIGN_OTP_FAILURE,
            message: error.data.message
        })
    }
}

function* workerForgetPassword(action) {
    try {
        const response = yield call(forgetPassword, action.payload)
        if (response.data.respond === 1) {
            yield put({
                type: t.FORGET_PASSWORD_SUCCSESS
            })
        } else {
            throw response
        }
    } catch (error) {
        yield put({
            type: t.FORGET_PASSWORD_FAILURE
        })
    }
}

function* workerSecretCode(action) {
    try {
        const response = yield call(secretCode, action.payload)
        if (response.data.respond === 1) {
            yield put({
                type: t.SECRET_CODE_SUCCSESS,
                data: response.data.result.link
            })
        } else {
            throw response
        }
    } catch (error) {
        yield put({
            type: t.SECRET_CODE_FAILURE
        })
    }
}

function* workerSignUp(action) {
    try {
        const response = yield call(signUp, action.payload)
        if (response.data.respond === 1) {
            yield put({
                type: t.SIGN_UP_SUCCSESS,
            })
        } else {
            throw response
        }
    } catch (error) {
        yield put({
            type: t.SIGN_UP_FAILURE,
            message: error.data.message
        })
    }
}

function* workerVerifyPhone(action) {
    try {
        const response = yield call(verifyPhone, action.payload)
        if (response.data.respond === 1) {
            yield put({
                type: t.VERIFY_PHONE_SUCCSESS
            })
        } else {
            throw response
        }
    } catch (error) {
        yield put({
            type: t.VERIFY_PHONE_FAILURE,
            message: error.data.message
        })
    }
}

function* workerChangePassword(action) {
    try {
        const response = yield call(changePassword, action.payload)
        if (response.data.respond === 1) {
            yield put({
                type: t.CHANGE_PASSWORD_SUCCSESS,
                message: response.data.message
            })
        } else {
            throw response
        }
    } catch (error) {
        yield put({
            type: t.CHANGE_PASSWORD_FAILURE,
            message: error.data.message
        })
    }
}

function* workerDoSaveDeviceToken(action) {
    const { token, os, userId } = action.payload
    yield put({ type: t.DO_SAVE_DEVICE_TOKEN_LOADING })
    try {
        const api = `${a.doSaveDeviceTokenApi}?auth_key=${a.authKey}&device_token=${token}&device_type=${os}&user_id=${userId}`
        const data = yield a.getDataFromApi({}, api, 'msg')

        yield put({ type: t.DO_SAVE_DEVICE_TOKEN_SUCCESS })
    } catch (error) {
        yield put({ type: t.DO_SAVE_DEVICE_TOKEN_FAILED })
    }
}

function* workerDoCheckToken() {
    yield put({ type: t.DO_CHECK_TOKEN_LOADING })
    try {
        const data = yield a.getDataFromApi({}, a.doCheckTokenApi, 'msg')
        const isValid = data === "Token is Valid"

        yield put({ type: t.DO_CHECK_TOKEN_SUCCESS, data: isValid })
    } catch (error) {
        const isValid = error.response.data.message !== "Access token is invalid or expired"
        yield put({ type: t.DO_CHECK_TOKEN_FAILED, data: isValid })
    }
}