import React from 'react'
import { ActionPost, CommentSection } from '../ActionPost'
import { CardFeed } from '../CardComponents'
import { FlatList } from '../FlatList'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import * as a from '../../redux/actions'
import { Spinner } from '../Loading/Spinner'

function CommunityPost({ doFollow, headerPost, data = [], doLike, onClickSeeMore, onEndReached, onClickAuthor, hasMore, isLoading, page }) {
    const dispatch = useDispatch()
    const { listComment, commentLoading } = useSelector(state => state.feedCommunity)
    const [commentID, setCommentID] = React.useState([])

    const getTypePost = (value) => {
        switch (value) {
            case 'post':
                return 'Article'
            case 'yotkota':
                return 'Event'
            case 'podcast':
                return 'Podcast'
            case 'album':
                return 'Photo'
            default:
                return null
        }
    }

    const fetchComment = async (id, loading, callpage = 0, reset,) => {
        await dispatch(a.doResetListComment())
        await dispatch(a.getCommentPost({
            id,
            loading, next: callpage, reset
        }))
    }

    const showComment = async (id) => {
        await setCommentID([id])
        fetchComment(id, true, 0, false)
    }

    const renderItem = ({ item }) => {
        const type = getTypePost(item?.post_type)
        const show = commentID.includes(item?.post_id)
        return (
            <CardFeed style="pb-0 pl-0 pr-0">
                {headerPost &&
                    <div className="flex items-center px-4 sm:px-5 mb-3">
                        <button onClick={() => onClickAuthor(item)} className="w-auto mr-2">
                            <img src={item?.author_image} alt="author image" className="h-11 object-contain border rounded-full" />
                        </button>
                        <div className="w-auto">
                            <p>
                                <button className="text-left" onClick={() => onClickAuthor(item)}>
                                    <div className="flex flex-row items-center">
                                        <p className="font-bold text-black text-sm">{item.author_name}
                                        </p>
                                    </div>
                                </button>
                                <button onClick={() => doFollow(item.author_id, item.is_follow == "false" ? "follow" : "unfollow")}>
                                    <p className="text-red-500 text-xs font-bold ml-1">{item.is_follow == "false" && headerPost ? '• Follow' : '• Unfollow'}</p>
                                </button>
                            </p>
                            <p className="text-gray-500 text-xs">{`${type} • ${moment(new Date(item.post_date)).fromNow()}`}</p>
                        </div>
                    </div>
                }
                <button onClick={() => onClickSeeMore(item, type)} className="text-left w-full px-0 sm:px-5">
                    <p className="text-sm text-black font-semibold mx-4 sm:mx-0">{item.post_title}</p>
                    {!headerPost && <p className="text-gray-500 text-xs mx-4 sm:mx-0">{`${type} • ${moment(new Date(item.post_date)).fromNow()}`}</p>}
                    <p className="text-sm text-black font-light mx-4 sm:mx-0 mt-3">{item.post_content}</p>
                    <img src={item.featured_image || item.cover_album} className="w-full object-cover mt-3" style={{ maxHeight: 500 }} />
                </button>
                <ActionPost
                    like={item.liked_count}
                    comment={item.comment_count}
                    isLiked={item.is_liked}
                    style="mt-5 py-2"
                    size={20}
                    onClickLike={() => doLike(item.post_id, item.is_liked ? "unlike" : "like")}
                    onClickComment={() => showComment(item.post_id)}
                />
                {show ?
                    commentLoading ?
                        <div className="flex items-center justify-center m-2">
                            <Spinner size={12} />
                        </div>
                        :
                        <CommentSection
                            id={commentID}
                            autoFocusInput
                            dataComment={listComment}
                        />
                    : null}
            </CardFeed>
        )
    }

    return (
        <FlatList
            data={data}
            renderItem={renderItem}
            onEndReached={onEndReached}
            hasMore={hasMore}
            containerStyle="mb-24"
            loading={isLoading}
            page={page}
        />
    )
}

export { CommunityPost }
