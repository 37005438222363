const lang = {
    landing: {
        heroTitle: 'CONNEXT ALL COMMUNITIES FOR A STRONGER GENERATION',
        heroDes: "SocialConnext is a home for all communities in Indonesia as well as an application for Indonesia's inspiring young generation",
        subTitle1: "Be Inspired with positive, educational, and fun content only in here!"
    },
    auth: {
        login: 'Sign in to your account',
        forgot: 'Forgot Password',
        forgotDesc: 'Enter the registered email to reset your password. We will send an email to reset your SocialConnext account password.',
        secret: 'Secret Code',
        secretDesc: 'Enter the secret code sent to the email',
        reg: 'Create your account'
    },
    chat: {
        emptyRoom: 'You don`t have any message selected',
        emptyRoom2: 'Choose one from your existing messages, or start a new one.'
    }
}

export const langId = {
    landing: {
        heroTitle: 'HUBUNGKAN SEMUA KOMUNITAS UNTUK GENERASI YANG LEBIH KUAT',
        heroDes: "SocialConnext adalah wadah bagi seluruh komunitas di Indonesia sekaligus aplikasi untuk generasi muda inspiratif Indonesia",
        subTitle1: "Terinspirasi dengan konten positif, mendidik, dan menyenangkan hanya di sini!"
    },
    auth: {
        login: 'Masuk ke akun anda',
        forgot: 'Lupa Password',
        forgotDesc: 'Masukkan email terdaftar untuk mengatur ulang kata sandi Anda. Kami akan mengirimkan email untuk mereset kata sandi akun SocialConnext Anda.',
        secret: 'Kode Rahasia',
        secretDesc: 'Masukkan kode rahasia yang dikirim ke email',
        reg: 'Buat akun mu'
    },
    chat: {
        emptyRoom: 'Anda tidak memiliki pesan yang dipilih',
        emptyRoom2: 'Pilih salah satu dari pesan yang ada, atau mulai yang baru.'
    }
}