import * as t from '../const.config'

export const addEvent = (item) => ({
    type: t.ADD_EVENT,
    payload: item
})

export const updateEvent = (item) => ({
    type: t.UPDATE_EVENT,
    payload: item
})

export const removeEvent = (item) => ({
    type: t.REMOVE_EVENT,
    payload: item
})

export const doResetBikinEvent = (item) => ({
    type: t.RESET_BIKIN_EVENT,
    payload: item
})

export const getListRsvp = (item) => ({
    type: t.GET_LIST_RSVP,
    payload: item
})

export const doApproveRsvp = (item) => ({
    type: t.DO_APPROVE_RSVP,
    payload: item
})

export const doSetGcal = (item) => ({
    type: t.SET_GCAL,
    payload: item
})