import * as t from '../const.config'

const initialState = {
    data:{},
    loading:false,
}

const reducerMessage = (state=initialState, action) => {
    switch(action.type){
        case t.MESSAGE_GLOBAL_LOADING:
            return{
                ...state,
                action:action.type,
                loading:true,
                data:{}
            }
        case t.MESSAGE_GLOBAL_SUCCESS:
            return{
                ...state,
                action:action.type,
                data:action.data,
                loading:false
            }
        case t.MESSAGE_GLOBAL_FAILED:
            return{
                ...state,
                action:action.type,
                loading:false
            }
        default:
            return state
    }
}

export {reducerMessage}