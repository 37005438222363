import * as t from '../../const.config'

const initialState = {
    articles:[],
    article:{},
    bookmarks:[],
    action:null,
    loading:false
}

const reducerContent = (state=initialState, action) => {
    switch(action.type){
        case t.HOME_ARTICLES_LOADING:
            return{
                ...state,
                action:action.type,
                loading:true
            }
        case t.HOME_ARTICLES_SUCCSESS:
            return{
                ...state,
                action:action.type,
                articles:action.articles,
                loading:false
            }
        case t.HOME_ARTICLES_FAILURE:
            return{
                ...state,
                action:action.type,
                loading:false,
                message:action.message
            }
        case t.HOME_ARTICLE_DETAIL_LOADING:
            return{
                ...state,
                action:action.type,
                loading:true
            }
        case t.HOME_ARTICLE_DETAIL_SUCCSESS:
            return{
                ...state,
                action:action.type,
                article:action.article,
                loading:false
            }
        case t.HOME_ARTICLE_DETAIL_FAILURE:
            return{
                ...state,
                action:action.type,
                loading:false,
                message:action.message
            }
        case t.HOME_ARTICLE_DETAIL_CLEAR:
            return{
                ...state,
                action:action.type,
                article:{},
                loading:false

            }
        case t.GET_BOOKMARK_LOADING:
            return{
                ...state,
                action:action.type,
                loading:true
            }
        case t.GET_BOOKMARK_SUCCSESS:
            return{
                ...state,
                action:action.type,
                bookmarks:action.bookmarks,
                loading:false
            }
        case t.GET_BOOKMARK_FAILURE:
            return{
                ...state,
                action:action.type,
                loading:false,
                message:action.message
            }
        default:
            return state
    }
}

export {reducerContent}