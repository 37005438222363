import { takeEvery, put } from 'redux-saga/effects'
import * as t from '../const.config'
import * as a from '../../config/api'

export const Chat = [
    takeEvery(t.GET_USER_LIST, workerGetUserList),
    takeEvery(t.GET_ROOM_DETAIL, workerGetRoomDetail),
    takeEvery(t.CREATE_GROUP, workerCreateGroup),
    takeEvery(t.EDIT_DESC, workerEditDesc),
    takeEvery(t.ADD_PARTICIPANTS, workerAddParticipants),
    takeEvery(t.UPDATE_AVATAR_GROUP, workerUpdateAvatarGroup),
    takeEvery(t.DO_MAKE_ADMIN_GROUP, workerDoMakeAdminGroup),
    takeEvery(t.REMOVE_PARTICIPANTS, workerRemoveParticipants),
    takeEvery(t.GET_LIST_CHAT_GROUP, workerGetListChatGroup),
    takeEvery(t.UPDATE_GROUP_NAME, workerUpdateGroupName),
]

function* workerGetUserList(action) {
    const { keyword, page } = action.payload
    yield put({ type: t.GET_USER_LIST_START })
    try {
        const api = `${a.getUserListApi}&search=${keyword}&perpage=${15}&callpage=${page}`
        const data = yield a.getDataFromApi({}, api, 'page')
        const pages = data.page
        const isLoadMore = page > 1

        yield put({ type: t.GET_USER_LIST_SUCCESS, data: data.data, isLoadMore, pages })
    } catch (error) {
        yield put({ type: t.GET_USER_LIST_FAILED })
    }
}

function* workerGetRoomDetail(action) {
    const { id, personal } = action.payload
    yield put({ type: t.GET_ROOM_DETAIL_START })
    try {
        const api = `${a.getRoomDetailApi}&room_id=${id}`
        const data = yield a.getDataFromApi({}, api)
        const newData = personal ? data.length ? data[0] : {} : data

        yield put({ type: t.GET_ROOM_DETAIL_SUCCESS, data: newData })
    } catch (error) {
        yield put({ type: t.GET_ROOM_DETAIL_FAILED })
    }
}

function* workerCreateGroup(action) {
    const body = action.payload
    yield put({ type: t.CREATE_GROUP_START })
    try {
        const data = yield a.getDataFromApi(body, a.createGroupApi)
        const newData = data.length === 0 ? {} : data.results.room

        yield put({ type: t.CREATE_GROUP_SUCCESS, data: newData })
    } catch (error) {
        yield put({ type: t.CREATE_GROUP_FAILED })
    }
}

function* workerEditDesc(action) {
    const body = action.payload
    yield put({ type: t.EDIT_DESC_START })
    try {
        const data = yield a.getDataFromApi(body, a.editDescApi)

        yield put({ type: t.EDIT_DESC_SUCCESS })
    } catch (error) {
        yield put({ type: t.EDIT_DESC_FAILED })
    }
}

function* workerAddParticipants(action) {
    const body = action.payload
    yield put({ type: t.ADD_PARTICIPANTS_START })
    try {
        const data = yield a.getDataFromApi(body, a.addParticipantsApi)

        yield put({ type: t.ADD_PARTICIPANTS_SUCCESS })
    } catch (error) {
        yield put({ type: t.ADD_PARTICIPANTS_FAILED })
    }
}

function* workerUpdateAvatarGroup(action) {
    const body = action.payload
    yield put({ type: t.UPDATE_AVATAR_GROUP_START })
    try {
        const data = yield a.getDataFromApi(body, a.updateAvatarGroupApi, '', 'form')

        yield put({ type: t.UPDATE_AVATAR_GROUP_SUCCESS })
    } catch (error) {
        yield put({ type: t.UPDATE_AVATAR_GROUP_FAILED })
    }
}

function* workerDoMakeAdminGroup(action) {
    const body = action.payload
    yield put({ type: t.DO_MAKE_ADMIN_GROUP_START })
    try {
        const data = yield a.getDataFromApi(body, a.doMakeAdminGroupApi)

        yield put({ type: t.DO_MAKE_ADMIN_GROUP_SUCCESS })
    } catch (error) {
        yield put({ type: t.DO_MAKE_ADMIN_GROUP_FAILED })
    }
}

function* workerRemoveParticipants(action) {
    const body = action.payload
    yield put({ type: t.REMOVE_PARTICIPANTS_START })
    try {
        const data = yield a.getDataFromApi(body, a.removeParticipantsApi)

        yield put({ type: t.REMOVE_PARTICIPANTS_SUCCESS })
    } catch (error) {
        yield put({ type: t.REMOVE_PARTICIPANTS_FAILED })
    }
}

function* workerGetListChatGroup(action) {
    const body = action.payload
    yield put({ type: t.GET_LIST_CHAT_GROUP_START })
    try {
        // const api = `${a.getListChatGroupApi}&user_qiscus=${}&perpage=10&callpage=${data.callpage}`
        // const data = yield a.getDataFromApi(body, api)

        yield put({ type: t.GET_LIST_CHAT_GROUP_SUCCESS })
    } catch (error) {
        yield put({ type: t.GET_LIST_CHAT_GROUP_FAILED })
    }
}

function* workerUpdateGroupName(action) {
    const body = action.payload
    yield put({ type: t.UPDATE_GROUP_NAME_START })
    try {
        const data = yield a.getDataFromApi(body, a.updateGroupNameApi)

        yield put({ type: t.UPDATE_GROUP_NAME_SUCCESS })
    } catch (error) {
        yield put({ type: t.UPDATE_GROUP_NAME_FAILED })
    }
}