import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'

function Modal({ visible, onClose = () => null, children, className, containerStyle }) {
    const cancelButtonRef = useRef(null)

    return (
        <Transition.Root show={visible} as={Fragment}>
            <Dialog
                as="div"
                static
                className="fixed z-50 inset-0 overflow-y-auto"
                initialFocus={cancelButtonRef}
                open={visible}
                onClose={onClose}
            >
                <div className="items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="inline-block align-middle h-screen-90 sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className={`inline-block bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 align-middle sm:max-w-lg ${containerStyle}`}>
                            <div className={`bg-white flex ${className}`}>
                                {children}
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

function SimpleModal({ visible, onClose, data, style }) {
    return (
        visible &&
        <button onClick={onClose} className="absolute flex items-center justify-center z-50 bg-black bg-opacity-5 bottom-0 top-0 w-full">
            <div className={`px-3 mb-3 w-1/2 pl-5 bg-white py-4 z-50 rounded-md shadow-md ${style}`}>
                {data.map(item => (
                    <button
                        onClick={item.onPress}
                        className={`w-full py-2 text-left text-base ${item.title && 'font-bold'}`}>
                        {item.text}
                    </button>
                ))}
            </div>
        </button>
    )
}

export { Modal, SimpleModal }