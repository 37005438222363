import { takeEvery, put } from 'redux-saga/effects'
import * as t from '../../const.config'
import * as a from '../../../config/api'

export const ArticleCommunity = [
    takeEvery(t.GET_ARTICLE, workerGetArticleCommunity),
]

function* workerGetArticleCommunity(action) {
    const { id, idArticle, type, keyword, page, reset = true, filter, loading } = action.payload
    yield put({ type: t.GET_ARTICLE_START, reset: type === "byId" ? false : reset, loading: loading !== undefined ? loading : true })
    try {
        const body = {
            community_id: id,
            article_id: type === "byId" ? idArticle : null,
            search: keyword,
            callpage: page,
            type: filter,
            perpage: 10
        }
        const data = yield a.getDataFromApi(body, a.getArticleApi, 'page')
        const state = type === "byId" ? "detailArticle" : "listArticle"
        const newData = type === "byId" ? data.data[0] : data.data
        const pages = data.page
        const isLoadMore = page > 1

        yield put({ type: t.GET_ARTICLE_SUCCESS, data: newData, state, isLoadMore, pages })
    } catch (error) {
        yield put({ type: t.GET_ARTICLE_FAILED })
    }
}