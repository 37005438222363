import * as t from '../../const.config'

const initialState = {
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    listArticle: [],
    detailArticle: {},
    pages: 0
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case t.GET_ARTICLE_START:
            return {
                ...state,
                isLoading: action.loading,
                isSuccess: false,
                isFailed: false,
                listArticle: action.reset ? [] : state.listArticle,
                detailArticle: action.reset ? {} : state.detailArticle,
            }
        case t.GET_ARTICLE_SUCCESS:
            const stateData = state.listArticle
            const data = action.isLoadMore ? stateData.concat(action.data) : action.data
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                [action.state]: data,
                pages: action.state === "listArticle" ? action.pages : state.pages
            }
        case t.GET_ARTICLE_FAILED:
            return {
                ...state,
                isLoading: false,
                isFailed: true,
            }
        case t.DO_RESET_ALL_POST_DETAIL:
            return {
                ...state,
                detailArticle: {},
            }
        default:
            return state;
    }
}

export default reducer;