import React, { useState, useEffect } from 'react'
import { Base, FlatList, TabCategory, Spinner, AudioPlayer, Alert } from '../../components'
import * as a from '../../redux/actions'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import track from './track'
import { disableFeature, link } from '../../Dao'

function ListPodcasts(props) {
    const [showAlert, setShowAlert] = React.useState(false)
    const [keyword, setKeyword] = useState("")
    const [page, setPage] = useState(1)
    const dispatch = useDispatch()
    const [activeTab, setActiveTab] = React.useState({})
    const { listPodcast, isLoading, pages, listCategory } = useSelector(state => state.podcastCommunity)
    const title = props.location.state?.title || ""
    const params = useParams()
    const fromSide = props.location.state?.fromSide
    const listCtg = [
        { series_id: 0, name: 'Semua' },
        ...listCategory
    ]

    const fetchData = (value, reset, resetPage, seris) => {
        dispatch(a.getPodcastCommunity({
            id: params?.id,
            idPodcast: null,
            type: null,
            keyword,
            page: value ? value : 1,
            reset,
            series: seris ? seris : ''
        }))
        resetPage && setPage(1)
    }

    const tabFilter = (item) => {
        setActiveTab(item)
        fetchData(null, true, true, item.series_id)
    }

    const loadMore = () => {
        setTimeout(() => {
            if (listPodcast.length % 10 == 0 && !isLoading && page + 1 <= pages) {
                setPage(page + 1)
                fetchData(page + 1, false)
            }
        }, 500)
    }

    useEffect(() => {
        fetchData()
        setActiveTab({ series_id: 0 })
        dispatch(a.getCategoryPodcast())
        dispatch(a.doCheckToken())
    }, [keyword])

    const renderItem = ({ item }) => {
        return (
            <button onClick={() => setShowAlert(true)} className="mb-2 text-left flex items-start flex-col">
                <img src={item?.featuredimage} className="h-68 w-68 object-fill rounded-lg mb-1" />
                <p className="text-sm md:text-base font-semibold">{item?.post_title}</p>
                <p className="text-xs md:text-sm font-light">{item?.speaker}</p>
            </button>
        )
    }

    return (
        <Base
            primary
            alert={false}
            title={`Podcasts ${title}`}
            onSearch={setKeyword}
            main={
                <div>
                    <Alert
                        title={disableFeature.title}
                        subTitle={disableFeature.subTitle}
                        visible={showAlert}
                        onClick={() => window.open(link.playstore, "_blank")}
                        yes={disableFeature.yes}
                        cancel={disableFeature.cancel}
                        onClose={() => setShowAlert(false)}
                    />
                    <div className="sticky top-14 lg:top-0 rounded-md sm:px-6 bg-white flex sm:justify-center ">
                        {fromSide &&
                            <TabCategory
                                data={listCtg}
                                active={activeTab?.series_id}
                                id="series_id"
                                class="shadow-md border-0"
                                onPressItem={tabFilter}
                            />
                        }
                    </div>
                    <FlatList
                        data={listPodcast}
                        renderItem={renderItem}
                        containerStyle={`grid grid-cols-2 gap-2 px-2 ${fromSide && 'mt-3'}`}
                        loading={isLoading}
                        onEndReached={loadMore}
                        hasMore={page + 1 <= pages}
                        page={page}
                    />
                </div>
            }
            right={
                <div>
                    {/* <AudioPlayer tracks={track}/> */}
                </div>
            }
        />
    )
}

export { ListPodcasts }
