import * as t from '../const.config'

const initialState = {
    question: {},
    answers: [],
    listMentor: [],
    mentorRandom: {},
    myQestion: [],
    topics: [],
    action: null,
    loading: false,
    questionLoading: false,

    isLoading: false,
    isSuccess: false,
    isFailed: false,
    listQuestion: [],
    detailQuestion: {},
    pages: 0
}

const reducerMentor = (state = initialState, action) => {
    switch (action.type) {
        case t.MENTOR_QUESTIONS_LOADING:
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
                isFailed: false,
                listQuestion: action.reset ? [] : state.listQuestion,
                // detailQuestion: action.reset ? {} : state.detailQuestion,
            }
        case t.MENTOR_QUESTIONS_SUCCSESS:
            const stateData = state.listQuestion
            const data = action.isLoadMore ? stateData.concat(action.data) : action.data
            return {
                ...state,
                isLoading: false,
                loading: false,
                isSuccess: true,
                listQuestion: data,
                pages: action.pages
            }
        case t.MENTOR_QUESTIONS_FAILURE:
            return {
                ...state,
                isLoading: false,
                isFailed: true,
                loading: false,
                isSuccess: false,
            }
        // case t.MENTOR_QUESTIONS_LOADING:
        //     return{
        //         ...state,
        //         action:action.type,
        //         loading:true
        //     }
        // case t.MENTOR_QUESTIONS_SUCCSESS:
        //     const stateData = state.questions
        //     const data = action.isLoadMore ? stateData.concat(action.questions) : action.questions
        //     return{
        //         ...state,
        //         action:action.type,
        //         questions:data,
        //         loading:false,
        //         pagging: action.pagging
        //     }
        // case t.MENTOR_QUESTIONS_FAILURE:
        //     return{
        //         ...state,
        //         action:action.type,
        //         loading:false,
        //         message:action.message
        //     }
        case t.MENTOR_QUESTION_DETAIL_LOADING:
            return {
                ...state,
                action: action.type,
                loading: true
            }
        case t.MENTOR_QUESTION_DETAIL_SUCCSESS:
            return {
                ...state,
                action: action.type,
                question: action.data,
                loading: false
            }
        case t.MENTOR_QUESTION_DETAIL_FAILURE:
            return {
                ...state,
                action: action.type,
                loading: false,
                message: action.message
            }
        case t.MENTOR_QUESTION_DETAIL_CLEAR:
            return {
                ...state,
                action: action.type,
                question: {},
                loading: false
            }
        case t.MENTOR_COMMENT_LOADING:
            return {
                ...state,
                action: action.type,
                loading: true,
                answers: []
            }
        case t.MENTOR_COMMENT_SUCCSESS:
            return {
                ...state,
                action: action.type,
                answers: action.data,
                loading: false
            }
        case t.MENTOR_COMMENT_FAILURE:
            return {
                ...state,
                action: action.type,
                loading: false,
                message: action.message
            }
        case t.MENTOR_GET_LOADING:
            return {
                ...state,
                action: action.type,
                loading: true
            }
        case t.MENTOR_GET_SUCCSESS:
            return {
                ...state,
                action: action.type,
                listMentor: action.data,
                mentorRandom: action.random,
                loading: false
            }
        case t.MENTOR_GET_FAILURE:
            return {
                ...state,
                action: action.type,
                loading: false,
                message: action.message
            }
        case t.TOPICS_GET_LOADING:
            return {
                ...state,
                action: action.type,
                loading: true
            }
        case t.TOPICS_GET_SUCCSESS:
            return {
                ...state,
                action: action.type,
                topics: action.data,
                loading: false
            }
        case t.TOPICS_GET_FAILURE:
            return {
                ...state,
                action: action.type,
                loading: false,
                message: action.message
            }
        case t.MENTOR_MY_QUESTIONS_LOADING:
            return {
                ...state,
                action: action.type,
                questionLoading: true
            }
        case t.MENTOR_MY_QUESTIONS_SUCCSESS:
            return {
                ...state,
                action: action.type,
                myQestion: action.data,
                questionLoading: false
            }
        case t.MENTOR_MY_QUESTIONS_FAILURE:
            return {
                ...state,
                action: action.type,
                questionLoading: false,
                message: action.message
            }
        case t.MENTOR_SUBMIT_ANSWER_LOADING:
            return {
                ...state,
                action: action.type,
                loading: true
            }
        case t.MENTOR_SUBMIT_ANSWER_SUCCSESS:
            return {
                ...state,
                action: action.type,
                loading: false
            }
        case t.MENTOR_SUBMIT_ANSWER_FAILURE:
            return {
                ...state,
                action: action.type,
                loading: false
            }
        case t.MENTOR_QUESTION_DETAIL_LOADING:
            return {
                ...state,
                action: action.type,
                loading: true
            }
        case t.MENTOR_QUESTION_DETAIL_SUCCSESS:
            return {
                ...state,
                action: action.type,
                loading: false
            }
        case t.MENTOR_QUESTION_DETAIL_FAILURE:
            return {
                ...state,
                action: action.type,
                loading: false
            }
        case t.MENTOR_SUBMIT_QUESTION_LOADING:
            return {
                ...state,
                loading: true,
                questionLoading: true
            }
        case t.MENTOR_SUBMIT_QUESTION_SUCCSESS:
            return {
                ...state,
                loading: false,
                questionLoading: false
            }
        case t.MENTOR_QUESTION_DETAIL_FAILURE:
            return {
                ...state,
                loading: false,
                questionLoading: false
            }
        case t.DO_RESET_DATA:
            return {
                ...state,
                myQestion: [],
                listQuestion: []
            }
        default:
            return state
    }
}

export { reducerMentor }