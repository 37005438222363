import React, { useState, useEffect } from 'react'
import { Base, FlatList, ActionPost, Spinner, CommentSection } from '../../components'
import Icon from 'react-web-vector-icons'
import * as a from '../../redux/actions'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { tabEvent } from '../../Dao/Community'
import moment from 'moment'

function EventDetail(props) {
    const [activeTab, setActiveTab] = useState(0)
    const [page, setPage] = useState(1)
    const [keyword, setKeyword] = useState("")
    const dispatch = useDispatch()
    const history = useHistory()
    const params = useParams()
    const { listComment, commentLoading } = useSelector(state => state.feedCommunity)
    const type = activeTab == 0 ? "upcoming" : "past"
    const { listEvent, detailEvent, isLoading } = useSelector(state => state.eventCommunity)
    const title = props.location.state?.title || ""
    const data = detailEvent
    const detail = [
        { id: 0, detail: `${data?.attender || 0} orang bergabung`, icon: 'user' },
        { id: 1, detail: `${data?.waktu_mulai} WIB, ${data?.tanggal_event}`, icon: 'clockcircleo' },
        { id: 2, detail: data?.lokasi || "-", icon: 'enviromento' },
        { id: 3, detail: `${data?.ticket_price || "Gratis"}`, icon: 'tago' }
    ]

    const fetchData = async (loading) => {
        dispatch(a.getEventCommunity({ id: null, idEvent: params?.idContent, type: 'byId', loading }))
    }

    const doLike = async (id, type) => {
        if (!isLoading) {
            await dispatch(a.doLikePost({ id, type, loading: false }))
            fetchData(false)
        }
    }

    useEffect(() => {
        fetchData(null, true)
    }, [activeTab])

    useEffect(() => {
        fetchData()
        fetchComment(null, true, 0, true)
    }, [])

    const fetchComment = async (id, loading, callpage = 0, reset,) => {
        await dispatch(a.doResetListComment())
        await dispatch(a.getCommentPost({
            id: params?.idContent,
            loading, next: callpage, reset
        }))
    }

    const showComment = (id) => {
        fetchComment(params?.idContent, true, 0, false)
    }

    return (
        <Base
            hideSearch
            title={`Event ${title}`}
            main={
                isLoading ?
                    <Spinner size={12} top={12} />
                    :
                    <div className="w-full pt-0 lg:pt-10 mb-20">
                        <div className="w-full flex flex-col items-center">
                            <img src={data?.featuredimage && data?.featuredimage[0]?.image} className="h-96 w-96 object-cover" />
                            <p className="text-xs sm:text-sm text-red-500 mt-5">{data?.tanggal_event}</p>
                            <p className="text-sm sm:text-base font-semibold mx-4 text-center mt-1 sm:mt-0">{data?.post_title}</p>
                        </div>
                        <div className="w-full flex flex-col mt-5 px-4">
                            <p className="text-sm sm:text-base font-semibold mb-2">Detail</p>
                            <FlatList
                                data={detail}
                                renderItem={({ item }) => (
                                    <div className="mb-1 flex items-center">
                                        <Icon
                                            name={item.icon}
                                            font={'AntDesign'}
                                            color='#4B5563'
                                            size={15}
                                        />
                                        <p className="text-xs sm:text-sm ml-2">{item.detail}</p>
                                    </div>
                                )}
                            />
                            <pre className="text-xs sm:text-sm my-3" style={{ fontFamily: 'inherit' }}>{data?.post_content}</pre>
                        </div>
                        <ActionPost
                            like={data?.liked_count}
                            comment={data?.comment_count}
                            onClickLike={() => doLike(data?.id, data?.is_liked ? "unlike" : "like")}
                            isLiked={data?.is_liked}
                            onClickComment={() => showComment(data?.id)}
                            style="mt-5 py-2"
                        />
                        {commentLoading ?
                            <div className="flex items-center justify-center m-2">
                                <Spinner size={12} />
                            </div>
                            :
                            <CommentSection
                                id={params?.idContent}
                                dataComment={listComment}
                            />
                        }
                    </div>
            }
            right={<></>}
        />
    )
}

export { EventDetail }
