import Icon from 'react-web-vector-icons'

function TabCategory(props) {
    const { data = [], onPressItem = () => null, onPress } = props
    return (
        <div class="bg-white sticky top-0 w-auto overflow-x-auto scrollbar-h">
            <nav class="flex flex-row sm:flex-row text">
                {data.map(item => {
                    const itemId = props.id == "id" ? item.id : props.id == "category_id" ? item.category_id : item.series_id
                    const active = props.active == itemId
                    return (
                        <button class=
                            {`text-sm sm:text-base text-gray-600 py-3 px-6 block whitespace-nowrap 
                            hover:text-red-500 
                            focus:outline-none
                            ${active ? `text-red-500 border-b-2 font-medium border-red-500` : null}`}
                            onClick={() => item.onPress ? item.onPress() : onPressItem(props.id == "id" ? item.id : item)}
                        >
                            <Icon
                                name={item.icon}
                                font='AntDesign'
                                color={active ? '#4B5563' : '#6B7280'}
                                size={18}
                            />
                            <span>
                                {item.name}
                            </span>
                        </button>
                    )
                })}
            </nav>
        </div>

    )
}

export { TabCategory }
