import { takeEvery, put } from 'redux-saga/effects'
import * as t from '../../const.config'
import * as a from '../../../config/api'

export const MemberCommunity = [
    takeEvery(t.GET_MEMBER, workerGetMember),
]

function* workerGetMember(action) {
    const { id, keyword, page, reset = true } = action.payload
    yield put({ type: t.GET_MEMBER_START, reset })
    try {
        const body = {
            community_id: id,
            search: keyword,
            callpage: page,
            perpage: 15,
        }
        const data = yield a.getDataFromApi(body, a.getMemberApi, 'page')
        const pages = data.page
        const isLoadMore = page > 1

        yield put({ type: t.GET_MEMBER_SUCCESS, data: data.data, isLoadMore, pages })
    } catch (error) {
        yield put({ type: t.GET_MEMBER_FAILED })
    }
}